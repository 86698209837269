.section-mirror-4 {
	margin-top: 116px;
	@media (max-width: 768.98px) {
		margin-top: 50px;
	}
	.overflow {
		// .overflow__container

		&__container {
			@media (max-width: 680.98px) {
				.coursor {
					display: flex;
				}
				padding: 0;
				& > div {
					padding: 0px 15px;
				}
			}
		}
	}

	// .section-mirror-4__container

	&__container {
	}

	// .section-mirror-4__maintitle

	&__maintitle {
	}

	// .section-mirror-4__block

	&__block {
		margin-top: 56px;
	}
}

.double-block {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 30px;
	@media (max-width: 1200.98px) {
		gap: 10px;
	}
	@media (max-width: 680.98px) {
		grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
		grid-auto-flow: column;
		overflow-x: scroll;
		margin-top: 0 !important;
		padding-bottom: 20px !important;
	}
	// .double-block__item

	&__item {
		min-width: 320px;
		position: relative;
	}

	// .double-block__img

	&__img {
	}

	// .double-block__info

	&__info {
		padding: 30px 20px 30px 40px;
		@media (max-width: 1200.98px) {
			padding: 20px 10px;
		}
		@media (max-width: 992.98px) {
			br {
				display: none;
			}
		}
	}

	// .double-block__title

	&__title {
		font-size: 1.5rem;
		line-height: 133.333333%; /* 32/24 */
		font-weight: 700;
		@media (max-width: 992.98px) {
			font-size: 1.125rem;
		}
		@media (max-width: 768.98px) {
			font-size: 1.0625rem;
		}
	}

	// .double-block__subtitle

	&__subtitle {
		margin-top: 20px;
		font-size: 1.125rem;
		line-height: 155.555556%; /* 28/18 */
		@media (max-width: 992.98px) {
			font-size: 1rem;
			margin-top: 5px;
		}
		@media (max-width: 768.98px) {
			font-size: 0.9375rem;
		}
	}
}
.section-mirror-5 {
	margin-top: 76px;
	@media (max-width: 768.98px) {
		margin-top: 30px;
	}
	.overflow__container {
		@media (max-width: 680.98px) {
			.coursor {
				display: flex;
			}
			padding: 0;
			& > div {
				padding: 0 15px;
			}
		}
	}
	// .section-mirror-5__wrapper

	&__wrapper {
		margin-left: auto;
		margin-right: auto;
		max-width: 1440px;
		border-radius: 40px;
		background-color: #f7f8fa;
		padding: 80px 0;
		@media (max-width: 768.98px) {
			padding: 30px 0 0;
		}
	}

	// .section-mirror-5__container

	&__container {
	}

	// .section-mirror-5__maintitle

	&__maintitle {
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-mirror-5__block

	&__block {
		margin-top: 46px;
	}
}

.section-mirror-7 {
	margin-top: 116px;
	@media (max-width: 768.98px) {
		margin-top: 30px;
	}
	.overflow {
		// .overflow__container

		&__container {
			@media (max-width: 1010.98px) {
				max-width: 100%;
				padding: 0;
				& > div {
					padding: 0 15px;
				}
				.coursor {
					display: flex;
				}
			}
		}
	}
	// .section-mirror-7__container

	&__container {
	}

	// .section-mirror-7__maintitle

	&__maintitle {
	}

	// .section-mirror-7__block

	&__block {
		margin-top: 56px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;
		@media (max-width: 1200.98px) {
			gap: 10px;
		}
		@media (max-width: 1010.98px) {
			grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
			grid-auto-flow: column;
			overflow-x: scroll;
			margin-top: 0 !important;
			padding-bottom: 20px !important;
		}
	}

	// .section-mirror-7__column

	&__column {
		min-width: 320px;
		position: relative;
	}

	// .section-mirror-7__img

	&__img {
	}

	// .section-mirror-7__info

	&__info {
		padding: 30px 20px;
		@media (max-width: 1200.98px) {
			padding: 20px 10px;
		}
	}

	// .section-mirror-7__title

	&__title {
		font-size: 1.25rem;
		line-height: 150%; /* 30/20 */
		@media (max-width: 1200.98px) {
			font-size: 18px;
		}
	}
}

.section-mirror-7 {
	margin-top: 116px;
	@media (max-width: 768.98px) {
		margin-top: 30px;
	}

	// .section-mirror-7__container

	&__container {
	}

	// .section-mirror-7__maintitle

	&__maintitle {
	}

	// .section-mirror-7__block

	&__block {
	}

	// .section-mirror-7__column

	&__column {
	}

	// .section-mirror-7__img

	&__img {
	}

	// .section-mirror-7__info

	&__info {
	}
}

.info-block {
	margin-top: 30px;
	// .info-block__block

	&__block {
		display: grid;
		grid-template-columns: repeat(2, auto);
		justify-items: center;
		justify-content: center;
		gap: 20px;
		@media (max-width: 768.98px) {
			grid-template-columns: 1fr;
			gap: 10px;
		}
	}

	// .info-block__item

	&__item {
		border-radius: 14px;
		background-color: #f7f8fa;
		padding: 40px 50px;
		display: grid;
		grid-template-columns: 50px 1fr;
		align-items: center;
		gap: 19px;
		@media (max-width: 768.98px) {
			width: 100%;
			br {
				display: none;
			}
		}
		@media (max-width: 480.98px) {
			padding: 20px;
		}
	}

	// .info-block__img

	&__img {
	}

	// .info-block__text

	&__text {
	}

	// .info-block__fon

	&__fon {
		margin-top: 30px;
		background: no-repeat left center/cover;
		background-image: url("../img/holod/bg-fon.jpg");
		border-radius: 20px;
		height: 450px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		color: #fff;
		& > h3,
		& > p {
			padding-left: 120px;
			@media (max-width: 620.98px) {
				padding: 0;
			}
		}
		@media (max-width: 620.98px) {
			padding: 50px 30px;
			height: auto;
		}
		@media (max-width: 480.98px) {
			padding: 40px 20px;
		}
	}

	// .info-block__title

	&__title {
		font-size: 2rem;
		line-height: 131.25%; /* 42/32 */
		font-weight: 700;
		@media (max-width: 480.98px) {
			font-size: 24px;
		}
	}

	// .info-block__subtitle

	&__subtitle {
		font-size: 1.125rem;
		line-height: 155.555556%; /* 28/18 */
		margin-top: 10px;
		@media (max-width: 480.98px) {
			font-size: 16px;
			br {
				display: none;
			}
		}
	}
}

@import "fonts";
@import "vars";
@import "global";
@import "mixins";
@import "parts/modal";
@import "parts/quiz";
@import "zerkala/section-1";
@import "zerkala/section-2";
@import "zerkala/section-3";
@import "zerkala/section-4";
@import "zerkala/section-7";
@import "zerkala/section-8";
@import "zerkala/section-9";
@import "holod/section-1";
@import "holod/section-4";

.header {
	z-index: 9;

	// .header__container

	&__container {
		position: absolute;
		top: 30px;
		left: 0;
		width: 100%;
		padding: 0 15px;
		color: #fff;
	}

	&__top {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		@media (max-width: 400px) {
			gap: 15px;
		}

		@media (max-width: ($md6 + px)) {
			gap: 12px;
		}
	}

	&__slogan {
		font-size: 14px;
		font-weight: 400;
		opacity: 0.8;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(24 / 14 * 100%);
		margin-top: 9px;
		padding-left: 35px;
		@media (max-width: ($md1 + px)) {
			padding-left: 0px;
		}

		@media (max-width: ($md5 + px)) {
			font-size: 11px;
		}

		@media (max-width: ($md6 + px)) {
			font-size: 10px;
		}
	}

	// .header__leftside

	&__leftside {
		cursor: pointer;
	}

	// .header__logo

	&__logo {
		display: block;
		@media (max-width: ($md1 + px)) {
			img {
				max-width: 80%;
			}
		}

		@media (max-width: ($md3 + px)) {
			img {
				max-width: 85%;
			}
		}

		@media (max-width: 400px) {
			img {
				max-width: 100%;
			}
		}
	}

	&__middle {
		display: flex;
		gap: 55px;

		@media (max-width: ($md1 + px)) {
			gap: 15px;
			margin-left: -40px;
		}

		@media (max-width: ($md2 + px)) {
			flex-direction: column;
			margin-left: 0;
		}

		@media (max-width: ($md3 + px)) {
			display: none;
		}
	}

	&__text {
		font-size: 14px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(24 / 14 * 100%);
		text-decoration: underline;
		text-underline-offset: 6px;
		text-decoration-thickness: 1px;
		margin-top: 3px;
		text-decoration-color: rgb(255, 255, 255, 0.5);
		text-align: left;
		color: #fff;

		@media (max-width: ($md1 + px)) {
			font-size: 13px;
		}
	}

	&__conditions,
	&__location {
		display: flex;
		gap: 16px;

		@media (max-width: ($md1 + px)) {
			gap: 10px;
		}

		@media (max-width: ($md1 + px)) {
			img {
				max-width: 50px;
			}
		}
	}
	// .header__rightside

	&__rightside {
		display: flex;
		flex-direction: column;

		@media (max-width: ($md3 + px)) {
			flex-shrink: 0;
		}
	}

	// .header__phone

	&__phone {
		padding-top: 3px;
		font-size: 22px;
		font-weight: 700;
		text-align: right;
		line-height: calc(26 / 22 * 100%);

		@media (max-width: ($md1 + px)) {
			font-size: 20px;
		}

		@media (max-width: ($md5 + px)) {
			font-size: 18px;
		}

		@media (max-width: ($md6 + px)) {
			font-size: 14px;
		}
	}
	// .header__callback

	&__callback {
		font-size: 12px;
		font-weight: 700;
		text-align: right;
		text-transform: uppercase;
		letter-spacing: 0.96px;
		opacity: 0.5;
		color: #fff;
		text-decoration: underline;
		text-underline-offset: 10px;
		text-decoration-thickness: 1px;
		margin-top: 13px;

		@media (max-width: ($md6 + px)) {
			font-size: 11.5px;
		}
	}

	// .header__nav

	&__nav {
	}
}
.main-nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 21px;
	position: relative;
	margin-top: 22px;
	border-top: 1px solid rgba($color: #ffffff, $alpha: 0.2);
	border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.2);
	height: 100%;
	@media (max-width: ($md2 + px)) {
		padding: 5px 0;
	}

	//&::before {
	//	content: "";
	//	width: 103.33%;
	//	height: 1px;
	//	position: absolute;
	//	top: -10px;
	//	left: -1.67%;
	//	background-color: #ffffff;
	//	opacity: 0.2;

	//	@media (max-width: ($md3 + px)) {
	//		width: 100%;
	//		top: -10px;
	//		left: 0;
	//	}
	//}

	//&::after {
	//	content: "";
	//	width: 103.33%;
	//	height: 1px;
	//	position: absolute;
	//	bottom: -10px;
	//	left: -1.67%;
	//	background-color: #ffffff;
	//	opacity: 0.2;
	//	@media (max-width: ($md3 + px)) {
	//		width: 100%;
	//		bottom: -10px;
	//		left: 0;
	//	}
	//}
	// .main-nav__btn
	&__toggler {
		width: 30px;
		display: none;

		@media (max-width: ($md2 + px)) {
			display: block;

			svg path {
				fill: #fff;
			}
		}
	}
	&__btn {
		font-size: 11px;
		font-weight: 400;
		line-height: 14px;
		letter-spacing: 0.44px;
		gap: 8px;
		min-width: 189px;
		height: 40px;
		padding: 0;
		box-shadow: 0 15px 30px rgba(1, 108, 208, 0.15), inset 0 0 18px 2px rgba(255, 255, 255, 0.15);
		border-radius: 4px;
		background-image: linear-gradient(to top, #016cd0 0%, #027aeb 100%);

		&::before {
			font-size: 16px;
		}

		@media (max-width: ($md5 + px)) {
			width: 189px !important;
		}
	}

	&__list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		height: 62px;
		@media (max-width: ($md1 + px)) {
			height: auto;
		}
		@media (max-width: ($md1 + px)) {
			display: grid;
			row-gap: 10px;
			grid-template-columns: repeat(5, auto);
		}
	}

	#menu-top-1 {
		@media (max-width: ($md2 + px)) {
			display: none;
		}
	}

	#menu-top {
		color: #000000;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-left: 20px;
		@media (max-width: ($md6 + px)) {
			padding-left: 20px;
		}
	}
	.offcanvas-header {
		justify-content: flex-end;
	}

	.offcanvas-start {
		@media (max-width: ($md5 + px)) {
			width: 90%;
		}
	}
	a {
		font-size: 13px;
		font-weight: 400;
		line-height: calc(20 / 13 * 100%);
		letter-spacing: 0.39px;

		@media (max-width: ($md2 + px)) {
			font-size: 18px;
		}
	}

	ul.sub-menu {
		display: none !important;
		@media (max-width: ($md2 + px)) {
			display: flex !important;
		}
	}

	.menu-item-has-children {
		position: relative;
		margin-right: 17px;

		&::after {
			position: absolute;
			content: "";
			width: 7px;
			height: 5px;
			background: url(../img/drop.webp);
			top: 0px;
			bottom: 0;
			margin: auto;
			right: -17px;
			cursor: pointer;
		}
		height: 100%;
		display: flex;
		align-items: center;
		@media (max-width: ($md1 + px)) {
			order: 2;
		}
		@media (max-width: ($md2 + px)) {
			display: block;
			order: 0;
		}
	}

	.main-nav__list li {
		@media (max-width: ($md1 + px)) {
			padding: 10px 0;
		}
		@media (max-width: ($md2 + px)) {
			padding: 0;
		}
	}
	@media (min-width: ($md2 + px)) {
		.menu-item-has-children:hover ul.sub-menu {
			display: block !important;
		}
	}

	ul.sub-menu {
		position: absolute;
		padding: 15px;
		width: 340px;
		background: rgb(255, 255, 255);
		color: #000;
		height: auto;
		top: 60px;
		left: 0;
		border-top: 1px solid rgba(197, 197, 197, 0.7);
		max-height: 500px;
		display: flex;
		flex-direction: column;
		gap: 15px;
		a {
			font-size: 18px;
		}

		@media (max-width: ($md1 + px)) {
			top: 40px;
		}

		@media (max-width: ($md2 + px)) {
			position: static;
			gap: 5px;
			border-top: none;
			padding-left: 30px;
			a {
				font-size: 16px;
				position: relative;
				&::before {
					position: absolute;
					left: -15px;
					top: 9px;
					content: "";
					width: 5px;
					height: 5px;
					border-radius: 50%;
					background-color: #0077ff;
				}
			}
		}
	}
}
.breadcrumbs {
	border-radius: 8px;
	background-color: rgba($color: #ffffff, $alpha: 0.1);
	font-size: 13px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.39px;
	display: flex;
	color: #ffffff;
	gap: 12px;
	max-width: fit-content;
	margin-top: 26px;
	padding: 13px 19px;
	margin-left: -20px;
	flex-wrap: wrap;

	@media (max-width: ($md3 + px)) {
		margin-left: 0;
	}
	&__separator {
		margin-top: -1px;
	}
	&__link {
		text-decoration: underline;
		text-underline-offset: 7px;
		text-decoration-color: rgba($color: #ffffff, $alpha: 0.2);
	}

	&__current {
		opacity: 0.5;
	}
}
.section-1 {
	padding-top: 331px;
	padding-bottom: 207px;
	color: #fff;
	position: relative;
	z-index: 0;

	@media (max-width: ($md2 + px)) {
		padding-bottom: 120px;
	}

	@media (max-width: ($md3 + px)) {
		padding-bottom: 50px;
		padding-top: 270px;
	}

	@media (max-width: ($md5 + px)) {
		padding-top: 250px;
	}

	&::before {
		content: "";
		position: absolute;
		height: 100%;
		top: 0;
		z-index: -1;
		left: 0;
		width: 100%;
		background-image: url(../img/sec1-back.png);
		background-size: cover;
		background-position: top center;

		@media (max-width: ($md1 + px)) {
			background-position: 70%;
			filter: brightness(0.7);
		}
	}
	// .section-1__container

	&__container {
	}

	// .section-1__title

	// .section-1__subtitle

	// .section-1__ellipse

	._icon-guarantee {
		&::before {
			margin: 0 12px 0 3px;
			vertical-align: middle;
			font-size: 35px;

			@media (max-width: ($md4 + px)) {
				font-size: 28px;
			}
		}
	}

	// .section-1__footer

	&__footer {
		margin-top: 55px;
		display: flex;
		align-items: center;
		gap: 40px;
		position: relative;

		@media (max-width: ($md2 + px)) {
			margin-top: 40px;
		}
		@media (max-width: ($md4 + px)) {
			flex-direction: column;
			align-items: flex-start;
			gap: 20px;
			margin-top: 25px;
		}
	}

	// .section-1__btn

	&__btn {
		width: 380px;
		height: 100px;

		@media (max-width: ($md2 + px)) {
			display: none;
		}
	}

	// .section-1__conditions

	&__conditions {
		display: flex;
		align-items: center;
		gap: 21px;
	}

	// .section-1__conditions-text

	&__conditions-text {
		font-size: 16px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(24 / 16 * 100%);
		text-decoration: underline;
		text-underline-offset: 7px;
		text-decoration-thickness: 1px;
		text-decoration-color: rgba(255, 255, 255, 0.5);
		text-align: left;
		color: #fff;
	}

	// .section-1__video-block

	&__video-block {
		position: absolute;
		right: 36px;
		display: flex;
		flex-direction: column;
		gap: 13px;
		top: -53px;

		@media (max-width: ($md2 + px)) {
			position: static;
			flex-direction: row;
		}

		@media (max-width: ($md3 + px)) {
			flex-shrink: 0;
		}
	}

	&__mobile {
		display: none;
		@media (max-width: ($md2 + px)) {
			display: flex;
			margin-top: 40px;
		}

		@media (max-width: ($md4 + px)) {
			margin-top: 30px;
		}
	}

	// .section-1__video-play

	&__video-play {
		width: 110px;
		height: 80px;
		border-radius: 40px;
		transition: 0.3s all;

		@media (max-width: ($md3 + px)) {
			width: 90px;
			height: 70px;
		}

		@media (max-width: ($md4 + px)) {
			width: 80px !important;
			height: 65px;
		}
	}

	// .section-1__video-text

	&__video-text {
		text-align: left;
		opacity: 0.5;
		color: #ffffff;
		font-size: 14px;
		font-weight: 600;
		line-height: calc(24 / 14 * 100%);
		padding-left: 34px;

		@media (max-width: ($md2 + px)) {
			padding-left: 0;
		}
	}
}

.section-2 {
	margin-top: 100px;

	@media (max-width: ($md3 + px)) {
		margin-top: 35px;

		.visible-hand {
			display: flex;
		}
	}

	// .section-2__container

	&__container {
	}

	// .section-2__items

	&__items {
		display: grid;
		grid-template-areas:
			"one two"
			"one three";
		gap: 40px;

		@media (max-width: ($md1 + px)) {
			gap: 20px;
		}

		@media (max-width: ($md2 + px)) {
			gap: 10px;
		}
		@media (max-width: ($md3 + px)) {
			grid-template-areas: "one two three";
			grid-template-columns: repeat(3, 310px);
			overflow: scroll;
			padding-bottom: 10px;
		}
	}

	// .section-2__item

	&__item {
		position: relative;
		border-radius: 14px;
		overflow: hidden;
		&:nth-of-type(1) {
			grid-area: one;

			.section-2__text {
				margin-top: 20px;
			}

			@media (max-width: ($md2 + px)) {
				img {
					filter: opacity(0.7);
				}
			}
		}

		&:nth-of-type(2) {
			grid-area: two;
			color: #fff;
			@media (max-width: ($md2 + px)) {
				img {
					filter: brightness(0.5);
				}
			}
			@media (max-width: ($md3 + px)) {
				color: inherit;
			}
		}

		&:nth-of-type(3) {
			grid-area: three;
			@media (max-width: ($md2 + px)) {
				img {
					filter: opacity(0.7);
				}
			}
		}

		img {
			height: 100%;
			@media (max-width: ($md3 + px)) {
				height: auto;
				border-radius: 14px;
				filter: none !important;
			}
		}
	}
	.section-2__item._white-block {
		color: #fff;
		@media (max-width: ($md3 + px)) {
			color: #000;
		}
		img {
			@media (max-width: ($md2 + px)) {
				filter: brightness(0.5);
			}
		}
	}

	// .section-2__inner

	&__inner {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding-left: 50px;
		padding-bottom: 47px;

		@media (max-width: ($md1 + px)) {
			padding-left: 30px;
			padding-bottom: 30px;
		}

		@media (max-width: ($md3 + px)) {
			position: relative;
			padding-left: 0;
			padding-bottom: 0;
			margin-top: 15px;
		}
	}

	// .section-2__title

	&__title {
		font-size: 24px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(32 / 24 * 100%);

		@media (max-width: ($md3 + px)) {
			font-size: 20px;
			br {
				display: none;
			}
		}
	}

	// .section-2__list

	&__list {
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		gap: 2px;

		@media (max-width: ($md3 + px)) {
			margin-top: 7px;
		}
	}
	// .section-2__list-item

	&__list-item {
	}

	// .section-2__text

	&__text {
		margin-top: 13px;

		@media (max-width: ($md3 + px)) {
			margin-top: 7px !important;
		}
	}

	// .section-2__credit

	&__credit {
		margin-top: 40px;
		background-image: url(../img/sec2-credit.webp);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		position: relative;
		width: 100%;
		border-radius: 14px;
		text-align: left;
		padding-left: 93px;
		padding-top: 62px;
		padding-bottom: 28px;
		z-index: 0;
		@media (max-width: ($md1 + px)) {
			margin-top: 20px;

			padding-left: 35px;
		}

		@media (max-width: ($md2 + px)) {
			margin-top: 10px;
		}

		@media (max-width: ($md3 + px)) {
			margin-top: 20px;
			padding-top: 25px;
		}
		@media (max-width: ($md5 + px)) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	// .section-2__cursor

	&__cursor {
		position: absolute;
		right: -107px;
		top: -12px;

		@media (max-width: ($md5 + px)) {
			right: -85px;
		}

		@media (max-width: ($md6 + px)) {
			max-width: 50px;
			right: -55px;
			top: -5px;
		}
	}

	// .section-2__steklofab

	&__steklofab {
		position: absolute;
		width: 100%;
		top: 24px;
		left: 0;
		bottom: 0;
		z-index: -2;
	}

	// .section-2__tinklogo

	&__tinklogo {
		position: absolute;
		z-index: -1;
		right: -12px;
		top: -12px;
		@media (max-width: ($md1 + px)) {
			max-width: 380px;
			right: -50px;
			top: 0;
		}

		@media (max-width: ($md2 + px)) {
			top: 120px;
			right: 0;
			bottom: 0;
			margin: auto;
		}
		@media (max-width: ($md3 + px)) {
			top: 50px;
			max-width: 55%;
		}

		@media (max-width: ($md5 + px)) {
			display: none;
		}
	}

	&__tinkologo-mob {
		display: none;

		@media (max-width: ($md5 + px)) {
			display: block;
			position: static;
			max-width: 100%;
			margin-top: 15px;
		}
	}
	// .section-2__credit-title

	&__credit-title {
		@media (max-width: ($md3 + px)) {
			max-width: 350px;
		}
	}

	// .section-2__conditions
	&__wrapper {
		display: flex;
		flex-direction: column;
		width: fit-content;
		@media (max-width: ($md5 + px)) {
			align-items: flex-start;
		}
	}
	&__conditions {
		margin-top: 18px;
		display: flex;
		gap: 10px;
		width: fit-content;
		@media (max-width: ($md2 + px)) {
			flex-direction: column;
		}
		@media (max-width: ($md5 + px)) {
			gap: 5px;
		}
	}

	// .section-2__condition

	&__condition {
		border-radius: 4px;
		background-color: #ffe815;
		height: 34px;
		display: flex;
		align-items: center;
		padding: 0 14px;
		font-size: 14px;
		font-weight: 400;
	}

	// .section-2__footnote

	&__footnote {
		text-align: right;
		margin-top: 28px;
		position: relative;
		color: #77818c;
		font-size: 14px;
		font-weight: 400;

		@media (max-width: ($md2 + px)) {
			text-align: left;
		}

		@media (max-width: ($md5 + px)) {
			margin-top: 17px;
		}

		@media (max-width: ($md6 + px)) {
			font-size: 13px;
		}
	}

	// .section-2__star

	&__star {
		color: #027ef2;
	}
}

.section-3 {
	margin-top: 110px;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	@media (max-width: ($md4 + px)) {
		.visible-hand {
			display: flex;
		}
	}

	& + .section-5 {
		margin-top: 120px;
		padding-top: 110px;

		@media (max-width: ($md3 + px)) {
			margin-top: 50px;
			padding-top: 30px;
		}
	}
	// .section-3__container

	&__container {
	}

	// .section-3__title

	&__title {
	}

	// .section-3__cards

	&__cards {
		margin-top: 62px;
		display: grid;
		gap: 15px;
		grid-template-columns: repeat(3, auto);
		grid-template-areas:
			"a a b b c c"
			"d d e e f f"
			"g g g i i i";
		@media (max-width: ($md1 + px)) {
			gap: 10px;
		}
		@media (max-width: ($md2 + px)) {
			grid-template-columns: repeat(2, auto);
			grid-template-areas:
				"a a a b b b"
				"c c c d d d"
				"e e e f f f"
				"g g g g g g"
				"i i i i i i";

			img {
				width: 100%;
			}
		}

		@media (max-width: ($md3 + px)) {
			margin-top: 30px;
		}
		@media (max-width: ($md4 + px)) {
			margin-top: 0;
			grid-template-columns: repeat(8, 300px);
			grid-template-areas: "a b c d e f g i";
			overflow: scroll;
			img {
				height: 100%;
			}
		}
	}

	// .section-3__card

	&__card {
		position: relative;
		display: flex;

		&:nth-of-type(1) {
			grid-area: a;
		}

		&:nth-of-type(2) {
			grid-area: b;
		}

		&:nth-of-type(3) {
			grid-area: c;
		}

		&:nth-of-type(4) {
			grid-area: d;
		}

		&:nth-of-type(5) {
			grid-area: e;
		}

		&:nth-of-type(6) {
			grid-area: f;
		}

		&:nth-of-type(7) {
			grid-area: g;
		}

		&:nth-of-type(8) {
			grid-area: i;
		}
		border-radius: 14px;
		overflow: hidden;
	}

	// .section-3__card-inner

	&__card-inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 30px;
		@media (max-width: ($md1 + px)) {
			padding: 20px;
		}

		@media (max-width: ($md4 + px)) {
			padding: 15px;
		}
	}

	// .section-3__card-title

	&__card-title {
		border-radius: 28px;
		background-color: #ffffff;
		padding: 0 30px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: fit-content;
		min-height: 56px;
		font-size: 20px;
		font-weight: 700;
		line-height: 130%;
		margin-bottom: 20px;

		@media (max-width: ($md1 + px)) {
			padding: 0 20px;
		}

		@media (max-width: ($md4 + px)) {
			font-size: 17.9px;
			padding: 0 15px;
		}
	}

	// .section-3__list

	&__list {
		padding-left: 36px;
	}

	// .section-3__list-item

	&__list-item {
		list-style-type: disc;
		padding-left: 0 !important;
	}

	// .section-3__card-text

	&__card-text {
		font-size: 16px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(28 / 16 * 100%);
		color: #ffffff;
		padding-left: 31px;
	}

	// .section-3__button

	&__button {
		justify-self: flex-end;
		max-width: 330px;
		height: 70px;
	}
}

.section-4 {
	margin-top: 30px;
	// .section-4__container

	&__container {
		@media (max-width: ($md2 + px)) {
			picture {
				display: none;
			}
			background-image: url(../img/sec4-map.png);
			background-size: cover;
			background-position: center;
			border-radius: 14px;
		}
	}

	&__link {
		position: relative;
		@media (max-width: ($md2 + px)) {
			height: 400px;
			display: block;
		}

		@media (max-width: ($md3 + px)) {
			height: 350px;
		}
	}

	// .section-4__title

	&__title {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		display: flex;
		gap: 13px;
		flex-direction: column;
		align-items: center;
		right: 0;
		margin: auto;
		text-align: center;
		padding-top: 37px;
		&::before {
			font-size: 35px;
		}
		span {
			text-decoration: underline;
			text-underline-offset: 10px;
			text-decoration-thickness: 1px;
			text-decoration-color: rgba(6, 7, 8, 0.1);
		}
		@media (max-width: ($md2 + px)) {
			padding-top: 20px;
			position: static;

			br {
				display: none;
			}
		}
		@media (max-width: ($md3 + px)) {
			br {
				display: none;
			}
		}
	}
}

.section-5 {
	max-width: 1880px;
	margin: auto;
	margin-top: -90px;
	padding-top: 200px;
	padding-bottom: 120px;
	background-size: cover;
	background-position: top center;
	border-radius: 20px;
	overflow: hidden;
	background-image: url(../img/sec5-back.png);
	@media (max-width: ($md3 + px)) {
		padding-top: 150px;
		padding-bottom: 30px;
	}
	// .section-5__container

	&__container {
	}

	// .section-5__title

	&__title {
		color: #fff;
		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-5__subtitle

	&__subtitle {
		margin-top: 30px;
		color: #fff;

		@media (max-width: ($md3 + px)) {
			margin-top: 15px;
		}

		._icon-sale::before {
			font-size: 33px;
			vertical-align: middle;
			margin: 0 5px;
			margin-top: -2px;
		}
	}

	// .section-5__quiz

	&__quiz {
		max-width: 1360px;
		margin: auto;
		margin-top: 57px;
		border-radius: 14px;
		background-color: #ffffff;

		@media (max-width: ($md3 + px)) {
			margin-top: 30px;
		}
	}
}

.section-6 {
	margin-top: 100px;

	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}

	@media (max-width: ($md4 + px)) {
		.visible-hand {
			display: flex;
		}
	}
	// .section-6__container

	&__container {
	}

	// .section-6__title

	&__title {
		span::before {
			font-size: 36px;
			vertical-align: middle;
			margin-left: 5px;
			@media (max-width: ($md3 + px)) {
				font-size: 30px;
			}
			@media (max-width: ($md4 + px)) {
				font-size: 25px;
			}
			@media (max-width: ($md5 + px)) {
				font-size: 20px;
			}
		}
	}

	// .section-6__subtitle

	&__subtitle {
		margin-top: 22px;
		@media (max-width: ($md3 + px)) {
			margin-top: 15px;
		}
	}

	// .section-6__cards

	&__cards {
		margin-top: 54px;
		display: flex;
		flex-direction: column;
		gap: 20px;

		@media (max-width: ($md3 + px)) {
			margin-top: 30px;
			gap: 15px;
		}
		@media (max-width: ($md4 + px)) {
			flex-direction: row;
			overflow: scroll;
			gap: 10px;
			margin-top: 0;
			padding-bottom: 8px;
		}
	}

	// .section-6__card

	&__card {
		position: relative;
		border-radius: 14px;
		overflow: hidden;
		z-index: 1;

		@media (max-width: ($md2 + px)) {
			background-size: cover;
			background-position: 20%;

			&::before {
				content: "";
				z-index: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-color: rgba($color: #fff, $alpha: 0.5);
			}
		}
		@media (max-width: ($md3 + px)) {
			background-position: 30%;
		}

		@media (max-width: ($md4 + px)) {
			background: none !important;

			flex: 0 0 300px;
			&::before {
				display: none;
			}
		}

		&:last-of-type {
			.section-6__card-inner {
				margin-top: 10px;
				@media (max-width: ($md1 + px)) {
					margin-top: 0;
				}
				@media (max-width: ($md2 + px)) {
					position: static;
					padding-left: 0;
				}
			}
			@media (max-width: ($md2 + px)) {
				.section-6__card-back {
					display: none;

					@media (max-width: ($md4 + px)) {
						display: block;
					}
				}

				padding-top: 40px;
				padding-bottom: 40px;
				padding-left: 50px;

				//&::before {
				//	content: "";
				//	position: absolute;
				//	width: 100%;
				//	height: 100%;
				//	top: 0;
				//	left: 0;
				//	background-image: url(../img/sec6-card3.png);
				//	background-size: cover;
				//	background-position: 20%;
				//	filter: opacity(0.65);
				//	@media (max-width: ($md3 + px)) {
				//		background-position: 30%;
				//	}

				//	@media (max-width: ($md4 + px)) {
				//		display: none;
				//	}
				//}
			}
			@media (max-width: ($md3 + px)) {
				.section-6__list {
					max-width: 350px;
				}
			}

			@media (max-width: ($md4 + px)) {
				padding: 0;
				order: -1;
			}
		}
	}

	// .section-6__card-back

	&__card-back {
		@media (max-width: ($md2 + px)) {
			filter: opacity(0.65);
		}
		@media (max-width: ($md3 + px)) {
			filter: opacity(0.5);
		}

		@media (max-width: ($md4 + px)) {
			filter: none;
			border-radius: 14px;
		}
	}

	.section-6__card._white-block {
		.section-6__video-text {
			color: #fff;
			text-decoration-color: rgba($color: #ffffff, $alpha: 0.3) !important;
			@media (max-width: ($md2 + px)) {
				color: #fff !important;
			}
			@media (max-width: ($md4 + px)) {
				color: #000 !important;
				text-decoration-color: rgba(0, 0, 0, 0.3) !important;
			}
		}
		@media (max-width: ($md2 + px)) {
			background-size: cover;
			background-position: 20%;

			&::before {
				content: "";
				z-index: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-color: rgba($color: #000, $alpha: 0.5);
			}
		}
		@media (max-width: ($md3 + px)) {
			background-position: 30%;
		}
		@media (max-width: ($md4 + px)) {
			background: none;
			color: #000 !important;
			&::before {
				display: none;
			}
		}
	}
	// .section-6__card-icon

	&__card-icon {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		height: 100%;

		@media (max-width: ($md4 + px)) {
			display: none;
		}
	}

	// .section-6__card-inner

	&__card-inner {
		position: absolute;
		width: 100%;
		top: 0;
		bottom: 0;
		margin: auto;
		left: 0;
		padding-left: 91px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media (max-width: ($md1 + px)) {
			padding-left: 50px;
		}

		@media (max-width: ($md4 + px)) {
			position: static;
			padding: 0;
			padding-top: 10px;
		}
	}

	// .section-6__card-title

	&__card-title {
		z-index: 1;
		@media (max-width: ($md4 + px)) {
			br {
				display: none;
			}
			font-size: 21px;
		}
	}

	// .section-6__list

	&__list {
		margin-top: 11px;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	// .section-6__list-item

	&__list-item {
	}

	// .section-6__text

	&__text {
		margin-top: 20px;
		@media (max-width: ($md4 + px)) {
			margin-top: 11px;
		}
	}

	// .section-6__video-block

	&__video-block {
		position: absolute;
		display: flex;
		right: 71px;
		bottom: 55px;
		gap: 17px;
		align-items: center;

		@media (max-width: ($md1 + px)) {
			right: 40px;
		}
		@media (max-width: ($md2 + px)) {
			position: relative;
			bottom: unset;
			right: unset;
			z-index: 1;
			margin-top: 20px;
		}

		@media (max-width: ($md4 + px)) {
			margin-top: 12px;
		}
	}

	// .section-6__video-play

	&__video-play {
		width: 78px;
		height: 78px;
		border-radius: 50%;

		img {
			padding-left: 5px;
		}

		@media (max-width: ($md4 + px)) {
			width: 50px !important;
			height: 50px;
		}
	}

	// .section-6__video-text

	&__video-text {
		font-size: 16px;
		font-weight: 400;
		font-style: normal;
		text-align: left;
		letter-spacing: normal;
		line-height: calc(26 / 16 * 100%);
		text-decoration: underline;
		text-underline-offset: 5px;
		text-decoration-thickness: 1px;
		text-decoration-color: rgba(0, 0, 0, 0.1);

		@media (max-width: ($md2 + px)) {
			text-decoration-color: rgba(0, 0, 0, 0.1) !important;
			color: #000 !important;
		}
	}
}

.section-7 {
	max-width: 1560px;
	border-radius: 40px 40px 0 0;
	background-color: #f7f8fa;
	margin: auto;
	margin-top: 120px;
	padding-top: 110px;

	padding-bottom: 120px;

	@media (max-width: ($md3 + px)) {
		padding-bottom: 30px;
		padding-top: 30px;
		margin-top: 50px;
	}

	& + .section-15 {
		margin-top: 110px;
		padding-bottom: 0 !important;
		border-bottom: none;
		// .section-15__container
		@media (max-width: ($md1 + px)) {
			margin-top: 120px;
		}
		@media (max-width: ($md3 + px)) {
			margin-top: 50px;
			padding-bottom: 30px;
		}
	}
	// .section-7__container

	&__container {
	}

	// .section-7__title

	&__title {
		@media (max-width: ($md5 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-7__subtitle

	&__subtitle {
		margin-top: 12px;
	}

	// .section-7__sliders

	&__sliders {
	}

	// .section-7__sliders-wrapper

	&__sliders-wrapper {
		display: grid;
		align-items: start;
		grid-template-columns: 360px auto;
		gap: 0px 40px;
		margin-top: 55px;

		@media (max-width: ($md1 + px)) {
			grid-template-columns: 300px auto;
			gap: 0 30px;
		}
		@media (max-width: ($md2 + px)) {
			grid-template-columns: auto;
			gap: 40px;
		}

		@media (max-width: ($md3 + px)) {
			grid-template-columns: auto;
			gap: 40px;
			margin-top: 30px;
			gap: 20px;
		}
	}

	// .section-7__nav-slider

	&__nav-slider {
	}

	// .section-7__for-slider

	&__for-slider {
	}

	// .section-7__more

	&__more {
		grid-column: -1 / 1;
		width: 360px;
		margin: auto;
		margin-top: 50px;
		height: 86px;
		gap: 14px;

		@media (max-width: ($md2 + px)) {
			margin-top: 0;
		}
	}

	.slider-nav {
		box-shadow: 0 30px 80px rgba(0, 0, 0, 0.04);
		border-radius: 12px;
		background-color: #ffffff;
		overflow: hidden;
		position: relative;
		.slick-slide {
			height: 80px;
			&:not(:last-of-type)::after {
				content: "";
				width: 86.596%;
				height: 1px;
				display: block;
				margin: auto;
				background-color: rgba(0, 0, 0, 0.1);
				@media (max-width: ($md2 + px)) {
					width: 1px;
					height: 86.596%;
					position: absolute;
					top: 0;
					bottom: 0;
				}
			}
		}
		.slick-track {
			@media (min-width: ($md2 + px)) {
				transform: translate3d(0, 0, 0) !important;
			}
		}

		// .slider-nav__item

		&__item {
			height: 80px;
			width: 100%;
			padding-left: 45px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			cursor: pointer;
			@media (max-width: ($md1 + px)) {
				padding-left: 35px;
			}
			@media (max-width: ($md2 + px)) {
				padding: 0 5px;
				text-align: center;
			}
		}

		// .slider-nav__text

		&__text {
			color: rgba($color: #060708, $alpha: 0.6);

			font-size: 16px;
			font-weight: 600;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(22 / 16 * 100%);

			@media (max-width: ($md2 + px)) {
				font-size: 15px;
			}
		}

		.slick-slide.slick-current.slick-active {
			.slider-nav__item {
				box-shadow: 0 15px 30px rgba(1, 108, 208, 0.15), inset 0 0 18px 2px rgba(255, 255, 255, 0.15);
				border-radius: 10px;
				background-image: linear-gradient(to top, #016cd0 0%, #027aeb 100%);
			}
			.slider-nav__text {
				color: #fff;
				font-weight: 700;
			}
		}
	}

	.slider-for {
		min-height: 0;
		min-width: 0;
		// .slider-for__item

		&__item {
		}

		// .slider-for__title

		&__title {
			font-size: 28px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(38 / 28 * 100%);
			@media (max-width: ($md4 + px)) {
				font-size: 25px;
			}
			@media (max-width: ($md5 + px)) {
				font-size: 23px;
			}
		}

		// .slider-for__works

		&__works {
			margin-top: 23px;
			display: flex;
			gap: 53px;

			@media (max-width: ($md3 + px)) {
				gap: 10px;
				flex-direction: column;
				margin-top: 18px;
			}
		}

		// .slider-for__works-text

		&__works-text {
			text-transform: uppercase;
			font-weight: 700;
		}

		// .slider-for__works-list

		&__works-list {
			display: flex;
			flex-direction: column;
			gap: 11px;
		}

		// .slider-for__works-item

		&__works-item {
			padding-left: 20px;
			&::before {
				top: 10px;
			}
		}

		// .slider-for__photos

		// .slider-for__main-photo

		&__main-photo {
			padding-bottom: 109.806%;
			width: auto;
			background-repeat: no-repeat;
			border-radius: 16px;
			display: block;
			width: auto;

			background-size: cover;
			@media (max-width: ($md2 + px)) {
				padding-bottom: 70%;
				width: 100%;
			}
		}

		// .slider-for__photo

		&__photo {
			padding-bottom: 64.29%;
			background-size: cover;
			background-position: top center;
			background-repeat: no-repeat;
			display: block;
			width: 100%;
			border-radius: 16px;
			@media (max-width: ($md2 + px)) {
				padding-bottom: 80%;
				width: 100%;
			}
			@media (max-width: ($md4 + px)) {
				padding-bottom: 70%;
			}
		}

		.slider-photos__wrapper {
			display: grid;
			grid-template-columns: 63.75% auto;
			gap: 8px;
			@media (max-width: ($md2 + px)) {
				display: flex;
				flex-direction: column;
				min-height: 0;
				min-width: 0;
			}
		}

		.slider-photos__nav .slick-slide {
			padding: 4px 0;
			@media (max-width: ($md2 + px)) {
				padding: 0 4px;
			}
		}

		.slider-photos__nav .slick-list {
			margin: -4px 0;
			@media (max-width: ($md2 + px)) {
				margin: 0 -4px;
			}
		}
		// .slider-for__footer

		&__footer {
			display: flex;
			gap: 20px;
			margin-top: 60px;
			@media (max-width: ($md2 + px)) {
				margin-top: 40px;
			}

			@media (max-width: ($md3 + px)) {
				margin-top: 20px;
				gap: 10px;
			}

			@media (max-width: ($md4 + px)) {
				flex-direction: column;
				align-items: center;
			}
		}

		// .slider-for__button

		&__button {
			width: 100%;
			max-width: 380px;
			height: 100px;
		}

		// .slider-for__next-button

		&__next-button {
			width: 100%;
			height: 100px;
			border-radius: 10px;
			font-size: 16px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(26 / 16 * 100%);
			color: rgba($color: #000000, $alpha: 0.5);
			border: 1px solid rgba($color: #000000, $alpha: 0.1);
			@media (max-width: ($md4 + px)) {
				max-width: 380px;
				width: 100%;
			}
		}
	}
}

.section-8 {
	margin: auto;
	padding-top: 110px;
	padding-bottom: 120px;
	max-width: 1880px;
	background-image: url(../img/sec8-back.png);
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 20px;

	& + .section-23 {
		margin-top: 0;
		max-width: 1880px;
		margin: auto;
		padding-top: 120px;
		border-radius: 20px;
		@media (max-width: ($md3 + px)) {
			padding-top: 30px;
		}
	}

	@media (max-width: ($md2 + px)) {
		padding-top: 80px;
		padding-bottom: 80px;
	}

	@media (max-width: ($md3 + px)) {
		padding-top: 30px;
		padding-bottom: 30px;
		.visible-hand {
			display: flex;
		}
	}
	// .section-8__container

	&__container {
	}

	// .section-8__title

	&__title {
		color: #fff;
	}

	// .section-8__reviews

	&__reviews {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 45px;
		margin-top: 60px;

		@media (max-width: ($md1 + px)) {
			gap: 20px;
		}

		@media (max-width: ($md2 + px)) {
			gap: 12px;
		}

		@media (max-width: ($md3 + px)) {
			display: flex;
			overflow: scroll;
			margin-top: 0;
		}
	}

	// .section-8__review

	&__review {
		box-shadow: 0 30px 60px rgba(0, 0, 0, 0.03);
		border-radius: 12px;
		overflow: hidden;
		background-color: #ffffff;
		display: flex;
		flex-direction: column;

		@media (max-width: ($md3 + px)) {
			flex: 0 0 300px;
		}
	}

	// .section-8__more

	&__more {
		gap: 14px;
		height: 86px;
		width: 371px;
		margin: auto;
		margin-top: 60px;

		@media (max-width: ($md3 + px)) {
			margin-top: 30px;
		}

		@media (max-width: ($md4 + px)) {
			margin-top: 20px;
		}

		@media (max-width: ($md5 + px)) {
			width: 100%;
		}

		@media (max-width: ($md6 + px)) {
			gap: 10px;
			font-size: 15px;
		}
	}
}

.review {
	// .review__photo

	&__photo {
		background-position: top center;
		background-size: cover;
		background-repeat: no-repeat;
		padding-bottom: 76%;
	}

	// .review__inner

	&__inner {
		padding: 40px 45px 45px 45px;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		@media (max-width: ($md1 + px)) {
			padding: 30px;
			padding-top: 25px;
		}

		@media (max-width: ($md2 + px)) {
			padding: 15px;
			padding-top: 20px;
		}
	}

	// .review__icon

	&__icon {
		position: absolute;
		top: -23px;
		right: 30px;
		width: 46px;
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #027ef2;
		border-radius: 50%;

		@media (max-width: ($md2 + px)) {
			width: 40px;
			height: 40px;
			right: 20px;
		}
	}

	// .review__quote

	&__quote {
		position: absolute;
		top: 20px;
		left: 29px;

		@media (max-width: ($md1 + px)) {
			left: 15px;
		}

		@media (max-width: ($md2 + px)) {
			width: 40px;
			left: 8px;
			top: 10px;
		}
	}

	// .review__text

	&__text {
		font-size: 22px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(34 / 22 * 100%);
		position: relative;
		z-index: 1;
		flex-grow: 1;

		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
			font-size: 20px;
		}

		@media (max-width: ($md2 + px)) {
			font-size: 18px;
			br {
				display: none;
			}
		}
	}

	// .review__button

	&__button {
		justify-self: flex-end;
		margin-top: 25px;
		font-size: 14px;
		font-weight: 700;
		line-height: 24px;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0.42px;
		gap: 20px;
		height: 80px;
		@media (max-width: ($md2 + px)) {
			gap: 10px;
		}

		img {
			width: 12px;
		}
	}
}

.section-9 {
	margin-top: 110px;

	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
		.visible-hand {
			display: flex;
		}
	}
	// .section-8__container

	&__container {
	}

	// .section-8__title

	&__title {
		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-8__cards

	&__cards {
		display: grid;
		grid-template-areas:
			"a a"
			"b c";
		gap: 30px;
		margin-top: 60px;

		@media (max-width: ($md1 + px)) {
			gap: 15px;
		}

		@media (max-width: ($md3 + px)) {
			grid-template-areas: "a b c";
			grid-template-columns: repeat(3, 300px);
			overflow: scroll;
			gap: 10px;
			margin-top: 0;
		}
	}

	// .section-8__card

	&__card {
		position: relative;
		border-radius: 14px;
		overflow: hidden;
	}

	// .section-8__card1

	// .section-8__card-back

	&__card-back {
		@media (max-width: ($md1 + px)) {
			height: 100%;
		}
		@media (max-width: ($md2 + px)) {
			filter: brightness(0.6);
		}

		@media (max-width: ($md3 + px)) {
			display: none;
		}
	}

	// .section-8__card-inner

	&__card-inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1;
		color: #fff;
		padding-left: 51px;
		padding-top: 53px;

		@media (max-width: ($md1 + px)) {
			padding-left: 40px;
			padding-top: 40px;
		}

		@media (max-width: ($md2 + px)) {
			padding-left: 30px;
			padding-right: 30px;
			padding-top: 25px;
		}

		@media (max-width: ($md3 + px)) {
			position: static;
			padding-right: 20px;
			padding-left: 20px;
		}
	}

	// .section-8__card-leftside

	&__card-leftside {
	}

	// .section-8__card-title

	&__card-title {
		@media (max-width: ($md3 + px)) {
			br {
				display: none;
			}
			font-size: 24px;
		}

		@media (max-width: ($md5 + px)) {
			font-size: 23px;
		}
		@media (max-width: ($md6 + px)) {
			font-size: 21px;
		}
	}

	// .section-8__ellipse

	&__ellipse {
		border-radius: 25px;
		border: 1px solid rgba(255, 255, 255, 0.4);
		padding: 0 25px 3px 19px;
		@media (max-width: ($md1 + px)) {
			display: block;
			width: fit-content;
		}
		@media (max-width: ($md3 + px)) {
			padding: 0;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	// .section-8__card-subtitle

	&__card-subtitle {
		font-size: 24px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(34 / 24 * 100%);
		margin-top: 18px;
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
			font-size: 22px;
		}

		@media (max-width: ($md3 + px)) {
			font-size: 20px;
		}

		@media (max-width: ($md6 + px)) {
			font-size: 18px;
		}
	}

	// .section-8__card-rightside

	&__card-rightside {
	}

	// .section-8__card-video

	&__card-video {
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: center;

		@media (max-width: ($md3 + px)) {
			gap: 10px;
			padding-bottom: 20px;
		}
	}

	// .section-8__card-link

	&__card-link {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		&::before {
			content: "";
			position: absolute;
			width: 78px;
			height: 78px;
			box-shadow: inset 0 -3px 3px 1px rgba(255, 255, 255, 0.1), inset 0 0 19px 1px rgba(255, 255, 255, 0.1);
			background-image: linear-gradient(to top, #337bda 0%, #4896ff 100%);
			border-radius: 50%;
		}

		&::after {
			content: "";
			position: absolute;
			width: 78px;
			height: 78px;
			background-image: url(../img/play.png);
			background-repeat: no-repeat;
			background-position: 54.5% 50%;
		}
	}

	// .section-8__video-text

	&__video-text {
		font-size: 16px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(26 / 16 * 100%);
		text-align: center;
		text-decoration: underline;
		text-underline-offset: 5px;
		text-decoration-thickness: 1px;
		text-decoration-color: rgba(255, 255, 255, 0.2);

		@media (max-width: ($md3 + px)) {
			font-size: 15px;
		}
	}

	&__text {
		margin-top: 12px;

		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}

		@media (max-width: ($md3 + px)) {
			max-width: 190px;
		}
	}

	// .section-8__card2
	&__card1 {
		grid-area: a;

		.section-9__card-inner {
			display: flex;
			align-items: center;
			top: 0;
			bottom: 0;
			gap: 115px;
			padding-left: 90px;
			padding-top: 0;

			@media (max-width: ($md1 + px)) {
				padding-left: 40px;
			}

			@media (max-width: ($md2 + px)) {
				padding-right: 20px;
				padding-left: 30px;
				gap: 20px;
			}

			@media (max-width: ($md3 + px)) {
				flex-direction: column;
				padding-top: 25px;
				padding-right: 20px;
				padding-left: 20px;
			}
		}

		.section-9__card-subtitle {
			margin-top: 18px;
		}

		.section-9__card-back {
			@media (max-width: ($md2 + px)) {
				filter: none;
			}
		}

		@media (max-width: ($md3 + px)) {
			&::before {
				content: "";
				background-image: url(../img/sec8-card1.png);
				background-size: cover;
				background-position: right;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: -1;
				filter: brightness(0.7);
			}
		}
	}

	&__card2 {
		grid-area: b;
		@media (max-width: ($md3 + px)) {
			&::before {
				content: "";
				background-image: url(../img/sec8-card2.png);
				background-size: cover;
				background-position: 70%;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: -1;
				filter: brightness(0.5);
			}
		}
	}

	&__card3 {
		grid-area: c;
		@media (max-width: ($md3 + px)) {
			&::before {
				content: "";
				background-image: url(../img/sec8-card3.png);
				background-size: cover;
				background-position: 80%;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: -1;
				filter: brightness(0.5);
			}
		}
	}

	// .section-8__list

	&__list {
		margin-top: 30px;
		display: flex;
		flex-direction: column;
		gap: 30px;
		padding-left: 20px;
	}

	// .section-8__list-item

	&__list-item {
		display: flex;
		gap: 14px;

		picture {
			@media (max-width: ($md2 + px)) {
				max-width: 70px;
			}
		}
	}
}

.section-10 {
	max-width: 1880px;
	margin: auto;
	margin-top: 120px;
	padding-top: 110px;
	padding-bottom: 120px;
	background-image: url(../img/sec10-back.png);
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 20px;

	@media (max-width: ($md2 + px)) {
		padding-top: 70px;
		padding-bottom: 70px;
	}

	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	// .section-10__container

	&__container {
	}

	// .section-10__inner

	&__inner {
		display: flex;
		justify-content: space-between;
		@media (max-width: ($md2 + px)) {
			flex-direction: column;
			gap: 50px;
		}

		@media (max-width: ($md3 + px)) {
			gap: 30px;
		}
	}

	// .section-10__leftside

	&__leftside {
		color: #fff;
		position: relative;
	}

	// .section-10__title

	&__title {
		text-align: left;

		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
		@media (max-width: ($md3 + px)) {
			text-align: center;
		}
	}

	// .section-10__subtitle

	&__subtitle {
		font-size: 28px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(38 / 28 * 100%);
		text-align: left;
		margin-top: 45px;

		span {
			margin-left: 3px;
			margin-right: 3px;
			vertical-align: middle;
			&::before {
				font-size: 35px;
			}
		}
		@media (max-width: ($md2 + px)) {
			font-size: 26px;
			margin-top: 20px;
		}
		@media (max-width: ($md3 + px)) {
			text-align: center;
			font-size: 24px;

			span {
				&::before {
					font-size: 30px;
				}
			}
		}

		@media (max-width: ($md4 + px)) {
			font-size: 22px;
		}

		@media (max-width: ($md6 + px)) {
			font-size: 20px;
			span {
				&::before {
					font-size: 25px;
				}
			}
		}
	}

	// .section-10__list

	&__list {
		margin-top: 25px;
		display: flex;
		flex-direction: column;
		gap: 35px;

		@media (max-width: ($md1 + px)) {
			gap: 25px;
		}
		@media (max-width: ($md3 + px)) {
			margin: auto;
			max-width: 450px;
			margin-top: 25px;
		}

		@media (max-width: ($md4 + px)) {
			gap: 15px;
		}

		@media (max-width: ($md5 + px)) {
			margin-top: 17px;
		}
	}

	// .section-10__list-item

	&__list-item {
		display: grid;
		grid-template-columns: 150px auto;
		align-items: center;
		gap: 19px;

		picture {
			display: flex;
			justify-content: center;
		}

		@media (max-width: ($md3 + px)) {
			grid-template-columns: 110px auto;
		}

		@media (max-width: ($md5 + px)) {
			grid-template-columns: 80px auto;
		}
	}

	// .section-10__list-text

	&__list-text {
		@media (max-width: ($md3 + px)) {
			font-size: 18px;
			br {
				display: block;
			}
		}

		@media (max-width: ($md5 + px)) {
			font-size: 16px;
		}
	}

	// .section-10__lists

	&__docs {
		position: absolute;
		bottom: -177px;
		right: -497px;
		@media (max-width: ($md1 + px)) {
			display: none;
		}

		@media (max-width: ($md2 + px)) {
			display: block;
			right: -120px;
			bottom: 0;
			max-width: 450px;
		}

		@media (max-width: ($md3 + px)) {
			display: none;
		}
	}

	// .section-10__form

	&__form {
		max-width: 510px;
		margin-top: 10px;
		@media (max-width: ($md2 + px)) {
			margin: auto;
		}
	}
}

.section-11 {
	margin-top: 110px;
	padding-bottom: 120px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);

	@media (max-width: ($md2 + px)) {
		.visible-hand {
			display: flex;
		}
	}

	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
		padding-bottom: 30px;
	}
	// .section-10__container

	&__container {
	}

	// .section-10__title

	&__title {
	}

	// .section-10__subtitle

	&__subtitle {
		margin-top: 23px;

		@media (max-width: ($md3 + px)) {
			br {
				display: none;
			}
			margin-top: 15px;
		}
	}

	// .section-10__items

	&__items {
		margin-top: 57px;
		display: grid;
		grid-template-columns: repeat(3, auto);
		justify-content: center;
		gap: 20px;

		@media (max-width: ($md1 + px)) {
			gap: 12px;
			grid-template-columns: repeat(3, 1fr);
		}

		@media (max-width: ($md2 + px)) {
			gap: 10px;
			grid-template-columns: repeat(3, 300px);
			overflow: scroll;
			justify-content: start;
			padding-bottom: 7px;
			margin-top: 0;
		}
	}

	// .section-10__item

	&__item {
	}

	&__inner {
		padding-left: 10px;
		margin-top: 29px;

		@media (max-width: ($md1 + px)) {
			padding-left: 5px;
		}

		@media (max-width: ($md2 + px)) {
			margin-top: 20px;
		}

		@media (max-width: ($md3 + px)) {
			margin-top: 10px;
		}
	}

	// .section-10__pic

	&__pic {
	}

	// .section-10__item-title

	&__item-title {
		font-size: 24px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(32 / 24 * 100%);
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
			font-size: 22px;
		}

		@media (max-width: ($md3 + px)) {
			font-size: 20px;
		}

		@media (max-width: ($md5 + px)) {
			font-size: 18px;
		}
	}

	// .section-10__item-text

	&__item-text {
		margin-top: 13px;
		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
		}
		@media (max-width: ($md3 + px)) {
			margin-top: 9px;
		}
	}

	// .section-10__footer

	&__footer {
		margin-top: 57px;
		display: flex;
		justify-content: center;
		gap: 20px;

		@media (max-width: ($md3 + px)) {
			flex-direction: column;
			margin: auto;
			align-items: center;
			margin-top: 30px;
			gap: 15px;
		}

		@media (max-width: ($md6 + px)) {
			img {
				max-width: 20px;
			}
		}
	}

	// .section-10__btn

	&__btn {
		width: 402px;
		height: 94px;
		text-align: left;
		gap: 14px;
		@media (max-width: ($md6 + px)) {
			font-size: 14px;
			gap: 10px;
		}
	}

	// .section-10__more

	&__more {
		width: 370px;
		height: 94px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 14px;

		@media (max-width: ($md6 + px)) {
			gap: 10px;
		}
	}

	// .section-10__info

	&__info {
	}
}

.section-12 {
	margin-top: 110px;
	@media (max-width: ($md3 + px)) {
		margin-top: 40px;
	}
	// .section-12__container
	@media (max-width: 620px) {
		.visible-hand {
			display: flex;
		}
	}
	&__container {
	}

	// .section-12__title

	&__title {
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
		@media (max-width: ($md5 + px)) {
			font-size: 24px;
		}
	}

	// .section-12__subtitle

	&__subtitle {
		margin-top: 25px;

		@media (max-width: ($md3 + px)) {
			margin-top: 15px;
		}
	}

	// .section-12__items

	&__items {
		margin-top: 57px;
		display: flex;
		gap: 30px;

		@media (max-width: ($md1 + px)) {
			gap: 17px;
		}

		@media (max-width: ($md2 + px)) {
			gap: 10px;
		}
		@media (max-width: ($md3 + px)) {
			justify-content: center;
			margin-top: 30px;
		}
		@media (max-width: 620px) {
			overflow: scroll;
			margin-top: 0;
			justify-content: flex-start;
		}
	}

	// .section-12__item

	&__item {
		position: relative;
		@media (max-width: ($md3 + px)) {
			flex: 0 0 300px;

			&:nth-of-type(1) {
				order: 2;
			}
		}
	}

	// .section-12__back

	&__back {
		@media (max-width: ($md2 + px)) {
			filter: brightness(0.8);
		}
		@media (max-width: ($md3 + px)) {
			filter: none;
			border-radius: 14px;
		}
	}

	// .section-12__inner

	&__inner {
		position: absolute;
		top: 0;
		left: 0;
		padding-top: 55px;
		padding-left: 50px;
		color: #fff;

		@media (max-width: ($md1 + px)) {
			padding-top: 30px;
			padding-left: 30px;
			padding-right: 30px;
		}
		@media (max-width: ($md2 + px)) {
			padding-top: 25px;
		}
		@media (max-width: ($md3 + px)) {
			position: static;
			color: #060708;
			padding-left: 0;
			padding-top: 15px;
		}
	}

	// .section-12__item-title

	&__item-title {
		font-size: 28px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(38 / 28 * 100%);
		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
			font-size: 26px;
		}
		@media (max-width: ($md3 + px)) {
			font-size: 22px;
		}
		@media (max-width: ($md4 + px)) {
			font-size: 20px;
		}
	}

	// .section-12__item-text

	&__item-text {
		font-size: 20px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(30 / 20 * 100%);
		margin-top: 11px;
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
			font-size: 18px;
			margin-top: 7px;
		}

		@media (max-width: ($md5 + px)) {
			font-size: 16px;
		}
	}
}

.section-13 {
	max-width: 1880px;
	margin: auto;
	margin-top: 120px;
	padding: 120px 0;
	border-radius: 20px;
	background-size: cover;
	background-position: top center;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
		padding: 30px 0;
		background-position: right;
	}
	// .section-13__container

	&__container {
	}

	// .section-13__form

	&__form {
		margin-left: auto;
		width: 510px;

		.capture-form {
			width: 100%;
		}

		@media (max-width: ($md3 + px)) {
			display: flex;
			justify-content: center;
			width: auto;
		}
	}

	.capture-form__cube {
		top: -19px;
		right: -77px;

		@media (max-width: ($md1 + px)) {
			top: -40px;
		}

		@media (max-width: ($md4 + px)) {
			right: -40px;
			top: -30px;
		}
	}

	.capture-form__subtitle {
		margin-top: 9px;
	}
}

.section-14 {
	margin-top: 112px;

	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	// .section-14__container

	&__container {
	}

	// .section-14__title

	&__title {
	}

	// .section-14__subtitle

	&__subtitle {
		margin-top: 23px;

		@media (max-width: ($md3 + px)) {
			margin-top: 15px;
		}

		@media (max-width: ($md4 + px)) {
			margin-top: 10px;
		}
	}

	// .section-14__items

	&__items {
		max-width: 528px;
		margin-top: 57px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		@media (max-width: ($md3 + px)) {
			margin: auto;
			margin-top: 30px;
			gap: 0;
		}
	}

	// .section-14__item

	&__item {
		padding: 24px 34px 27px 34px;
		position: relative;

		@media (max-width: ($md3 + px)) {
			padding: 20px;
		}
	}

	// .section-14__black

	&__black {
		color: #fff;
		border-radius: 14px;
		background-color: #000000;
		overflow: hidden;

		.section-14__number {
			opacity: 0.03;
			color: #ffffff;
			font-size: 200px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: 32px;
		}
	}

	// .section-14__item-title

	&__item-title {
		font-size: 24px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(32 / 24 * 100%);

		@media (max-width: ($md3 + px)) {
			font-size: 22px;
		}

		@media (max-width: ($md5 + px)) {
			font-size: 19px;
		}

		@media (max-width: ($md6 + px)) {
			font-size: 18px;
		}
	}

	// .section-14__list

	&__list {
		margin-top: 13px;
		display: flex;
		flex-direction: column;
		gap: 6px;
	}

	// .section-14__list-item

	&__list-item {
		padding-left: 18px;
	}

	// .section-14__number

	&__number {
		opacity: 0.04;
		color: #060708;
		font-size: 200px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: 32px;
		position: absolute;

		bottom: 15px;
		right: 0;

		@media (max-width: ($md3 + px)) {
			bottom: 30px;
		}
	}

	// .section-14__item-text

	&__item-text {
		margin-top: 13px;
		@media (max-width: ($md3 + px)) {
			br {
				display: block;
			}
		}
		@media (max-width: ($md5 + px)) {
			br {
				display: none;
			}
			margin-top: 8px;
		}
	}

	// .section-14__item-text2

	&__item-text2 {
		font-size: 15px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(24 / 15 * 100%);
		margin-top: 7px;

		@media (max-width: ($md6 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-14__docs

	&__docs {
		position: absolute;
		right: 15px;
		top: 150px;

		@media (max-width: ($md1 + px)) {
			max-width: 600px;
			top: 250px;
			right: -70px;
		}

		@media (max-width: ($md2 + px)) {
			max-width: 550px;
			right: -230px;
			z-index: -1;
		}
		@media (max-width: ($md3 + px)) {
			display: none;
		}
	}
}

.section-15 {
	margin-top: 214px;
	padding-bottom: 117px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	// .section-15__container
	@media (max-width: ($md1 + px)) {
		margin-top: 120px;
	}
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
		padding-bottom: 30px;
	}
	@media (max-width: ($md4 + px)) {
		.visible-hand {
			display: flex;
		}
	}

	& + .section-5 {
		margin-top: 120px;
		padding-top: 110px;

		@media (max-width: ($md3 + px)) {
			margin-top: 50px;
			padding-top: 30px;
		}
	}

	&__container {
	}

	// .section-15__title

	&__title {
		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-15__cooperators

	&__cooperators {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 47px 12px;
		margin-top: 61px;
		@media (max-width: ($md2 + px)) {
			grid-template-columns: repeat(3, 1fr);
		}
		@media (max-width: ($md3 + px)) {
			margin-top: 30px;
			grid-template-columns: repeat(2, 1fr);
			gap: 25px 10px;
		}

		@media (max-width: ($md4 + px)) {
			display: block;
			margin-top: 10px;
		}
	}

	// .section-15__cooperator

	&__cooperator {
		flex: 0 0 300px;
	}

	// .section-15__formblock

	&__formblock {
		grid-column: span 2;
		display: flex;

		@media (max-width: ($md4 + px)) {
			margin-top: 20px;
			justify-content: center;
		}
	}

	// .section-15__form

	&__form {
		.capture-form {
			padding: 5px;
			box-shadow: 0 0 81px rgba(0, 0, 0, 0.04);
		}
		.capture-form__block {
			border: 1px solid rgba(0, 0, 0, 0.05);
			border-radius: 12px;
			padding: 47px 40px 48px 39px;

			@media (max-width: ($md3 + px)) {
				padding: 35px;
			}
			@media (max-width: ($md4 + px)) {
				padding: 30px;
				padding-top: 20px;
			}
			@media (max-width: ($md5 + px)) {
				padding: 20px;
			}
		}
		.capture-form__title {
			font-size: 26px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(30 / 26 * 100%);
			@media (max-width: ($md4 + px)) {
				br {
					display: none;
				}
			}
			@media (max-width: ($md5 + px)) {
				font-size: 24px;
			}
			@media (max-width: ($md6 + px)) {
				font-size: 22px;
			}
		}
		.capture-form__subtitle {
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(26 / 16 * 100%);
			margin-top: 9px;
		}

		.capture-form__field {
			margin-top: 21px;
		}

		.capture-form__label {
			opacity: 0.8;
			color: #070809;
			font-size: 12px;
			font-weight: 700;
			text-align: left;
			text-transform: uppercase;
			letter-spacing: 0.24px;
		}
		.capture-form__button {
			color: #ffffff;
			font-size: 14px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(22 / 14 * 100%);
			text-align: center;
			height: 86px;
			width: 273px;
			@media (max-width: ($md3 + px)) {
				width: 100%;
			}
		}
		.capture-form__phone {
			height: 80px;
			padding-left: 28px;
		}
		.capture-form__policy {
			margin-top: 20px;
		}
		.policy__label {
			line-height: 19px;
			padding-left: 25px;

			&::before,
			&::after {
				margin: unset;
			}
			&::before {
				left: 0;
			}
			&::after {
				top: 5px;
				left: 3.5px;
			}
		}
	}
	&__wrapper {
		display: contents;

		@media (max-width: ($md4 + px)) {
			display: flex;
			gap: 10px;
			overflow: scroll;
			padding-bottom: 15px;
		}
	}
	// .section-15__phone

	&__phone {
		margin-left: -52px;
		@media (max-width: ($md4 + px)) {
			display: none;
		}
	}
}

.cooperator {
	// .cooperator__photo

	&__photo {
		background-position: top center;
		background-size: cover;
		background-repeat: no-repeat;
		padding-bottom: 137.46%;
		position: relative;
		border-radius: 12px;
	}

	// .cooperator__experience

	&__experience {
		position: absolute;
		border-radius: 4px;
		background-color: #027ef2;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 125px;
		height: 34px;
		font-size: 16px;
		font-weight: 500;
		line-height: calc(24 / 16 * 100%);
		text-align: center;
		bottom: 0;
		left: 0;
		margin: 0 0 25px 25px;
		color: #fff;
	}

	// .cooperator__description

	&__description {
		margin-top: 25px;
		@media (max-width: ($md3 + px)) {
			margin-top: 15px;
		}
	}

	// .cooperator__name

	&__name {
		color: #060708;
		font-size: 22px;
		font-weight: 700;
		line-height: calc(30 / 22 * 100%);
		text-align: center;
		@media (max-width: ($md3 + px)) {
			font-size: 20px;
		}
	}

	// .cooperator__post

	&__post {
		margin-top: 5px;
		opacity: 0.6;
		color: #060708;
		font-size: 16px;
		font-weight: 400;
		line-height: calc(24 / 16 * 100%);
		text-align: center;
	}
}

.section-16 {
	margin-top: 110px;

	@media (max-width: ($md3 + px)) {
		margin-top: 40px;
	}
	// .section-16__container

	&__container {
	}

	// .section-16__title

	&__title {
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-16__subtitle

	&__subtitle {
		margin-top: 31px;

		@media (max-width: ($md3 + px)) {
			margin-top: 15px;
		}
	}

	// .section-16__time

	&__time {
		vertical-align: middle;
		margin-top: -4px;
		margin-right: 7px;

		@media (max-width: ($md3 + px)) {
			max-width: 30px;
		}

		@media (max-width: ($md4 + px)) {
			max-width: 25px;
		}
		@media (max-width: ($md5 + px)) {
			max-width: 20px;
			margin-right: 2px;
		}
	}

	// .section-16__items

	&__items {
		display: flex;
		justify-content: center;
		gap: 80px;
		margin-top: 48px;
		margin-bottom: 50px;
		flex-wrap: wrap;
		@media (max-width: ($md1 + px)) {
			gap: 20px 80px;
		}
		@media (max-width: ($md2 + px)) {
			gap: 20px 50px;
		}
		@media (max-width: ($md3 + px)) {
			display: grid;
			grid-template-columns: auto;
			margin-top: 20px;
			margin-bottom: 30px;
		}

		@media (max-width: ($md5 + px)) {
			gap: 10px;
		}
	}

	// .section-16__item

	&__item {
		display: flex;
		gap: 15px;

		@media (max-width: ($md2 + px)) {
			gap: 10px;
		}

		&:nth-of-type(1) {
			.section-16__item-icon::before {
				font-size: 15px;
				color: #fff;
			}
		}
		&:nth-of-type(2) {
			.section-16__item-icon::before {
				font-size: 13px;
				color: #fff;
			}
		}
		&:nth-of-type(3) {
			.section-16__item-icon::before {
				font-size: 10px;
				color: #fff;
			}
		}
	}

	// .section-16__item-icon

	&__item-icon {
		width: 32px;
		height: 32px;
		background-color: #027ef2;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		@media (max-width: ($md5 + px)) {
			flex-shrink: 0;
		}
	}

	// .section-16__item-description

	&__item-description {
		display: flex;
		flex-direction: column;
		gap: 13px;
		margin-top: 6px;

		@media (max-width: ($md2 + px)) {
			gap: 6px;
		}
	}

	// .section-16__item-main

	&__item-main {
		opacity: 0.8;
		color: #060708;
		font-size: 14px;
		font-weight: 800;
		line-height: calc(22 / 14 * 100%);
		text-align: left;
		text-transform: uppercase;

		@media (max-width: ($md4 + px)) {
			font-size: 13px;
		}
	}

	// .section-16__item-address

	&__item-address {
		font-size: 16px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(24 / 16 * 100%);

		@media (max-width: ($md5 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-16__phone

	&__phone {
		font-size: 22px;
		font-weight: 700;
		@media (max-width: ($md4 + px)) {
			font-size: 20px;
		}
	}

	// .section-16__email

	&__email {
		color: #027ef2;
		font-size: 20px;
		font-weight: 600;
		text-decoration: underline;
		text-underline-offset: 5px;
		text-decoration-color: rgba($color: #027ef2, $alpha: 0.3);
	}

	// .section-16__map
	&__buttons {
		margin-top: -12px;
		display: flex;
		gap: 20px;
		margin-bottom: 53px;

		justify-content: center;

		@media (max-width: ($md3 + px)) {
			margin-bottom: 30px;
			gap: 10px;
			margin-top: 0;
		}

		@media (max-width: ($md5 + px)) {
			flex-direction: column;
		}
	}

	&__button {
		width: 280px;
		height: 90px;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 15px;
		color: #fff;
		font-size: 16px;
		font-weight: 700;

		@media (max-width: ($md5 + px)) {
			width: 100%;
			height: 80px;
		}
	}

	&__whatsapp-btn {
		box-shadow: 0 25px 50px rgba(18, 117, 55, 0.15), inset 0 0 18px 2px rgba(255, 255, 255, 0.15);
		background-image: linear-gradient(to top, #1cb052 0%, #21ca60 100%);
	}

	&__telegram-btn {
		box-shadow: 0 25px 50px rgba(13, 103, 138, 0.15), inset 0 0 18px 2px rgba(255, 255, 255, 0.15);
		background-image: linear-gradient(to top, #0094cf 0%, #00a6e7 100%);
	}
	&__map {
		max-width: 1920px;
		margin: auto;
		margin-top: 27px;
		position: relative;

		@media (max-width: ($md3 + px)) {
			margin-top: 15px;
		}
	}

	#map {
		height: 800px;
		width: 100%;

		@media (max-width: ($md2 + px)) {
			height: 500px;
		}

		@media (max-width: ($md4 + px)) {
			height: 400px;
		}
	}
	// .section-16__formblock

	&__formblock {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 2;
		box-shadow: 0 20px 50px rgba(33, 34, 58, 0.05);
		border-radius: 10px;
		background-color: #ffffff;
		padding: 53px 50px 63px 50px;
		margin-right: 18.5%;
		margin-top: 104px;

		@media (max-width: ($md2 + px)) {
			position: relative;
			max-width: 490px;
			margin: auto;
			margin-top: 30px;
		}
		@media (max-width: ($md3 + px)) {
			padding: 45px;
			padding-top: 30px;
		}

		@media (max-width: ($md4 + px)) {
			padding: 25px;
			max-width: 420px;
		}

		@media (max-width: ($md5 + px)) {
			margin-top: 15px;
		}

		@media (max-width: ($md6 + px)) {
			padding: 15px;
		}
	}

	// .section-16__form-title
	&__form-absolute {
		position: absolute;

		top: -29px;
		right: -25px;

		@media (max-width: ($md3 + px)) {
			max-width: 100px;
			right: 0px;
		}

		@media (max-width: ($md5 + px)) {
			display: none;
		}
	}
	&__form-title {
		font-size: 30px;
		font-weight: 700;
		line-height: calc(42 / 30 * 100%);

		@media (max-width: ($md3 + px)) {
			font-size: 26px;
		}
	}

	// .section-16__form-subtitle

	&__form-subtitle {
		font-size: 22px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		margin-top: 6px;
		line-height: calc(32 / 22 * 100%);
		b {
			font-weight: 700;
		}

		@media (max-width: ($md3 + px)) {
			font-size: 20px;
		}
	}

	// .section-16__form-how

	&__form-label {
		opacity: 0.8;
		color: #070809;
		font-size: 14px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 0.28px;
		margin-top: 30px;

		@media (max-width: ($md3 + px)) {
			margin-top: 20px;
			font-size: 13px;
		}

		@media (max-width: ($md5 + px)) {
			font-size: 11px;
		}

		@media (max-width: ($md6 + px)) {
			line-height: 1.4;
		}
	}

	// .section-16__form

	&__form-socials {
		display: flex;
		margin-top: 18px;
		justify-content: space-between;
		gap: 5px;
	}

	// .section-16__form-tel
	&__form {
		margin-top: 20px;
	}
	&__form-tel {
		width: 390px;
		height: 90px;
		@media (max-width: ($md3 + px)) {
			width: 100%;
		}
	}

	// .section-16__form-btn

	&__form-btn {
		width: 390px;
		height: 94px;
		margin-top: 10px;
		@media (max-width: ($md3 + px)) {
			width: 100%;
		}
	}

	// .section-16__policy

	&__policy {
		margin-top: 19px;
		.policy__label {
			padding-left: 35px;
			&::before {
				left: 11px;
			}
			&::after {
				left: 14px;
			}
		}

		@media (max-width: ($md4 + px)) {
			margin-top: 12px;
			line-height: 1.4;
			.policy__label {
				&::before {
					margin: unset;
				}
				&::after {
					margin: unset;
					top: 5px;
				}
			}
		}
	}
}
.socs {
	&__soc {
		display: flex;
		gap: 7px;
		width: 124px;
		height: 54px;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		border: 1px solid rgba($color: #000000, $alpha: 0.1);
		@media (max-width: ($md6 + px)) {
			gap: 3px;
		}
	}

	&__text {
		font-size: 12px;
		font-weight: 600;
		text-align: center;
		letter-spacing: 0.24px;
	}

	&__icon svg {
		@media (max-width: ($md6 + px)) {
			max-width: 15px;
		}
	}
}
.invisible {
	display: none;
}

.section-17 {
	padding-top: 313px;
	background-size: cover;
	background-position: top center;
	padding-bottom: 131px;

	& + .section-7 {
		background-color: #fff;
		padding-top: 0 !important;
		margin-top: 110px;
		@media (max-width: ($md3 + px)) {
			margin-top: 50px;
		}
	}

	@media (max-width: ($md3 + px)) {
		padding-bottom: 70px;
	}
	// .section-17__container

	&__container {
	}

	// .section-17__title

	&__title {
		color: #fff;
		text-align: center;
	}

	// .section-17__button
	&__buttons {
		display: flex;
		justify-content: center;
		gap: 20px;
		margin-top: 40px;
		@media (max-width: ($md3 + px)) {
			margin-top: 30px;
			gap: 15px;
		}

		@media (max-width: ($md4 + px)) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__showroom {
		box-shadow: 0 25px 50px rgba(26, 28, 42, 0.05), inset 0 -3px 5px rgba(255, 255, 255, 0.05),
			inset 0 0 10px rgba(255, 255, 255, 0.1);
		text-shadow: 0 1px 0 rgba(255, 255, 255, 0.9);
		color: #464d57;
		padding: 0 10px;
		background-image: linear-gradient(to top, #cfdef3 0%, #e0eafc 100%);
		@media (max-width: ($md5 + px)) {
			max-height: 84px;
			width: 100% !important;
		}
	}
	&__button {
		width: 360px;
		height: 100px;
		font-size: 16px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: 24px;
		text-align: center;
		gap: 14px;
		border-radius: 10px;
	}
}

.section-18 {
	margin-top: 111px;

	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	// .section-18__container

	&__container {
	}

	// .section-18__title

	&__title {
	}

	&__inner {
		border-radius: 16px;
		background-color: #f7f8fa;
		max-width: 1280px;
		margin: auto;
		margin-top: 50px;
		@media (max-width: ($md3 + px)) {
			margin-top: 20px;
		}
	}
	// .section-18__items

	&__items {
		display: grid;
		grid-template-columns: repeat(3, auto);

		padding-left: 49px;
		padding-top: 69px;
		padding-right: 52px;
		padding-bottom: 63px;
		justify-content: space-between;

		@media (max-width: ($md1 + px)) {
			padding: 69px 0 63px 0;
		}

		@media (max-width: ($md2 + px)) {
			grid-template-columns: auto;
			justify-content: center;
			gap: 30px;
		}

		@media (max-width: ($md3 + px)) {
			padding: 20px 0 30px 0;
			gap: 20px;
		}

		@media (max-width: ($md5 + px)) {
			gap: 15px;
		}
	}

	// .section-18__item

	&__item {
		@media (max-width: ($md2 + px)) {
			display: flex;
			gap: 35px;
		}

		@media (max-width: ($md3 + px)) {
			gap: 20px;
		}

		@media (max-width: ($md6 + px)) {
			gap: 10px;
		}
	}

	// .section-18__icon

	&__icon {
		&::before {
			font-size: 51px;
			color: #027ef2;

			@media (max-width: ($md3 + px)) {
				font-size: 40px;
			}

			@media (max-width: ($md6 + px)) {
				font-size: 30px;
			}
		}
	}

	// .section-18__description

	&__description {
		margin-top: 23px;
		@media (max-width: ($md2 + px)) {
			margin-top: 0;
		}
	}

	// .section-18__text

	&__text {
		font-size: 24px;
		line-height: calc(34 / 24 * 100%);
		b {
			font-weight: 700;
		}

		@media (max-width: ($md3 + px)) {
			font-size: 22px;
		}

		@media (max-width: ($md4 + px)) {
			font-size: 20px;
		}

		@media (max-width: ($md6 + px)) {
			font-size: 18px;
		}
	}

	// .section-18__list

	&__list {
		display: flex;
		justify-content: space-between;
		margin-top: 13px;
		gap: 29px;

		@media (max-width: ($md3 + px)) {
			margin-top: 7px;
		}

		@media (max-width: ($md5 + px)) {
			flex-direction: column;
			gap: 2px;
		}
	}

	// .section-18__list-item

	&__list-item {
		font-size: 18px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(28 / 18 * 100%);
		padding-left: 18px;

		&::before {
			top: 11px;
		}
	}

	& + .section-10 {
		overflow: hidden;
	}
}

.section-19 {
	margin-top: 110px;
	@media (max-width: ($md3 + px)) {
		margin-top: 40px;
		.visible-hand {
			display: flex;
		}
	}
	// .section-19__container

	&__container {
	}

	// .section-19__title

	&__title {
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
		@media (max-width: ($md5 + px)) {
			font-size: 24px;
		}
	}

	// .section-19__subtitle

	&__subtitle {
		margin-top: 25px;

		@media (max-width: ($md3 + px)) {
			margin-top: 15px;
		}
	}

	// .section-19__items

	&__items {
		margin-top: 57px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 30px;

		@media (max-width: ($md1 + px)) {
			gap: 17px;
		}

		@media (max-width: ($md2 + px)) {
			gap: 10px;
		}
		@media (max-width: ($md3 + px)) {
			display: flex;
			margin-top: 0px;
			overflow: scroll;
			justify-content: flex-start;
		}
	}

	// .section-19__item

	&__item {
		position: relative;
		@media (max-width: ($md3 + px)) {
			flex: 0 0 300px;

			&:nth-of-type(1) {
				order: 2;
			}
		}
	}

	&__longitem {
		grid-column: span 2;

		.section-19__inner {
			bottom: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-top: 0;
			margin-top: -20px;
			padding-left: 100px;
		}
		@media (max-width: ($md1 + px)) {
			.section-19__item-title br {
				display: block;
			}
			.section-19__inner {
				padding-left: 70px;
			}
		}
		@media (max-width: ($md2 + px)) {
			.section-19__inner {
				padding-left: 30px;
			}
		}

		@media (max-width: ($md3 + px)) {
			.section-19__inner {
				padding-left: 0px;
				margin-top: 0;
				padding-top: 15px;
			}
			.section-19__item-title br {
				display: none;
			}
		}
	}

	&__ellipse {
		border-radius: 26px;
		background-color: rgba($color: #ffffff, $alpha: 0.2);
		padding: 5px 20px 7px 20px;

		@media (max-width: ($md3 + px)) {
			padding: 0;
		}
	}

	// .section-19__back

	&__back {
		@media (max-width: ($md2 + px)) {
			filter: brightness(0.8);
		}
		@media (max-width: ($md3 + px)) {
			filter: none;
			border-radius: 14px;
		}
	}

	// .section-19__inner

	&__inner {
		position: absolute;
		top: 0;
		left: 0;
		padding-top: 55px;
		padding-left: 50px;
		color: #fff;

		@media (max-width: ($md1 + px)) {
			padding-top: 30px;
			padding-left: 30px;
			padding-right: 30px;
		}
		@media (max-width: ($md2 + px)) {
			padding-top: 25px;
		}
		@media (max-width: ($md3 + px)) {
			position: static;
			color: #060708;
			padding-left: 0;
			padding-top: 15px;
		}
	}

	// .section-19__item-title

	&__item-title {
		font-size: 28px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(38 / 28 * 100%);
		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
			font-size: 26px;
		}
		@media (max-width: ($md3 + px)) {
			font-size: 22px;
		}
		@media (max-width: ($md4 + px)) {
			font-size: 20px;
		}
	}

	// .section-19__item-text

	&__item-text {
		font-size: 20px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(30 / 20 * 100%);
		margin-top: 11px;
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
			font-size: 18px;
			margin-top: 7px;
		}

		@media (max-width: ($md5 + px)) {
			font-size: 16px;
		}
	}
}

.section-20 {
	margin-top: 113px;

	@media (max-width: ($md2 + px)) {
		.visible-hand {
			display: flex;
		}
	}

	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	// .section-20__container

	&__container {
	}

	// .section-20__title

	&__title {
		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-20__subtitle

	&__subtitle {
		margin-top: 23px;

		@media (max-width: ($md3 + px)) {
			margin-top: 15px;
			br {
				display: none;
			}
		}
	}

	// .section-20__items

	&__items {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;
		margin-top: 50px;

		@media (max-width: ($md1 + px)) {
			gap: 15px;
		}

		@media (max-width: ($md2 + px)) {
			display: flex;
			justify-content: flex-start;
			overflow: scroll;
			margin-top: 0;
		}
		@media (max-width: ($md3 + px)) {
			gap: 10px;
		}
	}

	// .section-20__item

	&__item {
		position: relative;
		border-radius: 14px;
		overflow: hidden;
		@media (max-width: ($md2 + px)) {
			flex: 0 0 300px;
			height: 400px;
		}
	}

	// .section-20__item-back

	&__item-back {
		@media (max-width: ($md2 + px)) {
			height: 100%;
			filter: brightness(0.8);
		}
	}

	// .section-20__inner

	&__inner {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		padding-top: 45px;
		padding-left: 50px;
		color: #fff;

		@media (max-width: ($md1 + px)) {
			padding-left: 30px;
			padding-top: 20px;
		}

		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
			padding-right: 15px;
			padding-left: 20px;
		}
	}

	// .section-20__item-title

	&__item-title {
		font-size: 24px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(32 / 24 * 100%);
		@media (max-width: ($md2 + px)) {
			font-size: 21px;
		}
	}

	// .section-20__item-text

	&__item-text {
		margin-top: 18px;
		@media (max-width: ($md2 + px)) {
			font-size: 16px;
		}
	}
}

.section-21 {
	border-radius: 10px;
	background-color: #f7f8fa;
	max-width: 1340px;
	margin: auto;
	margin-top: 60px;
	display: flex;
	justify-content: space-between;

	@media (max-width: ($md3 + px)) {
		margin-top: 30px;
	}

	// .section-21__picture

	&__picture {
		img {
			height: 100%;
			border-radius: 10px;
		}

		@media (max-width: ($md2 + px)) {
			display: none;
		}
	}

	// .section-21__container

	&__container {
	}

	// .section-21__formblock

	&__formblock {
		padding-top: 57px;
		padding-bottom: 47px;
		padding-left: 30px;
		@media (max-width: ($md2 + px)) {
			padding-left: 0;
			width: 100%;
		}

		@media (max-width: ($md3 + px)) {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}

	// .section-21__formblock-title

	&__formblock-title {
		font-size: 30px;
		font-weight: 400;
		line-height: calc(42 / 30 * 100%);

		b {
			font-weight: 700;
		}

		@media (max-width: ($md3 + px)) {
			br {
				display: none;
			}
			text-align: center;
		}

		@media (max-width: ($md4 + px)) {
			font-size: 25px;
		}
		@media (max-width: ($md5 + px)) {
			font-size: 23px;
		}
	}

	// .section-21__addresses

	&__addresses {
		display: flex;
		gap: 71px;
		margin-top: 14px;
		align-items: center;
		@media (max-width: ($md3 + px)) {
			justify-content: center;
			gap: 50px;
		}
		@media (max-width: ($md4 + px)) {
			flex-direction: column;
			gap: 20px;
			margin-top: 30px;
		}
	}

	// .section-21__address

	&__address {
		display: flex;
		gap: 11px;
	}

	// .section-21__icon

	&__icon {
		&::before {
			font-size: 17px;
		}
	}

	// .section-21__address-text

	&__address-text {
		font-size: 14px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(24 / 14 * 100%);
		text-decoration: underline;
		text-decoration-color: rgba($color: #070809, $alpha: 0.1);
		text-underline-offset: 7px;
		margin-top: -5px;
	}

	// .section-21__map

	&__map {
		display: flex;
		align-items: center;
		gap: 16px;
	}

	// .section-21__map-pic

	&__map-pic {
		border-radius: 50%;
		border: 4px solid #fff;

		@media (max-width: ($md4 + px)) {
			max-width: 50px;
		}
	}

	// .section-21__map-text

	&__map-text {
		color: #027ef2;
		font-size: 16px;
		font-weight: 600;
		text-decoration: underline;
		text-decoration-color: rgba($color: #027ef2, $alpha: 0.3);
		text-underline-offset: 7px;
		margin-top: -5px;
	}

	// .section-21__form

	&__form {
		margin-top: 32px;
		@media (max-width: ($md4 + px)) {
			margin-top: 20px;
		}
	}

	// .section-21__form-tel

	&__form-tel {
		width: 340px;
		height: 94px;
		background-color: #ffffff;

		@media (max-width: ($md3 + px)) {
			width: 350px;
		}
	}

	&__form-inner {
		display: flex;
		gap: 10px;

		@media (max-width: ($md3 + px)) {
			flex-direction: column;
			align-items: center;
		}
	}
	// .section-21__form-btn

	&__form-btn {
		width: 350px;
		height: 94px;
	}

	// .section-21__policy

	&__policy {
		margin-left: 147px;

		@media (max-width: ($md3 + px)) {
			margin-left: 0;
			display: flex;
			justify-content: center;
		}

		@media (max-width: ($md4 + px)) {
			margin-top: 20px;
		}
	}
}

.section-22 {
	margin-top: 112px;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	@media (max-width: 645px) {
		.visible-hand {
			display: flex;
		}
	}

	// .section-22__container

	&__container {
	}

	// .section-22__title

	&__title {
		@media (max-width: ($md3 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-22__items

	&__items {
		margin-top: 60px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 30px;

		@media (max-width: ($md1 + px)) {
			gap: 20px;
		}

		@media (max-width: ($md2 + px)) {
			gap: 15px;
		}

		@media (max-width: ($md3 + px)) {
			overflow: auto;
			gap: 15px;
		}

		@media (max-width: 645px) {
			margin-top: 0;
		}
	}

	// .section-22__item

	&__item {
		position: relative;
		border-radius: 14px;
		overflow: hidden;
		min-width: 300px;
		background-color: #fff;

		@media (max-width: ($md2 + px)) {
			height: 420px;
		}
	}

	// .section-22__item-back

	&__item-back {
		@media (max-width: ($md2 + px)) {
			height: 100%;
			filter: opacity(0.8);
		}
	}

	// .section-22__item-inner

	&__item-inner {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		padding-top: 53px;
		padding-left: 70px;

		@media (max-width: ($md1 + px)) {
			padding-top: 30px;
			padding-left: 40px;
		}

		@media (max-width: ($md2 + px)) {
			padding-right: 30px;
			padding-left: 30px;
		}
		@media (max-width: ($md3 + px)) {
			padding-right: 25px;
			padding-left: 25px;
		}
	}

	// .section-22__item-title

	&__item-title {
		@media (max-width: ($md3 + px)) {
			font-size: 24px;
		}
		@media (max-width: ($md4 + px)) {
			font-size: 22px;
		}
	}

	// .section-22__item-text

	&__item-text {
		margin-top: 10px;
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
			font-size: 17px;
		}
	}
}

.section-23 {
	margin-top: -74px;
	padding: 194px 0 120px 0;
	background-size: cover;
	background-position: top center;
	// .section-23__container

	@media (max-width: ($md3 + px)) {
		padding: 120px 0 30px 0;
		background-position: left;
	}

	&__container {
	}

	// .section-23__form

	&__form {
		margin-right: auto;
		width: 510px;
		@media (max-width: ($md3 + px)) {
			display: flex;
			justify-content: center;
			width: auto;
		}
	}

	.capture-form__cube {
		top: -46px;
		right: -75px;
		@media (max-width: ($md3 + px)) {
			right: -95px;
		}

		@media (max-width: ($md4 + px)) {
			right: -60px;
			top: -40px;
		}
		@media (max-width: ($md5 + px)) {
			right: -40px;
			top: -40px;
		}
	}

	.capture-form__subtitle,
	.capture-form__button {
		@media (max-width: ($md6 + px)) {
			br {
				display: none;
			}
		}
	}

	.capture-form__subtitle {
		@media (max-width: ($md6 + px)) {
			font-size: 17px;
		}
	}
}

.section-24 {
	margin-top: 80px;

	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	// .section-24__container

	&__container {
	}
	&__nav-wrapper {
		max-width: fit-content;
		margin: auto;
		padding: 10px;
		border-radius: 20px;
		border: 1px solid rgba($color: #060708, $alpha: 0.05);
	}
	// .section-24__nav

	&__nav {
		box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		background-color: #ffffff;

		@media (max-width: ($md3 + px)) {
			flex-direction: column;
		}
	}

	// .section-24__nav-item

	&__nav-item {
		border-radius: 10px;
		display: flex;
		align-items: center;
		&:not(:last-of-type)::after {
			content: "";
			width: 1px;

			height: 30px;

			background-color: rgba(0, 0, 0, 0.1);
			@media (max-width: ($md3 + px)) {
				height: 1px;
				width: 80%;
			}
		}
		@media (max-width: ($md3 + px)) {
			flex-direction: column;
		}
	}

	// .section-24__nav-link

	&__nav-link {
		color: rgba($color: #060708, $alpha: 0.6);
		font-size: 16px;
		font-weight: 600;
		border-radius: 10px;
		padding: 22px 30px;

		@media (max-width: ($md3 + px)) {
			width: 100%;
		}

		&.active {
			box-shadow: 0 15px 30px rgba(1, 108, 208, 0.15), inset 0 0 18px 2px rgba(255, 255, 255, 0.15);
			border-radius: 10px;
			font-weight: 700;
			background-image: linear-gradient(to top, #016cd0 0%, #027aeb 100%);
		}
	}

	// .section-24__tabs-content

	&__tabs-content {
	}

	// .section-24__measure

	&__measure {
	}

	// .section-24__title

	&__title {
		margin: 50px 0 60px 0;

		@media (max-width: ($md3 + px)) {
			margin: 30px 0 20px 0;
		}
	}

	// .section-24__time

	&__time {
	}

	// .section-24__install

	&__install {
	}

	// .section-24__service

	&__service {
	}
	.measure {
		.visible-hand {
			@media (max-width: ($md3 + px)) {
				display: flex;
			}
		}
		// .measure__items

		&__items {
			display: flex;
			flex-direction: column;
			gap: 30px;

			@media (max-width: ($md3 + px)) {
				gap: 10px;
				flex-direction: row;
				overflow: scroll;
			}
		}

		// .measure__item

		&__item {
			position: relative;
			@media (max-width: ($md3 + px)) {
				flex: 0 0 300px;
			}
		}

		&__inner {
			position: absolute;
			z-index: 1;
			display: flex;
			justify-content: center;
			flex-direction: column;
			left: 0;
			bottom: 0;
			top: 0;
			padding-left: 90px;

			@media (max-width: ($md1 + px)) {
				padding-left: 70px;
			}

			@media (max-width: ($md3 + px)) {
				position: static;
				padding-left: 0;
				padding-top: 15px;
				br {
					display: none;
				}
			}
		}
		// .measure__item-back

		&__item-back {
			@media (max-width: ($md2 + px)) {
				filter: opacity(0.7);
			}
			@media (max-width: ($md3 + px)) {
				filter: none;
				border-radius: 14px;
			}
		}

		// .measure__title

		&__title {
			@media (max-width: ($md1 + px)) {
				font-size: 28px;
			}
			@media (max-width: ($md2 + px)) {
				font-size: 26px;
			}

			@media (max-width: ($md3 + px)) {
				font-size: 20px;
			}
		}

		// .measure__text

		&__text {
			font-size: 20px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(30 / 20 * 100%);
			margin-top: 15px;

			@media (max-width: ($md3 + px)) {
				font-size: 18px;
			}
		}

		// .measure__glasses

		&__glasses {
			display: grid;
			grid-template-columns: repeat(4, auto);
			gap: 10px;
			margin-top: 22px;
			justify-content: start;
			@media (max-width: ($md2 + px)) {
				grid-template-columns: repeat(8, auto);
			}

			@media (max-width: ($md3 + px)) {
				grid-template-columns: repeat(4, auto);
			}
		}

		// .measure__list

		&__list {
			margin-top: 11px;
			padding-left: 39px;
			display: flex;
			flex-direction: column;
			gap: 9px;

			@media (max-width: ($md3 + px)) {
				padding-left: 0;
			}
		}

		// .measure__list-item

		&__list-item {
			display: flex;
			gap: 13px;
			align-items: center;
			@media (max-width: ($md2 + px)) {
				margin-top: 7px;
			}
		}
	}
	.time {
		// .time__cards
		.visible-hand {
			@media (max-width: ($md3 + px)) {
				display: flex;
			}
		}
		&__cards {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 16px;

			@media (max-width: ($md1 + px)) {
				gap: 10px;
			}

			@media (max-width: ($md3 + px)) {
				grid-template-columns: repeat(6, 300px);
				overflow: scroll;
			}
		}

		// .time__card

		&__card {
			position: relative;
			border-radius: 14px;
			overflow: hidden;

			@media (max-width: ($md2 + px)) {
				height: 300px;
			}
		}

		&__back {
			@media (max-width: ($md2 + px)) {
				height: 100%;
				filter: brightness(0.7);
			}
		}

		// .time__inner

		&__inner {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 30px;
			@media (max-width: ($md1 + px)) {
				padding: 20px;
			}

			@media (max-width: ($md3 + px)) {
				br {
					display: none;
				}

				padding: 15px;
			}
		}

		// .time__wrapper

		&__wrapper {
		}

		// .time__title

		&__title {
			border-radius: 28px;
			background-color: #fff;
			padding: 0 30px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;
			min-height: 56px;
			font-size: 20px;
			font-weight: 700;
			line-height: 130%;
			margin-bottom: 24px;
			@media (max-width: ($md1 + px)) {
				margin-bottom: 20px;
				min-height: 50px;
			}
			@media (max-width: ($md2 + px)) {
				padding: 0 13px;
			}

			@media (max-width: ($md3 + px)) {
				font-size: 18px;
				margin-bottom: 15px;
			}
		}

		// .time__text

		&__text {
			color: #ffffff;
			font-size: 18px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(30 / 18 * 100%);
			padding-left: 30px;

			@media (max-width: ($md2 + px)) {
				padding-left: 20px;
			}

			@media (max-width: ($md3 + px)) {
				font-size: 17px;
				padding-left: 10px;
			}
		}

		// .time__button

		&__button {
			max-width: 330px;
			height: 70px;
		}

		// .time__list

		&__list {
			display: grid;
			grid-template-columns: repeat(2, auto);
			row-gap: 10px;
		}

		// .time__list-item

		&__list-item {
			line-height: 1.75;
			&:nth-of-type(2) {
				margin-left: -180px;
				@media (max-width: ($md1 + px)) {
					margin-left: 0;
				}
			}
			&:nth-of-type(3) {
				grid-column: span 2;
			}

			@media (max-width: ($md1 + px)) {
				line-height: 1.2;
			}

			@media (max-width: ($md3 + px)) {
				font-size: 18px;

				br {
					display: block;
				}
			}
		}
	}
	.install {
		.visible-hand {
			@media (max-width: ($md3 + px)) {
				display: flex;
			}
		}
		// .install__cards

		&__cards {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 30px;

			@media (max-width: ($md1 + px)) {
				gap: 20px;
			}

			@media (max-width: ($md2 + px)) {
				gap: 15px;
			}
			@media (max-width: ($md3 + px)) {
				grid-template-columns: repeat(3, 300px);
				overflow: scroll;
				gap: 10px;
				padding-bottom: 10px;
			}
		}

		// .install__card

		&__card {
			position: relative;
			border-radius: 14px;
			overflow: hidden;
		}

		// .install__longcard

		&__longcard {
			grid-column: span 2;

			@media (max-width: ($md2 + px)) {
				height: 530px;
			}

			@media (max-width: ($md3 + px)) {
				grid-column: unset;
				height: auto;
			}

			.install__cardback {
				@media (max-width: ($md2 + px)) {
					height: 100%;
					object-position: 35%;
				}

				@media (max-width: ($md3 + px)) {
					height: auto;
					border-radius: 14px;
					object-position: center;
				}
			}

			.install__inner {
				position: absolute;
				width: 100%;
				top: 0;
				bottom: 0;
				margin: auto;
				left: 0;
				padding-left: 91px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-top: 0;

				@media (max-width: ($md1 + px)) {
					padding-left: 70px;
				}

				@media (max-width: ($md2 + px)) {
					justify-content: flex-start;
					padding-left: 40px;
					padding-top: 40px;
				}

				@media (max-width: ($md3 + px)) {
					padding-left: 0;
					position: static;
					padding-top: 15px;
				}
			}
		}

		// .install__cardback

		&__cardback {
			filter: opacity(0.8);
			@media (max-width: ($md3 + px)) {
				filter: none;
				object-position: center;
			}
		}

		// .install__inner

		&__inner {
			position: absolute;
			top: 0;
			left: 0;
			padding-left: 70px;
			padding-top: 50px;

			@media (max-width: ($md1 + px)) {
				padding-top: 40px;
			}

			@media (max-width: ($md2 + px)) {
				padding-left: 30px;
				padding-top: 20px;
			}
			@media (max-width: ($md3 + px)) {
				padding-left: 0;
				position: static;
				padding-top: 15px;
				br {
					display: none;
				}
			}
		}

		// .install__title

		&__title {
			@media (max-width: ($md1 + px)) {
				font-size: 28px;
			}
			@media (max-width: ($md2 + px)) {
				font-size: 26px;
			}

			@media (max-width: ($md3 + px)) {
				font-size: 21px;
			}
		}

		// .install__list

		&__list {
			margin-top: 18px;
			display: flex;
			flex-direction: column;
			gap: 10px;

			@media (max-width: ($md3 + px)) {
				gap: 5px;
				margin-top: 10px;
			}
		}

		// .install__list-item

		&__list-item {
		}

		// .install__absolute

		&__absolute {
			position: absolute;
			right: 71px;
			bottom: 55px;

			@media (max-width: ($md1 + px)) {
				bottom: 30px;
				right: 50px;
			}

			@media (max-width: ($md2 + px)) {
				position: static;
			}
		}

		// .install__pics

		&__pics {
			display: flex;
			flex-direction: column;
			padding-left: 80px;
			@media (max-width: ($md2 + px)) {
				padding-left: 0;
				flex-direction: row;
				margin-top: 15px;
			}

			@media (max-width: ($md3 + px)) {
				margin-top: 10px;
			}
		}

		&__pic2 {
			margin-top: -10px;

			@media (max-width: ($md1 + px)) {
				margin-top: -20px;
				margin-bottom: -20px;
			}

			@media (max-width: ($md2 + px)) {
				margin-top: 0;
				margin-bottom: 0;
			}
		}
		// .install__videoblock

		&__videoblock {
			display: flex;

			gap: 17px;
			align-items: center;
		}

		// .install__videoplay

		&__videoplay {
			width: 78px;
			height: 78px;
			border-radius: 50%;
			img {
				padding-left: 5px;
			}

			@media (max-width: ($md3 + px)) {
				width: 50px !important;
				height: 50px;
				flex-shrink: 0;
			}
		}

		// .install__videotext

		&__videotext {
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			text-align: left;
			letter-spacing: normal;
			line-height: 162.5%;
			text-decoration: underline;
			text-underline-offset: 5px;
			text-decoration-thickness: 1px;
			-webkit-text-decoration-color: rgba(0, 0, 0, 0.1);
			text-decoration-color: rgba(0, 0, 0, 0.1);
		}

		// .install__text

		&__text {
			padding-top: 10px;
		}
	}
	.service {
		.visible-hand {
			@media (max-width: 640px) {
				display: flex;
			}
		}
		// .service__cards

		&__cards {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 30px;

			@media (max-width: ($md1 + px)) {
				gap: 20px;
			}
			@media (max-width: ($md2 + px)) {
				gap: 10px;
			}
			@media (max-width: ($md3 + px)) {
				overflow: auto;
				padding-bottom: 10px;
			}
		}

		// .service__card

		&__card {
			position: relative;

			@media (max-width: ($md3 + px)) {
				min-width: 300px;
			}
		}

		// .service__cardback

		&__cardback {
			@media (max-width: ($md3 + px)) {
				border-radius: 14px;
			}
		}

		// .service__inner

		&__inner {
			position: absolute;
			top: 0;
			left: 0;
			padding-left: 70px;
			padding-top: 53px;

			@media (max-width: ($md1 + px)) {
				padding-left: 40px;
				padding-top: 30px;
			}

			@media (max-width: ($md2 + px)) {
				padding-top: 20px;
				padding-left: 30px;
			}

			@media (max-width: ($md3 + px)) {
				position: static;
				padding-left: 0;
				padding-top: 15px;
				br {
					display: none;
				}
			}
		}

		// .service__title

		&__title {
			@media (max-width: ($md1 + px)) {
				font-size: 28px;
			}

			@media (max-width: ($md2 + px)) {
				font-size: 24px;
			}
			@media (max-width: ($md3 + px)) {
				font-size: 20px;
			}
		}

		// .service__text

		&__text {
			margin-top: 10px;
			@media (max-width: ($md2 + px)) {
				margin-top: 7px;
			}
		}
	}
}

.section-25 {
	margin-top: 100px;

	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	// .section-25__container

	&__container {
	}

	&__items {
		display: flex;
		flex-direction: column;
		gap: 20px;
		@media (max-width: ($md3 + px)) {
			align-items: center;
		}
	}

	// .section-25__item

	&__item {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top center;
		border-radius: 14px;
		overflow: hidden;

		@media (max-width: ($md3 + px)) {
			max-width: 550px;
		}
	}

	// .section-25__back

	&__back {
	}

	// .section-25__inner

	&__inner {
		z-index: 2;
		position: relative;
		padding-left: 100px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 42px;
		padding-top: 98px;
		padding-bottom: 100px;
		padding-right: 30px;

		@media (max-width: ($md1 + px)) {
			padding-left: 70px;
		}
		@media (max-width: ($md2 + px)) {
			padding-left: 40px;
		}
		@media (max-width: ($md3 + px)) {
			padding: 40px;
			gap: 25px;
		}

		@media (max-width: ($md5 + px)) {
			padding: 30px;
		}
	}

	&__item-black {
		position: relative;
		z-index: 1;
		@media (max-width: ($md2 + px)) {
			&::before {
				position: absolute;
				content: "";
				width: 100%;
				height: 100%;
				z-index: 0;
				background-color: rgb(255, 255, 255, 0.2);
			}
		}
		@media (max-width: ($md3 + px)) {
			&::before {
				background-color: rgb(255, 255, 255, 0.6);
			}
		}
	}
	&__item-white {
		color: #fff;
		position: relative;
		@media (max-width: ($md2 + px)) {
			&::before {
				position: absolute;
				content: "";
				width: 100%;
				height: 100%;
				z-index: 0;
				background-color: rgb(0, 0, 0, 0.2);
			}
		}
		@media (max-width: ($md3 + px)) {
			&::before {
				background-color: rgb(0, 0, 0, 0.4);
			}
		}
	}
	// .section-25__title

	&__title {
		@media (max-width: ($md3 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-25__button

	&__button {
		width: 380px;
		height: 94px;
	}

	// .section-25__title-white
}

.section-26 {
	margin-top: 120px;

	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	// .section-26__container

	&__container {
		@media (max-width: ($md4 + px)) {
			padding: 0;
		}
	}

	// .section-26__formblock

	&__formblock {
		background-image: url(../img/sec26-back.png);
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
		border-radius: 10px;
		overflow: hidden;
		z-index: 1;
		position: relative;

		@media (max-width: ($md3 + px)) {
			&::before {
				position: absolute;
				content: "";
				width: 100%;
				height: 100%;
				z-index: 0;
				background-color: rgb(0, 0, 0, 0.2);
			}
		}
	}

	&__inner {
		padding-left: 90px;
		padding-top: 56px;
		padding-bottom: 60px;
		padding-right: 50px;
		z-index: 2;
		position: relative;
		@media (max-width: ($md1 + px)) {
			padding-left: 70px;
		}
		@media (max-width: ($md2 + px)) {
			padding-left: 50px;
		}
		@media (max-width: ($md3 + px)) {
			padding: 40px;
		}

		@media (max-width: ($md4 + px)) {
			padding: 30px;
		}
		@media (max-width: ($md5 + px)) {
			padding: 20px;
		}
		@media (max-width: ($md6 + px)) {
			padding: 20px 15px;
		}
	}

	.section-16 {
		// .section-16__form-title

		&__form-title {
			color: #fff;

			@media (max-width: ($md5 + px)) {
				font-size: 24px;
			}
			@media (max-width: ($md6 + px)) {
				font-size: 22px;
			}
		}

		&__form-inner {
			display: flex;
			gap: 10px;
			color: #fff;
			@media (max-width: ($md3 + px)) {
				flex-direction: column;
			}
		}
		// .section-16__form-subtitle

		&__form-subtitle {
			color: #fff;
			margin-top: 12px;

			@media (max-width: ($md4 + px)) {
				br {
					display: none;
				}
			}

			@media (max-width: ($md6 + px)) {
				font-size: 18px;
			}
		}

		// .section-16__form-label

		&__form-label {
			color: #fff;
		}

		// .section-16__form-socials

		&__form-socials {
		}

		// .section-16__form

		&__form {
		}

		// .section-16__form-tel

		&__form-tel {
			height: 94px;
			width: 350px;
		}

		// .section-16__form-btn

		&__form-btn {
			margin-top: 0;
			width: 330px;
			@media (max-width: ($md3 + px)) {
				width: 350px;
			}
		}

		// .section-16__policy

		&__policy {
		}
	}

	.policy__label {
		padding-left: 25px;
		&::before {
			border: 1px solid #ffffff;
			opacity: 0.3;
			left: 0;
		}
		&::after {
			left: 3px;
		}
	}
	.socs {
		justify-content: flex-start;
		color: #fff;
		gap: 9px;
		@media (max-width: ($md5 + px)) {
			gap: 5px;
		}
	}

	.socs__soc {
		border-radius: 6px;
		border: 1px solid rgba($color: #ffffff, $alpha: 0.25);
	}
}

.section-27 {
	margin-top: 120px;

	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	// .section-27__container

	&__container {
	}

	// .section-27__card

	&__card {
		position: relative;

		@media (max-width: ($md3 + px)) {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}
	}

	// .section-27__back

	&__back {
		@media (max-width: ($md1 + px)) {
			gap: 5px;
		}

		@media (max-width: ($md2 + px)) {
			filter: opacity(0.6);
		}
		@media (max-width: ($md3 + px)) {
			filter: none;
		}
	}

	// .section-27__inner

	&__inner {
		position: absolute;
		top: 0;
		left: 0;
		padding-top: 88px;
		padding-left: 90px;

		@media (max-width: ($md1 + px)) {
			padding-top: 60px;
			padding-left: 55px;
		}

		@media (max-width: ($md3 + px)) {
			position: static;
			order: -1;
			padding-left: 0;
			padding-top: 0;
		}
	}

	// .section-27__title

	&__title {
		font-size: 28px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(38 / 28 * 100%);

		@media (max-width: ($md1 + px)) {
			font-size: 26px;
		}

		@media (max-width: ($md3 + px)) {
			br {
				display: none;
			}
			font-size: 24px;
			text-align: center;
		}

		@media (max-width: ($md5 + px)) {
			font-size: 22px;
		}
	}

	// .section-27__subtitle

	&__subtitle {
		font-size: 20px;
		font-weight: 600;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(30 / 20 * 100%);
		margin-top: 22px;

		@media (max-width: ($md3 + px)) {
			text-align: center;
			margin-top: 15px;
		}
	}

	// .section-27__list

	&__list {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		gap: 5px;

		@media (max-width: ($md3 + px)) {
			display: grid;
			grid-template-columns: repeat(2, auto);
			column-gap: 50px;
			row-gap: 5px;
			justify-content: center;
			flex-wrap: wrap;
			margin-top: 15px;
		}

		@media (max-width: ($md4 + px)) {
			grid-template-columns: auto;
		}
	}

	// .section-27__list-item

	&__list-item {
		font-size: 20px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(30 / 20 * 100%);
		padding-left: 18px;
		&::before {
			top: 13px;
		}

		@media (max-width: ($md3 + px)) {
			font-size: 18px;
		}
	}
}

.section-28 {
	max-width: 1880px;
	margin: auto;
	margin-top: 120px;
	padding: 120px 0;
	border-radius: 20px;
	background-size: cover;
	background-position: top center;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
		padding: 30px 0;
		background-position: right;
	}
	@media (max-width: ($md5 + px)) {
		background-image: none !important;
		padding: 0;
	}

	// .section-28__container

	&__container {
		@media (max-width: ($md5 + px)) {
			padding: 0;
		}
	}

	// .section-28__catalog

	&__catalog {
		position: absolute;
		right: -520px;
		top: 110px;
		z-index: 0;

		@media (max-width: ($md3 + px)) {
			display: none;
		}
	}
	.section-16 {
		// .section-16__formblock

		&__formblock {
			z-index: 2;
			position: relative;
			max-width: 490px;
			margin-left: auto;
			margin-top: 0;
			margin-right: 0;
			padding: 47px 50px 63px 50px;

			@media (max-width: ($md2 + px)) {
				margin: auto;
			}
			@media (max-width: ($md3 + px)) {
				padding: 45px;
				padding-top: 30px;
			}

			@media (max-width: ($md4 + px)) {
				padding: 25px;
				max-width: 420px;
			}

			@media (max-width: ($md6 + px)) {
				padding: 15px;
			}
		}

		// .section-16__form-title

		&__form-title {
			@media (max-width: ($md6 + px)) {
				font-size: 25px;
			}
		}

		// .section-16__form-subtitle

		&__form-subtitle {
			@media (max-width: ($md6 + px)) {
				br {
					display: none;
				}
			}
		}

		// .section-16__form-label

		&__form-label {
		}

		// .section-16__form-socials

		&__form-socials {
		}

		// .section-16__form

		&__form {
		}

		// .section-16__form-tel

		&__form-tel {
		}

		// .section-16__form-btn

		&__form-btn {
		}

		// .section-16__policy

		&__policy {
		}
	}
}

.section-29 {
	margin-top: 100px;

	@media (max-width: ($md3 + px)) {
		margin-top: 50px;

		.visible-hand {
			display: flex;
		}
	}

	// .section-29__container

	&__container {
	}

	// .section-29__title

	&__title {
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-29__items

	&__items {
		margin-top: 62px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 30px;

		@media (max-width: ($md1 + px)) {
			gap: 15px;
		}

		@media (max-width: ($md2 + px)) {
			gap: 10px;
		}
		@media (max-width: ($md3 + px)) {
			grid-template-columns: repeat(4, 300px);
			overflow: scroll;
			margin-top: 0;
			padding-bottom: 10px;
		}
	}

	// .section-29__longitem

	&__longitem {
		grid-column: span 2;
		position: relative;

		@media (max-width: ($md3 + px)) {
			grid-column: auto;
		}
	}

	// .section-29__long-back

	&__long-back {
		border-radius: 14px;
		overflow: hidden;

		@media (max-width: ($md2 + px)) {
			filter: brightness(0.7);
		}

		@media (max-width: ($md3 + px)) {
			filter: none;
		}
	}

	// .section-29__longitem-inner

	&__longitem-inner {
		position: absolute;
		top: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		z-index: 1;
		color: #fff;
		justify-content: center;
		padding-left: 120px;

		@media (max-width: ($md1 + px)) {
			padding-left: 70px;
		}
		@media (max-width: ($md2 + px)) {
			padding-left: 50px;
		}

		@media (max-width: ($md3 + px)) {
			position: static;
			padding-left: 0;
			padding-top: 15px;
			color: #000000;
		}
	}

	// .section-29__item-title

	&__item-title {
		@media (max-width: ($md1 + px)) {
			font-size: 29px;
		}
		@media (max-width: ($md2 + px)) {
			font-size: 24px;
		}

		@media (max-width: ($md3 + px)) {
			font-size: 20px;
			br {
				display: none;
			}
		}
	}

	// .section-29__longitem-text

	&__longitem-text {
		font-size: 24px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(34 / 24 * 100%);
		margin-top: 15px;
		@media (max-width: ($md2 + px)) {
			font-size: 20px;
		}
		b {
			font-weight: 700;
		}

		@media (max-width: ($md3 + px)) {
			font-size: 16px;
			br {
				display: none;
			}
			margin-top: 10px;
		}
	}

	// .section-29__item

	&__item {
		position: relative;

		@media (max-width: ($md2 + px)) {
			height: 450px;
		}

		@media (max-width: ($md3 + px)) {
			height: auto;
		}
	}

	// .section-29__back

	&__back {
		border-radius: 14px;
		overflow: hidden;

		@media (max-width: ($md2 + px)) {
			height: 100%;
			filter: opacity(0.6);
		}

		@media (max-width: ($md3 + px)) {
			filter: none;
			height: auto;
		}
	}

	// .section-29__inner

	&__inner {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		padding-left: 50px;
		padding-top: 53px;
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}

		@media (max-width: ($md1 + px)) {
			padding-left: 30px;
			padding-top: 30px;
		}

		@media (max-width: ($md2 + px)) {
			padding-left: 15px;
			padding-top: 20px;
		}

		@media (max-width: ($md3 + px)) {
			position: static;
			padding-left: 0;
			padding-top: 15px;
		}
	}

	// .section-29__text

	&__text {
		margin-top: 14px;
		@media (max-width: ($md2 + px)) {
			margin-top: 10px;
		}
	}

	// .section-29__gost

	&__gost {
		position: absolute;
		bottom: 27px;
		right: 40px;
		@media (max-width: ($md2 + px)) {
			max-width: 200px;
		}
		@media (max-width: ($md3 + px)) {
			display: none;
		}
	}
}

.section-30 {
	margin-top: 112px;

	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	// .section-30__container

	&__container {
	}

	// .section-30__title

	&__title {
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-30__yandex-logo

	&__yandex-logo {
		@media (max-width: ($md2 + px)) {
			max-width: 325px;
		}
		@media (max-width: ($md3 + px)) {
			max-width: 300px;
		}
		@media (max-width: ($md4 + px)) {
			max-width: 250px;
		}
		@media (max-width: ($md5 + px)) {
			max-width: 220px;
		}
	}

	// .section-30__slider

	&__slider {
	}

	&__slider-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 29px;
		margin-top: 61px;

		@media (max-width: ($md2 + px)) {
			gap: 10px;
		}

		@media (max-width: ($md3 + px)) {
			margin-top: 30px;
		}

		@media (max-width: ($md5 + px)) {
			gap: 0;
			margin-left: -9px;
			margin-right: -9px;
		}
	}
}

.section-31 {
	margin-top: 180px;
	@media (max-width: ($md3 + px)) {
		margin-top: 85px;
	}
	// .section-31__container

	&__container {
	}

	// .section-31__title

	&__title {
		@media (max-width: ($md3 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-31__slider

	&__slider {
		margin-top: 60px;

		@media (max-width: ($md3 + px)) {
			margin-top: 30px;
		}
	}
}

.section-32 {
	padding-top: 110px;
	padding-bottom: 120px;
	border-radius: 40px 40px 0 0;
	background-color: #f7f8fa;
	max-width: 1560px;
	margin: auto;
	margin-top: 120px;
	@media (max-width: ($md2 + px)) {
		.visible-hand {
			display: flex;
		}
	}
	@media (max-width: ($md3 + px)) {
		margin-top: 30px;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	& + .section-5 {
		margin-top: 0;
		padding-top: 110px;

		@media (max-width: ($md3 + px)) {
			padding-top: 30px;
		}
	}

	// .section-32__container

	&__container {
	}

	// .section-32__title

	&__title {
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-32__items

	&__items {
		margin-top: 60px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;

		@media (max-width: ($md1 + px)) {
			gap: 15px;
		}
		@media (max-width: ($md2 + px)) {
			margin-top: 0;
			display: flex;
			overflow: scroll;
		}
		@media (max-width: ($md3 + px)) {
			gap: 10px;
		}
	}

	// .section-32__item

	&__item {
		display: flex;
		flex-direction: column;
		gap: 32px;

		@media (max-width: ($md2 + px)) {
			flex: 0 0 300px;
		}

		@media (max-width: ($md3 + px)) {
			gap: 20px;
		}
	}

	// .section-32__videoblock

	&__videoblock {
		border-radius: 16px;
		background-color: #000000;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		background-size: cover;
		background-position: top center;
		overflow: hidden;
		background-repeat: no-repeat;
		padding-bottom: 66%;
	}

	// .section-32__videoblock-btn

	&__videoblock-btn {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		color: #ffffff;
		font-size: 14px;
		font-weight: 700;
		text-align: center;
		text-transform: uppercase;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		width: 240px;
		height: 66px;
		letter-spacing: 0.42px;
		box-shadow: 0 25px 50px rgba(1, 108, 208, 0.15), inset 0 0 18px 2px rgba(255, 255, 255, 0.15);
		border-radius: 10px;
		background-image: linear-gradient(to top, #016cd0 0%, #027aeb 100%);
	}

	// .section-32__comment

	&__comment {
		position: relative;
		background-color: #fff;
		padding-top: 29px;
		padding-left: 44px;
		padding-bottom: 32px;
		padding-right: 15px;
		border-radius: 10px;
		height: 100%;
		&::after {
			position: absolute;
			content: "";
			width: 0;
			height: 0;
			border-left: 15px solid transparent;
			border-right: 15px solid transparent;
			border-bottom: 15px solid #fff;
			left: 32px;
			top: -12px;
		}
		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
		}

		@media (max-width: ($md2 + px)) {
			padding: 30px 25px;
		}
	}

	// .section-32__quote

	&__quote {
		position: absolute;
		top: 17px;
		left: 20px;

		@media (max-width: ($md2 + px)) {
			max-width: 35px;
			left: 15px;
			top: 15px;
		}
	}

	// .section-32__text

	&__text {
		font-size: 20px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(32 / 20 * 100%);
		@media (max-width: ($md1 + px)) {
			font-size: 18px;
		}
	}
}

.section-33 {
	background-size: cover;
	background-position: top center;
	padding-top: 350px;
	padding-bottom: 120px;
	color: #fff;
	// .section-33__container
	@media (max-width: ($md3 + px)) {
		padding-top: 320px;
		padding-bottom: 30px;
	}

	@media (max-width: ($md5 + px)) {
		padding-top: 300px;
	}
	&__container {
	}

	// .section-33__items

	&__items {
		display: flex;
		gap: 49px;
		margin-top: 37px;

		@media (max-width: ($md3 + px)) {
			margin-top: 30px;
		}
		@media (max-width: ($md4 + px)) {
			gap: 25px;
		}

		@media (max-width: ($md5 + px)) {
			flex-direction: column;
			gap: 15px;
		}

		& + .section-33__form {
			margin-top: 45px;
			@media (max-width: ($md3 + px)) {
				margin-top: 30px;
			}
		}
	}

	// .section-33__item

	&__item {
		display: flex;
		gap: 20px;
		@media (max-width: ($md4 + px)) {
			gap: 15px;
		}
	}

	// .section-33__item-icon

	&__item-icon {
	}

	// .section-33__item-description

	&__item-description {
		margin-top: 9px;
	}

	// .section-33__item-text

	&__item-text {
		line-height: calc(26 / 18 * 100%);
		br {
			display: block;
		}
	}

	// .section-33__item-list

	&__item-list {
		margin-top: 3px;
		display: flex;
		flex-direction: column;
		gap: 2px;
	}

	// .section-33__list-item

	&__list-item {
		font-size: 16px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(24 / 16 * 100%);
		padding-left: 11px;

		&::before {
			top: 10px;
		}
		b {
			font-weight: 700;
		}
	}

	// .section-33__title

	&__title {
		@media (max-width: ($md3 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-33__subtitle

	&__subtitle {
		margin-top: 10px;
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-33__form

	&__form {
		color: #000;
		margin-top: 93px;
		@media (max-width: ($md2 + px)) {
			margin-top: 60px;
		}
		@media (max-width: ($md3 + px)) {
			margin-top: 30px;
		}
		@media (max-width: ($md5 + px)) {
			margin-right: -10px;
			margin-left: -10px;
		}
	}

	.capture-form {
		max-width: fit-content;
		border-radius: 10px;
		background-color: #ffffff;
		// .capture-form__block

		&__block {
			padding: 45px 60px 49px 60px;
			@media (max-width: ($md2 + px)) {
				padding: 45px 40px;
			}
			@media (max-width: ($md3 + px)) {
				padding: 30px;
				max-width: 500px;
			}
			@media (max-width: ($md5 + px)) {
				padding: 20px;
			}
		}

		// .capture-form__title

		&__title {
			font-size: 30px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(42 / 30 * 100%);
			@media (max-width: ($md3 + px)) {
				font-size: 28px;
				br {
					display: none;
				}
			}

			@media (max-width: ($md4 + px)) {
				font-size: 26px;
			}

			@media (max-width: ($md5 + px)) {
				font-size: 23px;
			}
		}

		// .capture-form__subtitle

		&__subtitle {
			font-size: 22px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(32 / 22 * 100%);
			@media (max-width: ($md3 + px)) {
				font-size: 20px;
				margin-top: 10px;
			}

			@media (max-width: ($md5 + px)) {
				font-size: 18px;
			}
		}

		// .capture-form__form

		&__form {
		}

		// .capture-form__inner

		&__inner {
			display: flex;
			gap: 10px;
			margin-top: 37px;
			@media (max-width: ($md3 + px)) {
				flex-direction: column;
				margin-top: 20px;
			}
		}

		// .capture-form__field

		&__field {
			margin-top: 0;
		}

		// .capture-form__phone

		&__phone {
			width: 350px;
			height: 94px;
			@media (max-width: ($md2 + px)) {
				width: 330px;
			}
			@media (max-width: ($md3 + px)) {
				width: 100%;
			}
		}

		// .capture-form__button

		&__button {
			width: 370px;
			height: 94px;
			margin-top: 0;
			@media (max-width: ($md2 + px)) {
				width: 330px;
			}
			@media (max-width: ($md3 + px)) {
				width: 100%;
			}
		}

		// .capture-form__policy

		&__policy {
			text-align: center;
			margin-top: 17px;
			margin-left: -22px;
			@media (max-width: ($md5 + px)) {
				margin-left: 0;
				text-align: left;
			}
		}

		// .capture-form__footer

		&__footer {
		}

		// .capture-form__telephone-text

		&__telephone-text {
		}

		// .capture-form__telephone

		&__telephone {
		}
	}
}
.section-34 {
	max-width: 1560px;
	border-radius: 40px;
	background-color: #f7f8fa;
	margin: auto;
	margin-top: 120px;
	padding-top: 110px;
	padding-bottom: 178px;
	// .section-34__container
	@media (max-width: ($md3 + px)) {
		.visible-hand {
			display: flex;
		}
		padding-top: 30px;
		margin-top: 50px;
		padding-bottom: 150px;
	}
	&__container {
	}

	// .section-34__title

	&__title {
		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-34__items

	&__items {
		margin-top: 60px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 20px;
		@media (max-width: ($md1 + px)) {
			gap: 10px;
		}
		@media (max-width: ($md3 + px)) {
			margin-top: 0;
			overflow: scroll;
			grid-template-columns: repeat(4, 200px);
		}
	}

	// .section-34__item

	&__item {
		position: relative;
	}

	// .section-34__item-back

	&__item-back {
		border-radius: 14px;
		overflow: hidden;
	}

	// .section-34__item-text

	&__item-text {
		top: 0;
		left: 0;
		position: absolute;
		padding-left: 25px;
		padding-top: 37px;
		padding-right: 25px;
		span {
			height: 23px;
			border-radius: 5px;
			background-color: #fff;
			padding: 7px 15px;
			font-size: 16px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(24 / 16 * 100%);
			text-align: left;
			text-transform: uppercase;
			@media (max-width: ($md2 + px)) {
				padding: 7px 10px;
				font-size: 13px;
				line-height: 1.8;
			}
		}
		@media (max-width: ($md1 + px)) {
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 20px;
		}

		@media (max-width: ($md2 + px)) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	// .section-34__card

	&__card {
		position: relative;
		margin-top: 20px;

		@media (max-width: ($md3 + px)) {
			height: 190px;
		}
	}

	// .section-34__card-back

	&__card-back {
		@media (max-width: ($md3 + px)) {
			height: 100%;
			border-radius: 14px;
			filter: brightness(0.8);
		}
		@media (max-width: ($md5 + px)) {
			filter: brightness(0.7);
		}
	}

	// .section-34__card-text

	&__card-text {
		position: absolute;
		color: #fff;
		display: flex;
		top: 0;
		bottom: 0;
		align-items: center;
		padding-left: 100px;

		@media (max-width: ($md2 + px)) {
			padding-left: 40px;
		}
		@media (max-width: ($md6 + px)) {
			padding-left: 30px;
		}
	}

	& + .section-credit {
		margin-top: -118px;
		.section-2__credit {
			margin-top: 0;
		}
	}
}

.section-35 {
	margin-top: 110px;

	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}

	// .section-35__container

	&__container {
	}

	// .section-35__title

	&__title {
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-35__items

	&__items {
		margin-top: 60px;
		display: flex;
		flex-direction: column;
		gap: 30px;

		@media (max-width: ($md2 + px)) {
			gap: 20px;
		}

		@media (max-width: ($md4+ px)) {
			margin-top: 30px;
		}
	}

	// .section-35__item

	&__item {
		position: relative;
		@media (max-width: ($md4+ px)) {
			display: flex;
			flex-direction: column;
		}
	}

	// .section-35__item-back

	&__item-back {
		@media (max-width: ($md2 + px)) {
			filter: opacity(0.5);
		}
		@media (max-width: ($md4+ px)) {
			filter: none;
			border-radius: 14px;
			overflow: hidden;
		}
	}

	// .section-35__inner

	&__inner {
		position: absolute;
		top: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 90px;

		@media (max-width: ($md1 + px)) {
			padding-left: 70px;
		}
		@media (max-width: ($md2 + px)) {
			padding-left: 40px;
		}

		@media (max-width: ($md3 + px)) {
			max-width: 500px;
		}

		@media (max-width: ($md4 + px)) {
			max-width: 100%;
			position: static;
			padding-left: 0;
			order: -1;
			br {
				display: none;
			}
		}
	}

	// .section-35__item-title

	&__item-title {
	}

	// .section-35__item-text

	&__item-text {
		margin-top: 18px;
		@media (max-width: ($md3 + px)) {
			margin-top: 10px;
		}
		@media (max-width: ($md4 + px)) {
			margin-bottom: 10px;
		}
	}

	+ .section-credit {
		.section-2__credit {
			margin-top: 50px;

			@media (max-width: ($md3 + px)) {
				margin-top: 30px;
			}
		}
	}
}

.section-36 {
	margin-top: 110px;

	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
		.visible-hand {
			display: flex;
		}
	}

	// .section-36__container

	&__container {
	}

	// .section-36__title

	&__title {
	}

	// .section-36__items

	&__items {
		margin-top: 60px;
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		gap: 30px;
		@media (max-width: ($md2 + px)) {
			gap: 15px;
		}
		@media (max-width: ($md3 + px)) {
			grid-template-columns: repeat(5, 300px);
			gap: 10px;
			overflow: scroll;
			margin-top: 0;
		}
	}

	// .section-36__item

	&__item {
		position: relative;
		&:nth-of-type(1),
		&:nth-of-type(2),
		&:nth-of-type(3) {
			grid-column: span 2;
		}
		&:nth-of-type(4),
		&:nth-of-type(5) {
			grid-column: span 3;
		}

		@media (max-width: ($md3 + px)) {
			grid-column: auto !important;
			height: 100%;
		}
	}
	// .section-36__item-back

	&__item-back {
		@media (max-width: ($md3 + px)) {
			height: 100%;
			border-radius: 14px;
			overflow: hidden;
		}
	}

	// .section-36__text

	&__text {
		position: absolute;
		top: 20px;
		left: 20px;
		border-radius: 6px;
		background-color: #fff;
		padding: 13px 20px;
		font-size: 16px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;

		text-align: left;
		text-transform: uppercase;
		@media (max-width: ($md3 + px)) {
			top: 15px;
			left: 15px;
		}
		@media (max-width: ($md5 + px)) {
			font-size: 13px;
		}
	}
}

.section-37 {
	max-width: 1560px;
	border-radius: 40px;
	background-color: #f7f8fa;
	margin: auto;
	margin-top: 120px;
	padding-top: 110px;
	padding-bottom: 120px;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
		padding-top: 30px;
		padding-bottom: 30px;
	}
	@media (max-width: 640px) {
		.visible-hand {
			display: flex;
			margin: 10px 0;
		}
	}
	// .section-37__container

	&__container {
	}

	// .section-37__title

	&__title {
	}

	// .section-37__block
	&__blocks {
		display: flex;
		flex-direction: column;
		gap: 50px;
		margin-top: 60px;
		@media (max-width: ($md3 + px)) {
			gap: 35px;
			margin-top: 30px;
		}
	}

	&__block {
	}

	// .section-37__number

	&__number {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		background-color: #027ef2;
		color: #ffffff;
		font-size: 16px;
		font-weight: 700;
		line-height: 38px;
		border-radius: 50%;
	}

	// .section-37__block-title

	&__block-title {
		display: flex;
		align-items: center;
		gap: 15px;
		margin-bottom: 20px;
		@media (max-width: ($md3 + px)) {
			margin-bottom: 15px;
		}
	}

	// .section-37__items

	&__items {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 30px;

		@media (max-width: ($md1 + px)) {
			gap: 20px;
		}
		@media (max-width: ($md2 + px)) {
			gap: 10px;
		}

		@media (max-width: ($md3 + px)) {
			overflow: auto;
		}
	}

	// .section-37__item

	&__item {
		position: relative;

		@media (max-width: ($md3 + px)) {
			min-width: 300px;
		}
	}

	// .section-37__item-back

	&__item-back {
		border-radius: 14px;
		overflow: hidden;
	}

	// .section-37__text

	&__text {
		position: absolute;
		top: 25px;
		left: 20px;

		@media (max-width: ($md2 + px)) {
			top: 18px;

			left: 8px;
		}

		span {
			border-radius: 6px;
			background-color: #fff;
			padding: 10px 20px;
			font-size: 16px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(30 / 16 * 100%);
			text-align: left;
			text-transform: uppercase;
			@media (max-width: ($md2 + px)) {
				padding: 10px 8px;

				font-size: 14px;
			}
		}
	}

	// .section-37__middle-items
	&__middle {
		@media (max-width: ($md3 + px)) {
			.visible-hand {
				display: flex;
			}
		}
	}
	&__middle-items {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;
		@media (max-width: ($md1 + px)) {
			gap: 20px;
		}
		@media (max-width: ($md2 + px)) {
			gap: 10px;
		}
		@media (max-width: ($md3 + px)) {
			grid-template-columns: repeat(3, 250px);
			overflow: scroll;
			.section-37__item {
				min-width: 250px;
			}
		}
	}
}
.section-38 {
	margin-top: 110px;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	@media (max-width: 640px) {
		.visible-hand {
			display: flex;
		}
	}
	// .section-38__container

	&__container {
	}

	// .section-38__title

	&__title {
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-38__items

	&__items {
		margin-top: 53px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 30px;
		@media (max-width: ($md1 + px)) {
			gap: 20px;
		}
		@media (max-width: ($md2 + px)) {
			gap: 10px;
		}

		@media (max-width: ($md3 + px)) {
			overflow: auto;
			margin-top: 30px;
		}
		@media (max-width: 640px) {
			margin: 0;
			padding-bottom: 10px;
		}
	}

	// .section-38__item

	&__item {
		position: relative;

		@media (max-width: ($md3 + px)) {
			min-width: 300px;
		}
	}

	// .section-38__item-back

	&__item-back {
		@media (max-width: ($md2 + px)) {
			filter: opacity(0.65);
		}
		@media (max-width: ($md3 + px)) {
			filter: none;
			border-radius: 14px;
			overflow: hidden;
		}
	}

	// .section-38__inner

	&__inner {
		position: absolute;
		top: 0;
		left: 0;
		padding-top: 52px;
		padding-left: 70px;

		@media (max-width: ($md1 + px)) {
			padding-left: 40px;
			padding-top: 30px;
		}
		@media (max-width: ($md2 + px)) {
			padding-left: 20px;
			padding-right: 15px;
		}
		@media (max-width: ($md3 + px)) {
			padding-left: 0;
			padding-right: 0;
			padding-top: 15px;
			position: static;
		}
	}

	// .section-38__item-title

	&__item-title {
		@media (max-width: ($md2 + px)) {
			font-size: 25px;
			br {
				display: none;
			}
		}
		@media (max-width: ($md3 + px)) {
			font-size: 23px;
		}
	}

	// .section-38__list

	&__list {
		margin-top: 12px;
		display: flex;
		flex-direction: column;
		gap: 10px;

		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-38__list-item

	&__list-item {
		&::before {
			top: 10px;
		}
	}
}

.section-39 {
	margin-top: 110px;
	@media (max-width: ($md2 + px)) {
		.visible-hand {
			display: flex;
		}
	}
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	// .section-39__container

	&__container {
	}

	// .section-39__title

	&__title {
	}

	// .section-39__items

	&__items {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;
		margin-top: 53px;
		@media (max-width: ($md1 + px)) {
			gap: 15px;
		}

		@media (max-width: ($md2 + px)) {
			display: flex;
			overflow: scroll;
			gap: 10px;
			margin-top: 0;
			padding-bottom: 10px;
		}
	}

	// .section-39__item

	&__item {
		@media (max-width: ($md2 + px)) {
			flex: 0 0 300px;
		}
	}

	// .section-39__text

	&__text {
		padding-left: 14px;
		margin-top: 23px;
		line-height: calc(30 / 18 * 100%);
		@media (max-width: ($md2 + px)) {
			padding-left: 0;
			margin-top: 15px;

			br {
				display: none;
			}
		}
	}
}
.section-40 {
	margin-top: 110px;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
		.visible-hand {
			display: flex;
		}
	}

	// .section-40__container

	&__container {
	}

	// .section-40__title

	&__title {
		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-40__items

	&__items {
		margin-top: 60px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;
		@media (max-width: ($md1 + px)) {
			gap: 15px;
		}
		@media (max-width: ($md3 + px)) {
			gap: 10px;
			grid-template-columns: repeat(3, 270px);
			overflow: scroll;
			margin-top: 0;
		}
	}

	// .section-40__item

	&__item {
		position: relative;
	}

	// .section-40__item-back

	&__item-back {
	}

	// .section-40__item-inner

	&__item-inner {
		position: absolute;
		top: 0;
		left: 0;
		padding-left: 40px;
		padding-right: 20px;
		padding-top: 35px;
		@media (max-width: ($md1 + px)) {
			padding-left: 25px;
		}
		@media (max-width: ($md2 + px)) {
			padding-top: 15px;
		}
	}

	// .section-40__item-text

	&__item-text {
		font-size: 20px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(30 / 20 * 100%);
		text-align: left;

		b {
			font-weight: 700;
		}

		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
			font-size: 18px;
		}

		@media (max-width: ($md2 + px)) {
			font-size: 16px;
		}
	}

	// .section-40__card

	&__card {
		margin-top: 30px;
		position: relative;
		@media (max-width: ($md1 + px)) {
			margin-top: 15px;
		}
		@media (max-width: ($md4 + px)) {
			height: 250px;
			border-radius: 14px;
			overflow: hidden;
		}
	}

	// .section-40__card-back

	&__card-back {
		@media (max-width: ($md3 + px)) {
			filter: opacity(0.8);
		}
		@media (max-width: ($md4 + px)) {
			height: 100%;
			filter: opacity(0.6);
		}
	}

	// .section-40__card-inner

	&__card-inner {
		position: absolute;
		top: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 112px;
		gap: 17px;

		@media (max-width: ($md1 + px)) {
			padding-left: 70px;
		}

		@media (max-width: ($md2 + px)) {
			padding-left: 60px;
		}
		@media (max-width: ($md3 + px)) {
			padding-left: 40px;
		}
		@media (max-width: ($md4 + px)) {
			padding-left: 5%;
		}
		@media (max-width: ($md6 + px)) {
			padding-right: 5%;
		}
	}

	// .section-40__card-title

	&__card-title {
	}

	// .section-40__card-text

	&__card-text {
		@media (max-width: ($md3 + px)) {
			br {
				display: block;
			}
		}

		@media (max-width: ($md6 + px)) {
			br {
				display: none;
			}
		}
	}
}

.section-41 {
	margin-top: 110px;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;

		.visible-hand {
			display: flex;
		}
	}
	// .section-41__container

	&__container {
	}

	// .section-41__title

	&__title {
	}

	// .section-41__items

	&__items {
		margin-top: 60px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;

		@media (max-width: ($md1 + px)) {
			gap: 15px;
		}

		@media (max-width: ($md3 + px)) {
			grid-template-columns: repeat(3, 300px);
			overflow: scroll;
			gap: 10px;
			padding-bottom: 10px;
			margin-top: 0;
		}
	}

	// .section-41__item

	&__item {
	}

	// .section-41__picture

	&__picture {
		position: relative;
	}

	// .section-41__img

	&__img {
	}

	// .section-41__text

	&__text {
		position: absolute;
		top: 35px;
		left: 30px;
		span {
			padding: 4px 10px;
			font-size: 20px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(28 / 20 * 100%);
			background-color: #fff;
			border-radius: 5px;
			@media (max-width: ($md2 + px)) {
				font-size: 16px;
			}
		}

		@media (max-width: ($md1 + px)) {
			left: 20px;
		}
		@media (max-width: ($md2 + px)) {
			left: 7px;

			top: 15px;
		}
	}

	// .section-41__description

	&__description {
		padding-left: 10px;
		margin-top: 30px;
		@media (max-width: ($md2 + px)) {
			padding-left: 0;
			margin-top: 20px;
		}

		@media (max-width: ($md3 + px)) {
			margin-top: 15px;
		}
	}

	// .section-41__item-title

	&__item-title {
		font-size: 22px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(30 / 22 * 100%);
		text-align: left;

		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
		}
		@media (max-width: ($md2 + px)) {
			font-size: 19px;
		}
	}

	// .section-41__item-text

	&__item-text {
		padding-top: 7px;

		b {
			font-weight: 700;
		}

		@media (max-width: ($md2 + px)) {
			font-size: 17px;
			br {
				display: none;
			}
		}
		@media (max-width: ($md3 + px)) {
			padding-top: 5px;
		}
	}

	& + .section-credit {
		margin-top: 58px;

		@media (max-width: ($md3 + px)) {
			margin-top: 20px;
		}
	}
}
.section-42 {
	margin-top: 110px;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
		.visible-hand {
			display: flex;
		}
	}
	// .section-42__container

	&__container {
	}

	// .section-42__title

	&__title {
		@media (max-width: ($md3 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-42__items

	&__items {
		margin-top: 60px;
		display: grid;
		grid-template-columns: repeat(3, auto);
		gap: 20px;
		justify-content: center;
		@media (max-width: ($md1 + px)) {
			grid-template-columns: repeat(3, 1fr);
			gap: 15px;
		}

		@media (max-width: ($md2 + px)) {
			gap: 10px;
		}
		@media (max-width: ($md3 + px)) {
			grid-template-columns: repeat(3, 300px);
			overflow: scroll;
			margin-top: 0;
			justify-content: start;
			padding-bottom: 10px;
		}
	}

	// .section-42__item

	&__item {
	}

	// .section-42__picture

	&__picture {
	}

	// .section-42__inner

	&__inner {
		margin-top: 30px;
		padding-left: 9px;
		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
			padding-left: 0;
			margin-top: 20px;
		}

		@media (max-width: ($md2 + px)) {
			margin-top: 20px;
		}
	}

	// .section-42__item-title

	&__item-title {
		font-size: 24px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(32 / 24 * 100%);

		@media (max-width: ($md2 + px)) {
			font-size: 18px;
		}
	}

	// .section-42__item-text

	&__item-text {
		margin-top: 12px;
		@media (max-width: ($md2 + px)) {
			font-size: 16px;
			margin-top: 5px;
		}
	}
}

.section-43 {
	margin-top: 107px;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;

		.visible-hand {
			display: flex;
		}
	}

	// .section-43__container

	&__container {
	}

	// .section-43__title

	&__title {
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-43__items

	&__items {
		margin-top: 60px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 20px;
		@media (max-width: ($md1 + px)) {
			gap: 10px;
		}
		@media (max-width: ($md2 + px)) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media (max-width: ($md3 + px)) {
			display: flex;
			overflow: scroll;
			margin-top: 0;
		}
	}

	// .section-43__item

	&__item {
		position: relative;
		@media (max-width: ($md3 + px)) {
			flex: 0 0 235px;
		}
	}

	// .section-43__item-text

	&__item-text {
		position: absolute;
		top: 35px;
		left: 25px;
		span {
			font-size: 18px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(24 / 18 * 100%);
			background-color: #fff;
			padding: 5px 10px;
			border-radius: 5px;
			@media (max-width: ($md1 + px)) {
				font-size: 17px;

				line-height: 1.4;
			}
		}
		@media (max-width: ($md1 + px)) {
			top: 15px;
			left: 10px;
		}
	}
}

.section-44 {
	margin-top: 110px;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
		.visible-hand {
			display: flex;
		}
	}

	// .section-44__container

	&__container {
	}

	// .section-44__title

	&__subtitle {
		margin-top: 25px;

		@media (max-width: ($md3 + px)) {
			margin-top: 15px;
		}
	}

	// .section-44__items

	&__items {
		margin-top: 60px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;

		@media (max-width: ($md1 + px)) {
			gap: 20px;
		}
		@media (max-width: ($md2 + px)) {
			gap: 15px;
		}
		@media (max-width: ($md3 + px)) {
			gap: 10px;
			display: flex;
			overflow: scroll;
			margin-top: 0;
			padding-bottom: 10px;
		}
	}

	// .section-44__item
	&__inner {
		margin-top: 30px;
		padding-left: 10px;
		@media (max-width: ($md1 + px)) {
			padding-left: 0;
		}
		@media (max-width: ($md3 + px)) {
			margin-top: 15px;
		}
	}
	&__item {
		@media (max-width: ($md3 + px)) {
			flex: 0 0 300px;
		}
	}

	// .section-44__item-title

	&__item-title {
		font-size: 24px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(32 / 24 * 100%);
		@media (max-width: ($md1 + px)) {
			font-size: 22px;
		}
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
		@media (max-width: ($md3 + px)) {
			font-size: 21px;
		}
	}

	// .section-44__item-text

	&__item-text {
		margin-top: 15px;
		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
		}

		@media (max-width: ($md2 + px)) {
			font-size: 17px;
			margin-top: 10px;
		}
	}
}
.section-45 {
	margin-top: 50px;
	@media (max-width: ($md3 + px)) {
		margin-top: 30px;
	}

	// .section-45__container

	&__container {
	}

	// .section-45__card

	&__card {
		position: relative;
		@media (max-width: ($md4 + px)) {
			height: 250px;
		}
		@media (max-width: ($md5 + px)) {
			height: 220px;
		}
		img {
			@media (max-width: ($md3 + px)) {
				filter: brightness(0.8);
			}
			@media (max-width: ($md4 + px)) {
				height: 100%;
				border-radius: 14px;
			}
		}
	}

	// .section-45__card-inner

	&__card-inner {
		position: absolute;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		padding-left: 90px;
		color: #ffffff;

		font-size: 28px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(38 / 28 * 100%);
		@media (max-width: ($md2 + px)) {
			padding-left: 55px;
			font-size: 25px;
		}

		@media (max-width: ($md3 + px)) {
			font-size: 23px;
			padding-left: 30px;
		}

		@media (max-width: ($md5 + px)) {
			font-size: 21px;
			padding-left: 20px;
			padding-right: 20px;
			br {
				display: none;
			}
		}
	}

	& + .section-credit {
		@media (max-width: ($md2 + px)) {
			.section-2__credit {
				margin-top: 30px;
			}
		}
	}
}

.section-46 {
	margin-top: 110px;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
		.visible-hand {
			display: flex;
		}
	}

	.section-44__items {
		row-gap: 45px;
	}

	.section-44__inner {
		padding-left: 28px;
		@media (max-width: ($md1 + px)) {
			padding-left: 15px;
		}
		@media (max-width: ($md2 + px)) {
			padding-left: 0px;
		}
	}

	.section-44__item-text {
		@media (max-width: ($md2 + px)) {
			font-size: 16px;
		}
		@media (max-width: ($md3 + px)) {
			margin-top: 5px;
		}
	}
}

.section-47 {
	border-radius: 40px;
	background-color: #f7f8fa;
	padding: 120px 0;
	max-width: 1560px;
	margin: auto;
	margin-top: 110px;

	@media (max-width: ($md2 + px)) {
		padding: 90px 0;
	}
	@media (max-width: ($md3 + px)) {
		padding: 30px 0;
		margin-top: 50px;
	}
	// .section-47__container

	&__container {
	}

	// .section-47__items

	&__items {
		display: flex;
		flex-direction: column;
		gap: 30px;
		@media (max-width: ($md1 + px)) {
			gap: 20px;
		}
		@media (max-width: ($md2 + px)) {
			gap: 50px;
		}
		@media (max-width: ($md4 + px)) {
			gap: 30px;
		}
	}

	// .section-47__item

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media (max-width: ($md1 + px)) {
			display: grid;
			grid-template-columns: 32% auto;
		}
		@media (max-width: ($md2 + px)) {
			grid-template-columns: auto;
			gap: 20px;
		}
		@media (max-width: ($md4 + px)) {
			gap: 15px;
		}
	}

	// .section-47__title

	&__title {
		text-align: left;
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-47__photos

	&__photos {
		display: flex;
		gap: 30px;
		@media (max-width: ($md1 + px)) {
			gap: 20px;
		}
		@media (max-width: ($md3 + px)) {
			gap: 10px;
		}
	}

	// .section-47__photo

	&__photo {
		position: relative;
	}

	// .section-47__text

	&__text {
		position: absolute;
		left: 20px;
		background-color: #fff;
		padding: 6px 20px;
		font-size: 16px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(30 / 16 * 100%);
		text-align: center;
		text-transform: uppercase;
		border-radius: 6px;
		@media (max-width: ($md3 + px)) {
			padding: 6px 10px;
		}
		@media (max-width: ($md4 + px)) {
			position: static;
			padding: 6px;
			line-height: 1.5;
			font-size: 14px;
		}
	}

	// .section-47__bottom-text

	&__bottom-text {
		bottom: 20px;
	}

	// .section-47__top-text

	&__top-text {
		top: 20px;
	}

	.section-45__card {
		margin-top: 30px;
	}
}

.section-48 {
	margin-top: 110px;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
		.visible-hand {
			display: flex;
		}
	}

	&__title {
		@media (max-width: ($md5 + px)) {
			br {
				display: none;
			}
		}
	}
	.section-43__item-text span {
		text-transform: uppercase;
		padding: 6px 14px;
	}
	.section-43__items {
		@media (max-width: ($md2 + px)) {
			grid-template-columns: repeat(2, auto);
			justify-content: center;
		}
		@media (max-width: ($md3 + px)) {
			justify-content: start;
		}
	}
}

.section-49 {
	margin-top: 110px;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	@media (max-width: ($md4 + px)) {
		.visible-hand {
			display: flex;
		}
	}
	// .section-49__container

	&__container {
	}

	// .section-49__title

	&__title {
	}

	// .section-49__items

	&__items {
		margin-top: 60px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;
		@media (max-width: ($md2 + px)) {
			gap: 20px;
		}
		@media (max-width: ($md3 + px)) {
			gap: 10px;
			margin-top: 30px;
		}
		@media (max-width: ($md4 + px)) {
			grid-template-columns: repeat(3, 300px);
			overflow: scroll;
			padding-bottom: 10px;
			margin-top: 0;
		}
	}

	// .section-49__item

	&__item {
	}

	// .section-49__item-text

	&__item-text {
		text-align: center;
		line-height: calc(30 / 18 * 100%);
		margin-top: 20px;
		font-size: 18px;

		@media (max-width: ($md4 + px)) {
			margin-top: 10px;
		}
	}

	& + .section-44 {
		max-width: 1560px;
		margin: auto;
		margin-top: 120px;
		border-radius: 40px;
		background-color: #f7f8fa;
		padding-top: 110px;
		padding-bottom: 205px;
		@media (max-width: ($md3 + px)) {
			margin-top: 50px;
			padding-top: 30px;
			padding-bottom: 160px;
		}
	}

	& + .section-44 + .section-credit {
		margin-top: -160px;
	}
}

.section-50 {
	margin-top: 110px;
	// .section-50__container
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	@media (max-width: ($md5 + px)) {
		.visible-hand {
			display: flex;
		}
	}
	&__container {
	}

	// .section-50__title

	&__title {
		@media (max-width: ($md2 + px)) {
			br {
				display: none;
			}
		}
	}

	// .section-50__items

	&__items {
		margin-top: 60px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 20px;

		@media (max-width: ($md2 + px)) {
			gap: 10px;
		}
		@media (max-width: ($md3 + px)) {
			grid-template-columns: repeat(2, 200px);
			justify-content: center;
			margin-top: 30px;
		}
		@media (max-width: ($md5 + px)) {
			grid-template-columns: repeat(4, 200px);
			justify-content: start;
			margin-top: 0px;
			overflow: scroll;
			padding-bottom: 10px;
		}
	}

	// .section-50__item

	&__item {
		position: relative;
	}

	// .section-50__item-text

	&__item-text {
		position: absolute;
		bottom: 25px;
		left: 25px;
		border-radius: 5px;
		background-color: #fff;
		font-size: 16px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(24 / 16 * 100%);
		text-align: left;
		text-transform: uppercase;
		padding: 5px 16px;

		@media (max-width: ($md2 + px)) {
			padding: 5px 10px;
			left: 10px;
			bottom: 10px;
		}
	}
}

.section-51 {
	max-width: 1880px;
	padding-top: 113px;
	padding-bottom: 120px;
	margin: auto;
	margin-top: 119px;
	background-size: cover;
	background-image: url(../img/sec51-back.png);
	background-position: top center;
	border-radius: 20px 20px 0 0;
	overflow: hidden;
	position: relative;
	z-index: 1;

	@media (max-width: ($md1 + px)) {
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: rgba($color: #ffffff, $alpha: 0.3);
			z-index: -1;
			@media (max-width: ($md2 + px)) {
				background-color: rgba($color: #ffffff, $alpha: 0.6);
			}
		}
	}
	@media (max-width: ($md3 + px)) {
		padding-top: 30px;
		margin-top: 40px;
		padding-bottom: 30px;
	}
	// .section-51__container

	&__container {
	}

	// .section-51__title

	&__title {
		text-align: left;
	}

	// .section-51__block

	&__block {
		margin-top: 60px;
		display: flex;
		gap: 19px;
		@media (max-width: ($md3 + px)) {
			margin-top: 20px;
		}
		@media (max-width: ($md5 + px)) {
			gap: 10px;
		}
	}

	// .section-51__icon

	&__icon {
		@media (max-width: ($md5 + px)) {
			svg {
				max-width: 35px;
			}
		}
	}

	// .section-51__description

	&__description {
		margin-top: 8px;
	}

	// .section-51__block-title

	&__block-title {
	}

	// .section-51__block-text

	&__block-text {
		font-size: 24px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(32 / 24 * 100%);
		margin-top: 15px;

		@media (max-width: ($md4 + px)) {
			font-size: 22px;
		}
		@media (max-width: ($md5 + px)) {
			font-size: 20px;
			margin-top: 10px;
		}
		@media (max-width: ($md6 + px)) {
			font-size: 18px;
		}
	}

	.section-2__credit {
		margin-top: 79px;
		@media (max-width: ($md3 + px)) {
			margin-top: 30px;
		}
	}

	& + .section-10 {
		margin-top: 0 !important;
		border-radius: 0 0 20px 20px;
	}
}

.section-52 {
	margin-top: 110px;
	@media (max-width: ($md3 + px)) {
		margin-top: 50px;
	}
	@media (max-width: ($md4 + px)) {
		.visible-hand {
			display: flex;
		}
	}
	// .section-52__container

	&__container {
	}

	// .section-52__title

	&__title {
	}

	// .section-52__items

	&__items {
		margin-top: 60px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 20px;

		@media (max-width: ($md1 + px)) {
			gap: 15px;

			br {
				display: none;
			}
		}

		@media (max-width: ($md2 + px)) {
			grid-template-columns: repeat(2, minmax(250px, 285px));
			justify-content: center;
			gap: 20px;
		}

		@media (max-width: ($md3 + px)) {
			gap: 15px;
			margin-top: 30px;
		}

		@media (max-width: ($md4 + px)) {
			grid-template-columns: repeat(4, 285px);
			justify-content: flex-start;
			gap: 10px;
			overflow: scroll;
			padding-bottom: 10px;
			margin-top: 0;
		}
	}

	// .section-52__item

	&__item {
	}

	// .section-52__item-text

	&__item-text {
		margin-top: 20px;
		text-align: center;
		@media (max-width: ($md3 + px)) {
			margin-top: 10px;
		}
	}

	// .section-52__card

	&__card {
		margin-top: 60px;
		position: relative;
		@media (max-width: ($md3 + px)) {
			margin-top: 30px;
			height: 220px;
		}
		@media (max-width: ($md4 + px)) {
			display: flex;
			flex-direction: column;
			height: auto;
		}
	}

	// .section-52__card-back

	&__card-back {
		@media (max-width: ($md3 + px)) {
			height: 100%;
			border-radius: 14px;
			filter: opacity(0.75);
		}
		@media (max-width: ($md4 + px)) {
			height: auto;
			filter: none;
		}
	}

	// .section-52__card-inner

	&__card-inner {
		position: absolute;
		top: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 89px;
		@media (max-width: ($md1 + px)) {
			padding-left: 70px;
		}
		@media (max-width: ($md2 + px)) {
			padding-left: 50px;
		}
		@media (max-width: ($md3 + px)) {
			max-width: 500px;
			padding-left: 30px;
			padding-right: 30px;
			br {
				display: none;
			}
		}
		@media (max-width: ($md4 + px)) {
			padding: 0;
			max-width: 100%;
			order: -1;
			position: static;
			margin-bottom: 15px;
		}
	}

	// .section-52__card-title

	&__card-title {
		font-size: 24px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(32 / 24 * 100%);
		@media (max-width: ($md2 + px)) {
			font-size: 22px;
		}

		@media (max-width: ($md3 + px)) {
			font-size: 20px;
		}
		@media (max-width: ($md4 + px)) {
			font-size: 18px;
		}
	}

	// .section-52__card-text

	&__card-text {
		margin-top: 13px;
		@media (max-width: ($md2 + px)) {
			font-size: 16px;
			margin-top: 8px;
		}
	}

	& + .section-credit {
		.section-2__credit {
			margin-top: 50px;
			@media (max-width: ($md3 + px)) {
				margin-top: 30px;
			}
		}
	}
}

.section-53 {
	margin-top: 30px;
	// .section-53__container

	&__container {
	}

	// .section-53__card

	&__card {
		position: relative;
		@media (max-width: ($md3 + px)) {
			height: 240px;
		}
		@media (max-width: ($md4 + px)) {
			height: auto;
			display: flex;
			flex-direction: column;
		}
	}

	// .section-53__card-inner

	&__card-inner {
		position: absolute;
		top: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 122px;

		@media (max-width: ($md1 + px)) {
			padding-left: 100px;
		}
		@media (max-width: ($md2 + px)) {
			padding-left: 50px;
		}
		@media (max-width: ($md3 + px)) {
			padding-left: 30px;
		}
		@media (max-width: ($md4 + px)) {
			padding-left: 0;
			margin-bottom: 15px;
			order: -1;
			position: static;

			br {
				display: none;
			}
		}
	}

	// .section-53__card-title

	&__card-title {
	}

	// .section-53__card-text

	&__card-text {
		margin-top: 17px;
		br {
			display: block;
		}
		@media (max-width: ($md4 + px)) {
			br {
				display: none;
			}
			margin-top: 8px;
		}
	}

	&__card-back {
		@media (max-width: ($md3 + px)) {
			filter: opacity(0.7);
			height: 100%;
			border-radius: 14px;
		}
		@media (max-width: ($md4 + px)) {
			filter: none;
			height: 100%;
			border-radius: 14px;
		}
	}
}

.section-54 {
	padding-top: 188px;
	padding-bottom: 290px;
	margin: auto;
	margin-top: 100px;
	background-size: cover;
	background-image: url(../img/sec54-back.png);
	background-position: top center;

	overflow: hidden;
	position: relative;
	z-index: 1;
	@media (max-width: ($md3 + px)) {
		background-position: left;
	}

	@media (max-width: ($md2 + px)) {
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: rgba($color: #ffffff, $alpha: 0.3);
			z-index: -1;
		}
	}
	@media (max-width: ($md3 + px)) {
		padding-top: 30px;
		margin-top: 40px;
	}
	// .section-54__container

	&__container {
	}

	// .section-54__title

	&__title {
		text-align: left;
		@media (max-width: ($md3 + px)) {
			max-width: 500px;
			br {
				display: none;
			}
		}
	}

	&__subtitle {
		text-align: left;
		margin-top: 10px;
	}
	// .section-54__block

	&__block {
		margin-top: 40px;
		display: flex;
		gap: 19px;
		@media (max-width: ($md3 + px)) {
			margin-top: 20px;
		}
		@media (max-width: ($md5 + px)) {
			gap: 10px;
		}
	}

	// .section-54__icon

	&__icon {
		@media (max-width: ($md5 + px)) {
			svg {
				max-width: 35px;
			}
		}
	}

	// .section-54__description

	&__description {
		margin-top: 8px;
	}

	// .section-54__block-title

	&__block-title {
	}

	// .section-54__block-text

	&__block-text {
		font-size: 24px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(32 / 24 * 100%);
		margin-top: 15px;

		@media (max-width: ($md4 + px)) {
			font-size: 22px;
		}
		@media (max-width: ($md5 + px)) {
			font-size: 20px;
			margin-top: 10px;
		}
		@media (max-width: ($md6 + px)) {
			font-size: 18px;
		}
	}
}

.section-55 {
	max-width: 1560px;
	margin: auto;
	margin-top: -140px;
	padding: 110px 0;
	border-radius: 40px;
	background-color: #f7f8fa;
	z-index: 2;
	position: relative;
	@media (max-width: ($md3 + px)) {
		margin-top: -240px;
		padding: 30px 0;
	}
	// .section-55__container
	@media (max-width: ($md4 + px)) {
		.visible-hand {
			display: flex;
		}
	}
	&__container {
	}

	.section-49 {
		// .section-49__title

		&__title {
			@media (max-width: ($md2 + px)) {
				br {
					display: none;
				}
			}
		}

		// .section-49__items

		&__items {
		}

		// .section-49__item

		&__item {
		}

		// .section-49__item-text

		&__item-text {
			font-size: 22px;
			font-weight: 400;
			line-height: calc(30 / 22 * 100%);
			margin-top: 25px;

			@media (max-width: ($md3 + px)) {
				margin-top: 10px;
				font-size: 19px;
				br {
					display: none;
				}
			}
		}
	}
}
.section-holod-4__wrapper {
	position: relative;
	z-index: 2;
}

.section-mirror-9__maintitle {
	@media (max-width: ($md3 + px)) {
		br {
			display: none;
		}
	}
}
.section-56 {
	padding-bottom: 176px;
	padding-top: 230px;
	background-image: url(../img/sec56-back.png);
	background-size: cover;
	background-position: top center;
	max-width: 1880px;
	border-radius: 20px;
	margin: auto;
	margin-top: -120px;
	position: relative;
	z-index: 1;
	@media (max-width: ($md2 + px)) {
		&::before {
			position: absolute;
			content: "";
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color: #000000, $alpha: 0.3);
			z-index: 0;
		}
	}
	@media (max-width: ($md3 + px)) {
		margin-top: -190px;
		padding-bottom: 30px;
	}
	// .section-56__container

	&__container {
	}

	// .section-56__title

	&__title {
		text-align: left;
		color: #fff;
		@media (max-width: ($md3 + px)) {
			text-align: center;
			br {
				display: none;
			}
		}
	}

	// .section-56__items

	&__items {
		display: flex;
		margin-top: 90px;
		align-items: flex-start;

		@media (max-width: ($md1 + px)) {
			justify-content: space-between;
		}
		@media (max-width: ($md2 + px)) {
			display: grid;
			grid-template-columns: repeat(2, auto);
			padding-right: 25px;
			gap: 70px 30px;
		}
		@media (max-width: ($md3 + px)) {
			display: grid;
			grid-template-columns: auto;
			gap: 30px;
			justify-content: center;
			margin-top: 50px;
		}
		@media (max-width: ($md4 + px)) {
			gap: 20px;
			padding-right: 10px;
			margin-top: 30px;
		}
	}

	// .section-56__item

	&__item {
		position: relative;
		border-radius: 16px;
		background-color: #fff;
		padding: 35px 33px;
		@media (max-width: ($md4 + px)) {
			padding: 25px 23px;
		}
		&:nth-of-type(1) {
			margin-right: 117px;
			@media (max-width: ($md1 + px)) {
				margin-right: 0;
			}
		}
		&:nth-of-type(2) {
			margin-right: 199px;
			margin-top: 274px;
			@media (max-width: 1225px) {
				margin-right: 170px;
			}
			@media (max-width: ($md1 + px)) {
				margin-right: 0;
			}
			@media (max-width: ($md2 + px)) {
				margin-top: 0;
			}
		}
		&:nth-of-type(3) {
			margin-top: 62px;
			@media (max-width: ($md2 + px)) {
				margin-top: 0;
				grid-column: span 2;
				justify-self: center;
				max-width: fit-content;
			}

			@media (max-width: ($md3 + px)) {
				grid-column: unset;
			}

			.section-56__circle {
				right: unset;
				left: -30px;

				@media (max-width: ($md3 + px)) {
					right: -30px;
					left: unset;
				}
				@media (max-width: ($md4 + px)) {
					right: -20px;
				}
			}
		}
	}

	// .section-56__circle

	&__circle {
		position: absolute;
		top: -30px;
		right: -30px;
		width: 60px;
		height: 60px;
		background-color: #027ef2;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		@media (max-width: ($md4 + px)) {
			width: 40px;
			height: 40px;
			top: -20px;
			right: -20px;
			svg {
				max-width: 20px;
			}
		}
	}

	// .section-56__text

	&__text {
		@media (max-width: ($md3 + px)) {
			br {
				display: block;
			}
		}
	}
}

.projects-slider {
	// .projects-slider__slidernav

	&__slidernav {
	}

	// .projects-slider__sliderfor

	&__sliderfor {
	}
}
.projects-slidernav {
	max-width: 650px;
	margin: auto;
	// .projects-slidernav__item
	.slick-slide {
		@media (max-width: ($md3 + px)) {
			padding: 0 3px;
		}
	}
	&__item {
		width: 210px;
		height: 90px;
		cursor: pointer;
		border-radius: 10px;
		border: 1px solid rgba($color: #000000, $alpha: 0.1);

		display: flex;
		align-items: center;
		justify-content: center;
		color: rgba($color: #060708, $alpha: 0.6);
		font-size: 16px;
		font-weight: 600;
		line-height: calc(22 / 16 * 100%);
		text-align: center;

		@media (max-width: ($md3 + px)) {
			width: 100%;
			max-width: 210px;
		}

		@media (max-width: ($md4 + px)) {
			height: 70px;
		}
	}

	.slick-slide.slick-current.slick-active {
		.projects-slidernav__item {
			color: #fff;
			font-weight: 700;
			box-shadow: 0 15px 30px rgba(1, 108, 208, 0.15), inset 0 0 18px 2px rgba(255, 255, 255, 0.15);
			border-radius: 10px;
			border: 1px solid rgba($color: #000000, $alpha: 0);
			background-image: linear-gradient(to top, #016cd0 0%, #027aeb 100%);
		}
	}
}
.projects-sliderfor {
	margin-top: 40px;

	// .projects-sliderfor__slide
	@media (max-width: ($md3 + px)) {
		margin-top: 20px;
	}
	&__slide {
		display: flex;
		gap: 30px;

		@media (max-width: ($md2 + px)) {
			gap: 15px;
		}

		@media (max-width: ($md3 + px)) {
			flex-direction: column;
			gap: 10px;
		}
	}

	// .projects-sliderfor__item

	&__item {
		position: relative;
		background-color: black;
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
		border-radius: 20px;
		overflow: hidden;
		padding-bottom: 33.334%;
		display: flex;
		width: 100%;
		@media (max-width: ($md3 + px)) {
			padding-bottom: 55%;
			max-width: 500px;
			margin: auto;
		}
		@media (max-width: ($md5 + px)) {
			padding-bottom: 65%;
		}
	}

	// .projects-sliderfor__project-text

	&__project-text {
		position: absolute;
		left: 30px;
		top: 30px;
		width: 195px;
		height: 44px;
		border-radius: 6px;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		font-weight: 700;
		text-align: center;
		text-transform: uppercase;
		@media (max-width: ($md4 + px)) {
			font-size: 14px;
			width: 175px;
			height: 40px;
			top: 15px;
			left: 15px;
		}
	}

	// .projects-sliderfor__result-text

	&__result-text {
		position: absolute;
		left: 30px;
		top: 30px;
		width: 144px;
		height: 44px;
		border-radius: 6px;
		background-color: #027ef2;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 16px;
		font-weight: 700;
		text-align: center;
		text-transform: uppercase;

		@media (max-width: ($md4 + px)) {
			font-size: 14px;
			width: 135px;
			height: 40px;
			top: 15px;
			left: 15px;
		}
	}
}

.reviews-slider {
	// .reviews-slider__item
	min-height: 0;
	min-width: 0;
	max-width: 930px;

	&__item {
		margin: 0 10px;
		@media (max-width: ($md2 + px)) {
			margin: 0 5px;
		}
		@media (max-width: ($md3 + px)) {
			margin: 0;
		}
	}

	// .reviews-slider__link

	&__link {
		@media (max-width: ($md3 + px)) {
			display: flex;
			justify-content: center;
		}
	}

	// .reviews-slider__arrows

	&__arrows {
	}

	// .reviews-slider__arrow

	&__arrow {
		width: 70px;
		height: 70px;
		box-shadow: 0 25px 50px rgba(1, 108, 208, 0.15), inset 0 0 18px 2px rgba(255, 255, 255, 0.15);
		background-image: linear-gradient(to top, #016cd0 0%, #027aeb 100%);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;

		@media (max-width: ($md2 + px)) {
			width: 50px;
			height: 50px;
		}

		@media (max-width: ($md5 + px)) {
			width: 35px;
			height: 35px;
			z-index: 2;
		}
	}

	// .reviews-slider__prev

	&__prev {
		@media (max-width: ($md5 + px)) {
			margin-right: -17px;
		}
	}

	// .reviews-slider__next

	&__next {
		@media (max-width: ($md5 + px)) {
			margin-left: -17px;
		}
	}

	.slick-track {
		display: flex;
		min-height: 0;
		min-width: 0;
	}

	.slick-slide {
		min-height: 0;
		min-width: 0;
	}

	.slick-dots {
		text-align: center;
		display: flex;
		justify-content: center;
		gap: 10px;
		list-style: none;
		left: 0;
		right: 0;
		position: absolute;
		margin: auto;
		margin-top: 50px;

		@media (max-width: ($md3 + px)) {
			margin-top: 20px;
		}
		@media (max-width: ($md5 + px)) {
			margin-top: 10px;
		}
	}
	.slick-dots button {
		background-color: #d7d8db;
		border: none;
		border-radius: 50%;
		height: 8px;
		width: 8px;
		font-size: 0;
	}

	.slick-dots li[class="slick-active"] button {
		background-color: #027ef2;
	}
}

.footer {
	margin-top: 55px;

	@media (max-width: ($md5 + px)) {
		margin-top: 35px;
	}
	// .footer__container

	&__container {
	}

	// .footer__top

	&__top {
	}
	.footer-top {
		&__container {
			display: flex;
			align-items: flex-start;

			@media (max-width: ($md1 + px)) {
				justify-content: space-between;
			}
		}

		padding-bottom: 60px;
		border-bottom: 1px solid rgba($color: #000000, $alpha: 0.07);
		// .footer-top__nav
		@media (max-width: ($md3 + px)) {
			padding-bottom: 30px;
		}

		&__nav {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 20px 77px;
			@media (max-width: ($md1 + px)) {
				gap: 20px;
			}

			@media (max-width: ($md2 + px)) {
				grid-template-columns: auto;
				gap: 10px;
			}
		}

		// .footer-top__nav-item

		&__nav-item {
		}

		// .footer-top__nav-link

		&__nav-link {
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(26 / 16 * 100%);
			font-weight: 400;
			text-decoration: underline;
			text-decoration-color: rgba($color: #060708, $alpha: 0.08);
			text-underline-offset: 8px;

			@media (max-width: ($md3 + px)) {
				font-size: 14px;
			}
		}

		.footer-top__wrapper {
			display: contents;

			@media (max-width: ($md5 + px)) {
				display: block;
			}
		}

		// .footer-top__service

		&__service {
			display: flex;
			flex-direction: column;
			margin-left: 76px;

			@media (max-width: ($md1 + px)) {
				margin-left: 40px;
			}

			@media (max-width: ($md3 + px)) {
				margin-left: 25px;
			}

			@media (max-width: ($md5 + px)) {
				margin-left: 0;
				margin-top: 10px;
			}
		}

		// .footer-top__sublist

		&__sublist {
			margin-top: 18px;
			display: flex;
			flex-direction: column;
			gap: 10px;
			padding-left: 12px;

			@media (max-width: ($md5 + px)) {
				display: none;
			}
		}

		&__contacts-list {
			display: flex;
			flex-direction: column;
			gap: 15px;
			margin-top: 6px;
		}
		// .footer-top__sublist-item

		&__sublist-item {
		}

		// .footer-top__sublist-link

		&__sublist-link {
			font-size: 14px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(26 / 14 * 100%);
			text-align: left;
			/* Text style for "Ремонт и о" */
			font-style: normal;
			text-decoration: underline;
			text-decoration-color: rgba($color: #060708, $alpha: 0.08);
			text-underline-offset: 8px;

			&::before {
				width: 5px;
				height: 5px;
				top: 10px;
			}
		}

		// .footer-top__contacts

		&__contacts {
			display: flex;
			flex-direction: column;
			margin-left: 102px;
			gap: 15px;

			@media (max-width: ($md1 + px)) {
				margin-left: 50px;
			}

			@media (max-width: ($md3 + px)) {
				margin-left: 25px;
			}
		}

		// .footer-top__contacts-item

		&__contacts-item {
			display: flex;
			align-items: center;
			gap: 10px;
		}

		&__icon {
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			flex-shrink: 0;

			&::before {
				color: #fff;
			}
		}

		._icon-vk {
			background-color: #0077ff;
			&::before {
				font-size: 9px;
				margin-right: 1px;
			}
		}

		._icon-whatsapp {
			background-color: #21ca60;
			&::before {
				font-size: 15px;
			}
		}
		._icon-tg {
			background-color: #00a6e7;
			&::before {
				font-size: 13px;
				margin-right: 2px;
			}
		}
	}

	.footer-bottom {
		padding-top: 30px;
		padding-bottom: 25px;
		// .footer-bottom_creator-link

		&_creator-link {
			display: flex;
			gap: 16px;
		}

		// .footer-bottom__container

		&__container {
			display: flex;
			justify-content: space-between;
			align-items: center;

			@media (max-width: ($md1 + px)) {
				align-items: flex-start;
			}

			@media (max-width: ($md5 + px)) {
				gap: 17px;
			}
		}

		// .footer-bottom__logo

		&__logo {
			display: flex;
			flex-direction: column;
			gap: 4px;

			@media (max-width: ($md2 + px)) {
				max-width: 250px;
			}

			@media (max-width: ($md4 + px)) {
				max-width: 240px;
			}
		}

		// .footer-bottom__pic

		&__pic {
		}

		// .footer-bottom__slogan

		&__slogan {
			opacity: 0.4;
			color: #060708;
			font-size: 15px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(24 / 15 * 100%);
			padding-left: 39px;
			@media (max-width: ($md2 + px)) {
				padding-left: 10px;
			}

			@media (max-width: ($md4 + px)) {
				font-size: 14px;
			}

			@media (max-width: ($md5 + px)) {
				br {
					display: none;
				}
				padding-left: 0;
				font-size: 13px;
			}

			@media (max-width: ($md6 + px)) {
				font-size: 12px;
			}
		}

		// .footer-bottom__middle

		&__middle {
			display: flex;
			gap: 55px;
			align-items: center;

			@media (max-width: ($md1 + px)) {
				gap: 20px;
				flex-direction: column;
			}

			@media (max-width: ($md3 + px)) {
				display: none;
			}
		}

		// .footer-bottom__creator

		&__creator {
		}

		// .footer-bottom__policy

		&__policy {
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			text-decoration: underline;
			text-decoration-color: rgba($color: #060708, $alpha: 0.08);
			text-underline-offset: 6px;
			margin-top: -13px;
			@media (max-width: ($md1 + px)) {
				margin-top: 0;
			}

			@media (max-width: ($md2 + px)) {
				font-size: 15px;
			}
		}

		// .footer-bottom__creator-text

		&__creator-text {
			opacity: 0.4;
			color: #060708;
			font-size: 14px;
			font-weight: 400;
			line-height: calc(24 / 14 * 100%);
			text-decoration: underline;
			text-decoration-color: rgba($color: #060708, $alpha: 0.08);
			text-underline-offset: 4px;
			margin-top: -5px;
		}

		// .footer-bottom__rightside

		&__rightside {
			display: flex;
			flex-direction: column;
			gap: 2px;
			align-items: flex-end;
			margin-top: -13px;
			@media (max-width: ($md3 + px)) {
				margin-top: -5px;
			}

			@media (max-width: ($md5 + px)) {
				flex-shrink: 0;
			}
		}

		// .footer-bottom__phone

		&__phone {
		}

		// .footer-bottom__tel

		&__tel {
			color: #060708;
			font-size: 26px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: calc(26 / 14 * 100%);
			text-align: right;
			@media (max-width: ($md2 + px)) {
				font-size: 21px;
			}

			@media (max-width: ($md4 + px)) {
				font-size: 20px;
			}

			@media (max-width: ($md5 + px)) {
				font-size: 18px;
			}

			@media (max-width: ($md6 + px)) {
				font-size: 16px;
			}
		}

		// .footer-bottom__callback

		&__callback {
		}

		// .footer-bottom__callback-btn

		&__callback-btn {
			color: #016cd0;
			font-size: 13px;
			font-weight: 700;
			text-align: right;
			text-transform: uppercase;
			letter-spacing: 0.78px;
			text-decoration: underline;
			text-decoration-color: rgba($color: #016cd0, $alpha: 0.5);
			text-underline-offset: 6px;
		}
	}
}

.section-thanks {
	background-image: url("../img/section-thx.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding-top: 260px;
	position: relative;
	color: #fff;

	@media (max-width: ($md3 + px)) {
		padding-top: 200px;
	}

	&__man {
		position: relative;
		bottom: 0;
		align-self: flex-end;
		flex-shrink: 0;
		@media (max-width: ($md1 + px)) {
			max-width: 480px;
		}
		@media (max-width: ($md2 + px)) {
			position: absolute;
			right: -120px;
			max-width: 500px;
		}
		@media (max-width: ($md3 + px)) {
			max-width: 58%;
		}
		@media (max-width: ($md4 + px)) {
			position: static;
			order: 2;
			align-self: center;
			max-width: 350px;
		}
	}
	&__subtitle {
		font-size: 28px;
		font-weight: 700;

		@media (max-width: ($md3 + px)) {
			font-size: 27px;
		}

		@media (max-width: ($md4 + px)) {
			font-size: 25px;
		}
		@media (max-width: ($md5 + px)) {
			font-size: 23px;
			line-height: 1.3;
		}
	}

	&__susubtitle {
		font-size: 22px;
		margin-top: 10px;
		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
		}
		@media (max-width: ($md4 + px)) {
			font-size: 20px;
		}
		@media (max-width: ($md5 + px)) {
			font-size: 18px;
		}
	}
	// .section-thanks__man
	&__container {
		height: 100%;
		display: flex;
		gap: 20px;

		@media (max-width: ($md3 + px)) {
			display: flex;
			flex-direction: column;
			gap: 15px;
		}
	}

	&__button {
		margin-top: 40px;
		width: 350px;
		height: 94px;
		gap: 15px;
		@media (max-width: ($md3 + px)) {
			margin-top: 20px;
		}
	}

	&__rightside {
		padding-top: 50px;

		@media (max-width: ($md1 + px)) {
			padding-bottom: 50px;
		}
		@media (max-width: ($md2 + px)) {
			max-width: 500px;
		}
		@media (max-width: ($md4 + px)) {
			padding-bottom: 0;
			max-width: 100%;
		}
	}

	&__question {
		margin-top: 50px;
		font-size: 20px;

		@media (max-width: ($md3 + px)) {
			margin-top: 20px;
		}

		._ellipse {
			border-color: rgba($color: #ffffff, $alpha: 0.1);
			padding: 5px 10px;
			@media (max-width: ($md1 + px)) {
				display: block;
				width: fit-content;
				margin-top: 5px;
			}
			@media (max-width: ($md6 + px)) {
				padding: 5px 8px;
			}
		}
	}

	&__phone {
		font-size: 40px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: 1.4;
		margin-top: 30px;
		margin-bottom: 32px;
		display: block;

		@media (max-width: ($md3 + px)) {
			margin: 15px;
			font-size: 35px;
		}
		@media (max-width: ($md4 + px)) {
			font-size: 30px;
		}
		@media (max-width: ($md5 + px)) {
			font-size: 28px;
		}
	}

	&__respect {
		font-size: 18px;
		line-height: calc(28 / 18 * 100%);
	}
}

.section-mirror-1 {
	margin-top: 156px;
	@media (max-width: 768.98px) {
		margin-top: 50px;
	}
	.overflow {
		// .overflow__container

		&__container {
			@media (max-width: 992.98px) {
				max-width: 100%;
				padding: 0;
				.coursor {
					display: flex;
				}
			}
		}
	}
	// .section-mirror-1__container

	&__container {
	}

	// .section-mirror-1__title

	&__title {
	}

	// .section-mirror-1__block

	&__block {
		margin-top: 56px;
		@media (max-width: 992.98px) {
			margin-top: 0;
		}
	}

	// .section-mirror-1__pref

	&__pref {
	}
}

.three-block {
	// .three-block__wrapper

	&__wrapper {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;
		@media (max-width: 1213.98px) {
			gap: 10px;
		}
		@media (max-width: 992.98px) {
			grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
			grid-auto-flow: column;
			overflow-x: scroll;
			padding: 0 15px;
		}
	}

	// .three-block__item

	&__item {
		position: relative;
		min-width: 280px;
	}

	// .three-block__pic

	&__pic {
	}
	// .three-block__info

	&__info {
		position: absolute;
		inset: 0 0 auto;
		padding: 50px 50px;
		@media (max-width: 1213.98px) {
			padding: 20px;
		}
		@media (max-width: 992.98px) {
			padding: 20px 10px;
		}
	}
	// .three-block__title

	&__title {
		font-size: 1.5rem;
		line-height: 133.333333%; /* 32/24 */
		font-weight: 700;
		@media (max-width: 992.98px) {
			font-size: 20px;
		}
	}

	// .three-block__subtitle

	&__subtitle {
		margin-top: 10px;
		font-size: 1.125rem;
		line-height: 155.555556%; /* 28/18 */
		@media (max-width: 992.98px) {
			font-size: 1rem;
		}
	}
}
.pref-block {
	margin-top: 30px;
	max-width: 1028px;
	border-radius: 14px;
	background-color: #f7f8fa;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 40px 0;
	@media (max-width: 992.98px) {
		display: grid;
		justify-content: center;
		gap: 20px;
		padding: 20px 0;
	}
	// .pref-block__item

	&__item {
		display: grid;
		grid-template-columns: 51px 1fr;
		gap: 15px;
	}

	// .pref-block__img

	&__img {
	}

	// .pref-block__text

	&__text {
		align-self: center;
		font-size: 1.125rem;
		line-height: 155.555556%; /* 28/18 */
	}
}

.modal-content {
	border: none;
	border-radius: 10px;
	position: relative;
}

.modal-dialog {
	@media (max-width: ($md3 + px)) {
		margin-left: auto;
		margin-right: auto;
	}
}

.modal-content .close {
	position: absolute;
	right: -25px;
	top: 0px;
	z-index: 10;
	opacity: 1;
	@media (max-width: ($md3 + px)) {
		right: 15px;
		top: 15px;
	}
}

.modal-body {
	border-radius: 10px;
	padding: 0;
}

.catalog-modal {
	// .catalog-modal__dialog

	&__dialog {
		max-width: 800px;

		@media (max-width: ($md2 + px)) {
			max-width: 750px;
		}
		@media (max-width: ($md3 + px)) {
			max-width: 450px;
		}
	}

	// .catalog-modal__content

	&__content {
	}

	// .catalog-modal__body

	&__body {
	}

	// .catalog-modal__container

	&__container {
	}

	.policy {
		margin-left: 0px;
	}

	.capture-form__title {
		br {
			display: none;
		}
		max-width: none;
	}

	.capture-form__block {
		padding: 40px;

		@media (max-width: ($md3 + px)) {
			padding: 30px;
		}

		@media (max-width: ($md5 + px)) {
			padding: 20px;
		}
	}
}

.quiz-modal {
	&__dialog {
		max-width: 1300px;
		@media (max-width: ($md1 + px)) {
			max-width: 1000px;
		}
		@media (max-width: ($md2 + px)) {
			max-width: 800px;
		}
		@media (max-width: ($md3 + px)) {
			max-width: 95%;
		}
		@media (max-width: ($md6 + px)) {
			max-width: 100%;
		}
	}

	.modal-content .close {
		@media (max-width: 1350px) {
			right: 15px;
			top: 15px;
		}
	}
	.quiz__wrapper {
		padding: 0;
	}
	.section-5__title,
	.section-5__subtitle {
		color: #000;
	}

	.section-5__subtitle {
		margin-bottom: 20px;
		margin-top: 10px;
	}

	.section-5__quiz {
		margin-top: 0;

		._container {
			padding: 0;
		}
	}

	&__container {
		padding: 50px;
		@media (max-width: 1300px) {
			padding: 50px 30px;
		}
		@media (max-width: ($md3 + px)) {
			padding: 30px;
		}
		@media (max-width: ($md4 + px)) {
			padding: 30px 20px;
		}
		@media (max-width: ($md5 + px)) {
			padding: 30px 15px;
		}
	}

	.progress-text {
		@media (max-width: ($md6 + px)) {
			font-size: 15px;
		}
	}
	.section-5__title,
	.section-5__subtitle {
		display: none;
	}

	.section-5__subtitle {
		@media (max-width: ($md4 + px)) {
			font-size: 20px;

			._icon-sale::before {
				@media (max-width: ($md5 + px)) {
					font-size: 25px;
				}
				@media (max-width: ($md6 + px)) {
					font-size: 20px;
				}
			}

			@media (max-width: ($md6 + px)) {
				font-size: 16px;
			}
		}
	}
	.section-5__title {
		@media (max-width: ($md3 + px)) {
			font-size: 30px;
		}
		@media (max-width: ($md4 + px)) {
			font-size: 25px;
		}
		@media (max-width: ($md5 + px)) {
			font-size: 22px;
		}
		@media (max-width: ($md6 + px)) {
			font-size: 19px;
		}
	}
	.quiz-beginning {
		.section-5__title,
		.section-5__subtitle {
			color: #000;
			display: block;
		}
	}
}

#consultation,
#catalog-akcii,
#consultation-akcii {
	.catalog-modal__dialog {
		max-width: 490px;
		width: 95%;

		@media (max-width: ($md3 + px)) {
			max-width: 400px;
		}
	}
	.section-16__form-title {
		@media (max-width: ($md3 + px)) {
			font-size: 24px;
		}

		@media (max-width: ($md5 + px)) {
			font-size: 22px;
		}
		@media (max-width: ($md6 + px)) {
			font-size: 21px;
		}
	}

	.section-16__form-subtitle {
		@media (max-width: ($md5 + px)) {
			font-size: 19px;
		}
		@media (max-width: ($md6 + px)) {
			font-size: 18px;
		}
	}
	.section-16__formblock {
		margin: 0;
		position: relative;
		max-width: 490px;
		@media (max-width: ($md3 + px)) {
			padding-top: 30px !important;
			padding: 20px;
		}
		@media (max-width: ($md6 + px)) {
			padding: 15px;
		}
	}
}
.callback-modal {
	// .callback-modal__dialog

	&__dialog {
		max-width: 450px;
	}

	// .callback-modal__content

	&__content {
	}

	.capture-form__subtitle {
		color: #000000;
		font-size: 22px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: 32px;

		margin-top: 10px;
	}

	.capture-form__title {
		max-width: none;
		font-size: 35px;
		br {
			display: block !important;
		}
	}

	.capture-form__block {
		padding: 35px;

		@media (max-width: ($md3 + px)) {
			padding: 30px;
		}

		@media (max-width: ($md5 + px)) {
			padding: 20px;
			padding-top: 30px;
		}
	}

	.capture-form__inner {
		flex-direction: column;
	}

	.capture-form__button,
	.capture-form__phone {
		width: 100%;
	}

	.policy {
		margin-left: 0px;
	}

	.capture-form__form {
		margin-top: 20px;
	}
	// .callback-modal__body

	&__body {
	}

	// .callback-modal__container

	&__container {
	}
}

#callback {
	.callback-modal__container {
		display: flex;
	}

	.modal-content .close {
		@media (max-width: ($md2 + px)) {
			top: 15px;
			right: 15px;
		}
	}

	.callback-modal__lefside {
		background-image: url(../img/callback-woman.jpg);
		background-position: top center;
		background-size: cover;
		min-width: 397px;
		background-repeat: no-repeat;
		position: relative;
		border-radius: 10px 0 0 10px;
		@media (max-width: ($md2 + px)) {
			min-width: 300px;
		}
		@media (max-width: ($md3 + px)) {
			display: none;
		}
	}
	.callback-modal__leftside-text {
		position: absolute;
		bottom: 50px;
		left: 20px;
	}

	.callback-modal__name {
		color: #ffffff;
		font-size: 18px;
		font-weight: 700;
		text-align: center;
		width: 198px;
		height: 42px;
		background-image: url(../img/blue-plashka.png);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
		position: relative;
	}

	.callback-modal__manager {
		color: rgb(7 8 9 / 60%);
		font-size: 14px;
		font-weight: 400;
		background-image: url(../img/white-plashka.png);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 198px;
		height: 42px;
		margin-top: -5px;
		margin-left: 20px;
		z-index: 1;
		position: relative;
	}
	.capture-form__title {
		@media (max-width: ($md5 + px)) {
			font-size: 30px;
		}
	}

	.capture-form__block {
		@media (max-width: ($md6 + px)) {
			padding: 15px;
			padding-top: 30px;
		}
	}
	.capture-form__subtitle {
		@media (max-width: ($md5 + px)) {
			font-size: 20px;
			br {
				display: none;
			}
		}
	}
	.callback-modal__dialog {
		max-width: 868px;
		@media (max-width: ($md2 + px)) {
			max-width: 780px;
		}
		@media (max-width: ($md3 + px)) {
			max-width: 450px;
		}
		@media (max-width: ($md5 + px)) {
			max-width: 95%;
		}
	}

	.capture-form__label {
		margin-bottom: 15px;
	}
}

.policy-modal {
	// .policy-modal__dialog

	&__dialog {
		max-width: 700px;
	}

	// .policy-modal__content

	&__content {
	}

	// .policy-modal__body

	&__body {
		padding: 30px;
		padding-top: 40px !important;

		@media (max-width: ($md2 + px)) {
			padding: 20px;
		}

		@media (max-width: ($md5 + px)) {
			padding: 15px;
		}
	}

	// .policy-modal__title

	&__title {
		font-size: 25px;
		text-align: center;
	}

	// .policy-modal__text

	&__text {
		margin-top: 20px;
		font-size: 16px;
		line-height: 1.4;
	}
}

.showroom-modal {
	&__dialog {
		max-width: 800px;
		@media (max-width: ($md2 + px)) {
			max-width: 700px;
		}
		@media (max-width: ($md3 + px)) {
			max-width: 550px;
		}
		@media (max-width: ($md4 + px)) {
			max-width: 400px;
		}
	}

	.section-21__formblock-title {
		@media (max-width: ($md5 + px)) {
			font-size: 21px;
		}
	}
	@media (max-width: ($md2 + px)) {
		.modal-content .close {
			@media (max-width: ($md2 + px)) {
				top: 15px;
				right: 15px;
			}
		}

		.section-21__formblock-title {
			br {
				display: none;
			}
		}
	}

	.section-21__addresses {
		@media (max-width: ($md3 + px)) {
			gap: 35px;
		}
		@media (max-width: ($md4 + px)) {
			gap: 15px;
			margin-top: 20px;
			display: grid;
		}
	}

	&__container {
		padding: 50px;
		@media (max-width: ($md2 + px)) {
			padding: 40px 30px;
		}
		@media (max-width: ($md3 + px)) {
			padding: 30px 20px;
		}
		@media (max-width: ($md5 + px)) {
			padding: 30px 15px;
		}
	}

	.section-21__formblock {
		padding: 0;
	}
}

#conditions {
	.callback-modal__dialog {
		max-width: 480px;
	}
	.capture-form__title {
		font-size: 30px;
		@media (max-width: ($md5 + px)) {
			font-size: 25px;
		}

		@media (max-width: ($md6 + px)) {
			font-size: 21px;
		}
	}

	.capture-form__label {
		margin: 15px 0;
	}

	.capture-form__button {
		margin-top: 20px;
	}

	.capture-form__subtitle {
		@media (max-width: ($md5 + px)) {
			font-size: 20px;
		}
		@media (max-width: ($md6 + px)) {
			font-size: 18px;
		}
	}

	.capture-form__block {
		@media (max-width: ($md6 + px)) {
			padding: 15px;
			padding-top: 30px;
		}
	}

	.capture-form__form {
		@media (max-width: ($md4 + px)) {
			margin-top: 10px;
		}
	}
}

#address {
	.address-modal__dialog {
		max-width: 1200px;
	}
	.address-modal__container {
		padding: 50px 30px 0 30px;

		@media (max-width: ($md4 + px)) {
			padding: 30px 20px 0 20px;
		}
		@media (max-width: ($md6 + px)) {
			padding: 30px 15px 0px 15px;
		}
	}
	.modal-body {
		overflow: hidden;
	}
	#map2 {
		height: 800px;
		width: 100%;

		@media (max-width: ($md2 + px)) {
			height: 500px;
		}

		@media (max-width: ($md4 + px)) {
			height: 400px;
		}
	}

	.modal-content .close {
		@media (max-width: 1270px) {
			top: 15px;
			right: 15px;
		}
	}
}

//credit

#credit .modal-dialog {
	max-width: 1290px;

	@media (max-width: ($md2 + px)) {
		padding-top: 20px;
	}

	.modal-content .close {
		@media (max-width: 1360px) {
			top: 15px;
			right: 15px;
		}
	}
}
#credit .modal-content {
	border: none;
	padding: 100px 90px;
}
.credit-block {
	position: relative;
}
.credit-block__text {
}
.credit-block__main-title {
	font-size: 36px;
	line-height: calc(50 / 36 * 100%);
	font-weight: 900;
}
.credit-block__main-subtitle {
	margin-top: 25px;
	font-size: 18px;
	line-height: calc(30 / 18 * 100%);
}
.credit-block__main {
	margin-top: 30px;
}
.credit-block__title {
	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;
}
.credit-block__list {
	margin-top: 30px;
}
.credit-block__list-title {
	margin-top: 36px;
	margin-bottom: 15px;
	width: 297px;
	height: 36px;
	border-radius: 7px;
	border: 1px solid #0000001a;
	font-size: 16px;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
}
.credit-block__list-item {
	padding-left: 22px;
	font-size: 15px;
	font-weight: 500;
	line-height: calc(32 / 15 * 100%);
	position: relative;
}
.credit-block__list-item:not(:last-child) {
	margin-bottom: 10px;
}
.credit-block__list-item:before {
	content: "";
	width: 10px;
	height: 10px;
	background-image: linear-gradient(to top, #337bda 0%, #4896ff 100%);
	margin-right: 10px;
	position: absolute;
	top: 11px;
	left: 0;
	border-radius: 0;
}
.credit-block__pic {
	position: absolute;
	top: 0;
	left: 560px;
}
.credit-block__pic img {
	max-width: unset;
}
.credit-inner {
	margin-top: 60px;
}
.credit-inner__main-title {
	text-align: center;
	font-size: 36px;
	font-weight: 900;
	line-height: calc(50 / 36 * 100%);
}
.credit-inner__block {
	margin-top: 40px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 30px;
}
.credit-inner__column {
	width: 100%;
	height: 228px;
	border-radius: 7px;
	background-color: #ffe816;
	padding: 55px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.credit-inner__title {
	font-size: 18px;
	font-weight: 700;
	line-height: calc(26 / 18 * 100%);
	text-align: center;
}
.credit-inner__subtitle {
	margin-top: 45px;
	font-size: 18px;
	line-height: calc(26 / 18 * 100%);
	text-align: center;
}

@media (max-width: 1200px) {
	.credit-inner__block {
		gap: 15px;
	}
}
@media (max-width: 992px) {
	.credit-block__main-title br {
		display: none;
	}
	.credit-block__pic {
		position: unset;
		margin-top: 20px;
	}
	.credit-block__pic img {
		max-width: 400px;
		text-align: center;
		margin: 0 auto;
	}
	.credit-block__list-title {
		margin-top: 0px;
		margin-bottom: 5px;
	}
	.credit-block__list-item:not(:last-child) {
		margin-bottom: 0;
	}
}
@media (max-width: 768px) {
	.credit-block br {
		display: none;
	}
	.section-contact {
		padding-bottom: 50px;
	}
	#credit .modal-content {
		border: none;
		padding: 40px 20px;
	}
	.credit-block__main-title {
		font-size: 27px;
	}
	.credit-block__main-subtitle {
		margin-top: 10px;
	}
	.credit-inner__main-title {
		text-align: center;
		font-size: 27px;
	}
	.credit-inner {
		margin-top: 30px;
	}
}
@media (max-width: 580px) {
	.credit-inner__column {
		width: 100%;
		height: auto;
		padding: 15px 0;
	}
	.credit-inner__subtitle {
		margin-top: 10px;
	}
	.credit-inner__block {
		grid-template-columns: 1fr;
	}

	.credit-block__pic {
		text-align: center;
	}
}
@media (max-width: 460px) {
	.credit-block__pic img {
		max-width: 220px;
		text-align: center;
		margin: 0 auto;
	}
}

//credit

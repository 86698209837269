//atomic===============================================
._align-items-start {
	align-items: flex-start !important;
}
._p-0 {
	padding: 0;
}

._center {
	text-align: center;
}

._relative {
	position: relative;
}
._absolute {
	position: absolute;
}

._white-block {
	color: #fff !important;
}
._white-text {
}
//atomic===============================================

.visible-hand {
	display: none;
	justify-content: center;
	margin: 15px 0;
}

._title {
	font-size: 54px;
	font-weight: 700;
	line-height: calc(66 / 54 * 100%);

	@media (max-width: ($md2 + px)) {
		font-size: 48px;
	}
	@media (max-width: ($md3 + px)) {
		font-size: 40px;
	}

	@media (max-width: ($md4 + px)) {
		font-size: 35px;
	}

	@media (max-width: ($md5 + px)) {
		font-size: 30px;
		br {
			display: none;
		}
	}

	@media (max-width: ($md6 + px)) {
		font-size: 28px;
	}
}

._subtitle {
	font-size: 36px;
	font-weight: 400;
	line-height: calc(54 / 36 * 100%);
	letter-spacing: 0.36px;
	margin-top: 22px;

	@media (max-width: ($md2 + px)) {
		font-size: 34px;
	}

	@media (max-width: ($md3 + px)) {
		font-size: 30px;
	}

	@media (max-width: ($md4 + px)) {
		margin-top: 15px;
		font-size: 24px;
		br {
			display: none;
		}
	}

	@media (max-width: ($md5 + px)) {
		font-size: 22px;
	}
}

._ellipse {
	border-radius: 33px;
	border: 1px solid rgba(255, 255, 255, 0.2);
	padding: 7px 29px 7px 25px;
	@media (max-width: ($md3 + px)) {
		padding: 3px 10px 4px 10px;
	}

	@media (max-width: ($md4 + px)) {
		padding: 3px 3px 4px 9px;
		margin-right: 5px;
	}
}

._section-title {
	font-size: 44px;
	font-style: normal;
	letter-spacing: normal;
	line-height: calc(54 / 44 * 100%);
	text-align: center;

	@media (max-width: ($md2 + px)) {
		font-size: 40px;
	}

	@media (max-width: ($md3 + px)) {
		font-size: 35px;
	}

	@media (max-width: ($md4 + px)) {
		font-size: 30px;
	}

	@media (max-width: ($md5 + px)) {
		font-size: 27px;
	}

	@media (max-width: ($md6 + px)) {
		font-size: 25px;
	}
}

._blue-icon {
	&::before {
		color: #027ef2;
	}
}

._blue-marker {
	padding-left: 15px;
}

._blue-marker::before {
	content: "";
	width: 6px;
	height: 6px;
	background-color: #027ef2;
	margin-right: 13px;
	position: absolute;
	top: 11.5px;
	left: 0;
	border-radius: 100%;

	@media (max-width: ($md3 + px)) {
		top: 10px;
	}
}

._white-marker::before {
	content: "";
	width: 6px;
	height: 6px;
	background-color: #ffffff;
	margin-right: 10px;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	left: 0;
	border-radius: 100%;
}

._section-subtitle {
	font-size: 26px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: calc(36 / 26 * 100%);
	text-align: center;

	@media (max-width: ($md3 + px)) {
		font-size: 23px;
	}
	@media (max-width: ($md5 + px)) {
		font-size: 21px;
	}

	@media (max-width: ($md5 + px)) {
		font-size: 19px;
	}
}

._main-text {
	font-size: 18px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: calc(28 / 18 * 100%);
	@media (max-width: ($md3 + px)) {
		font-size: 16px;
		br {
			display: none;
		}
	}
}

._main-title {
	font-size: 32px;
	font-weight: 700;
	line-height: calc(42 / 32 * 100%);

	@media (max-width: ($md2 + px)) {
		font-size: 30px;
	}

	@media (max-width: ($md3 + px)) {
		font-size: 28px;
	}
	@media (max-width: ($md4 + px)) {
		font-size: 25px;
	}

	@media (max-width: ($md5 + px)) {
		font-size: 23px;
	}

	@media (max-width: ($md6 + px)) {
		font-size: 21px;
	}
}

._main-btn {
	box-shadow: 0 25px 50px rgba(1, 108, 208, 0.15), inset 0 0 18px 2px rgba(255, 255, 255, 0.15);
	border-radius: 10px;
	background-image: linear-gradient(to top, #016cd0 0%, #027aeb 100%);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
	z-index: 1;
	transition: all 0.4s;
	position: relative;
	color: #ffffff;
	font-size: 16px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: normal;
	line-height: 24px;
	text-align: center;

	&::after {
		position: absolute;
		content: "";
		transition: all 0.4s;
		opacity: 0;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 10px;
		z-index: -1;
	}

	&:hover::after {
		opacity: 1;
		transition: all 0.4s;
	}

	@media (max-width: ($md5 + px)) {
		width: 100% !important;
		max-height: 84px;
	}

	@media (max-width: ($md6 + px)) {
		font-size: 15px;
	}
}

._second-btn {
	box-shadow: inset 0 -3px 5px rgba(255, 255, 255, 0.05), inset 0 0 10px rgba(255, 255, 255, 0.1);
	border-radius: 10px;
	background-image: linear-gradient(to top, #cfdef3 0%, #e0eafc 100%);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.9);
	color: #454c56;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;

	@media (max-width: ($md5 + px)) {
		max-height: 84px;
		width: 100% !important;
	}
}

.capture-form {
	z-index: 1;
	position: relative;
	box-shadow: 0 30px 80px rgba(0, 0, 0, 0.04);
	border-radius: 10px;
	background-color: #ffffff;

	max-width: 510px;

	// .capture-form__block

	&__block {
		padding: 47px 50px 58px 50px;
		@media (max-width: ($md4 + px)) {
			padding: 30px;
		}

		@media (max-width: ($md5 + px)) {
			padding: 20px;
		}

		@media (max-width: ($md6 + px)) {
			padding: 15px;
		}
	}

	// .capture-form__title

	&__title {
		font-size: 32px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(42 / 32 * 100%);

		@media (max-width: ($md3 + px)) {
			font-size: 30px;
		}

		@media (max-width: ($md4 + px)) {
			font-size: 28px;
		}

		@media (max-width: ($md5 + px)) {
			font-size: 24px;
		}
	}

	// .capture-form__subtitle

	&__subtitle {
		font-size: 23.7px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(32 / 24 * 100%);
		margin-top: 14px;

		@media (max-width: ($md3 + px)) {
			font-size: 21px;
		}

		@media (max-width: ($md5 + px)) {
			font-size: 18px;
			margin-top: 10px;
		}
	}

	// .capture-form__form

	&__form {
	}

	// .capture-form__discount

	&__discount {
		font-size: 20px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(30 / 20 * 100%);
		border-radius: 12px;
		border: 1px dotted rgba(0, 0, 0, 0.3);
		position: relative;
		padding: 14px 31px;
		margin-top: 23px;

		@media (max-width: ($md5 + px)) {
			margin-top: 17px;
		}

		b,
		strong {
			font-weight: 700;
		}

		@media (max-width: ($md5 + px)) {
			font-size: 17px;
			padding: 14px 18px;
		}

		@media (max-width: ($md6 + px)) {
			font-size: 15.5px;
		}
	}

	// .capture-form__cube

	&__cube {
		position: absolute;
		top: -10px;
		right: -67px;

		@media (max-width: ($md2 + px)) {
			max-width: unset;
		}
		@media (max-width: ($md4 + px)) {
			max-width: 29%;
			right: -45px;
			top: -5px;
		}

		@media (max-width: ($md6 + px)) {
			display: none;
		}
	}

	// .capture-form__inner

	&__field {
		display: flex;
		flex-direction: column;
		margin-top: 31px;
		gap: 13px;

		@media (max-width: ($md4 + px)) {
			margin-top: 20px;
		}
	}

	&__label {
		opacity: 0.8;
		font-size: 14px;
		font-weight: 700;
		text-align: left;
		text-transform: uppercase;
		letter-spacing: 0.28px;
	}
	// .capture-form__phone

	&__phone {
		height: 98px;
	}

	// .capture-form__button

	&__button {
		width: 100%;
		margin-top: 10px;
		height: 94px;
	}

	// .capture-form__policy

	&__policy {
	}

	&__footer {
		background-color: #f2f4fa;
		border-radius: 0 0 10px 10px;
		height: 66px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;

		@media (max-width: ($md5 + px)) {
			gap: 5px;
		}
		@media (max-width: ($md6 + px)) {
			flex-direction: column;
		}
	}
	&__telephone-text {
		font-size: 20px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		&::before {
			margin-right: 14px;
			font-size: 21px;

			@media (max-width: ($md5 + px)) {
				margin-right: 7px;
				font-size: 18px;
			}
		}

		@media (max-width: ($md5 + px)) {
			font-size: 18px;
		}
	}
	// .capture-form__telephone

	&__telephone {
		font-size: 24px;
		font-weight: 700;
		@media (max-width: ($md4 + px)) {
			font-size: 22px;
		}
		@media (max-width: ($md5 + px)) {
			font-size: 18px;
		}
	}
}

.policy {
	margin-top: 24px;
	@media (max-width: ($md4 + px)) {
		margin-top: 13px;
	}
	// .policy__checkbox

	&__checkbox {
		position: absolute;
		z-index: -1;
		opacity: 0;
		display: block;
		width: 0;
		height: 0;
	}

	// .policy__label

	&__label {
		position: relative;
		cursor: pointer;
		font-size: 13px;
		font-weight: 400;
		color: #a8a8a8;
		padding-left: 45px;

		&::before {
			content: "";
			width: 16px;
			height: 16px;
			border-radius: 3px;

			border: 1px solid #000000;
			opacity: 0.1;
			box-sizing: border-box;
			position: absolute;
			left: 21px;
			top: 0;
			bottom: 0;
			margin: auto;
			@media (max-width: ($md4 + px)) {
				left: 0;
			}
			@media (max-width: ($md5 + px)) {
				margin: unset;
			}
		}

		&::after {
			width: 10px;
			height: 7px;
			box-sizing: border-box;
			background-image: url(../img/form-check.png);
			background-size: contain;
			content: "";
			position: absolute;
			left: 24px;
			top: 0;
			bottom: 0;
			margin: auto;
			opacity: 0;
			transition: all 0.1s linear;
			background-repeat: no-repeat;
			background-position: center;
			@media (max-width: ($md4 + px)) {
				left: 3px;
			}
			@media (max-width: ($md5 + px)) {
				margin: unset;
				top: 5px;
			}
		}

		@media (max-width: ($md4 + px)) {
			padding-left: 20px;
		}
		@media (max-width: ($md5 + px)) {
			line-height: 1.5;
		}
	}

	// .policy__link

	&__link {
		text-decoration: underline;
		text-underline-offset: 4px;
		text-decoration-thickness: 1px;
		color: #027ef2;
		text-decoration-color: rgba(2, 122, 242, 0.3);
	}
}

._form-control {
	border-radius: 10px;
	border: 1px solid #f1f1f1;
	background-color: #fafbfc;
	color: #070809;
	font-size: 18px;
	font-weight: 400;
	text-align: left;
	padding-left: 40px;
	@media (max-width: ($md5 + px)) {
		width: 100% !important;
		max-height: 84px;
	}
}

._form-control::placeholder {
	color: rgba(7, 8, 9, 0.5);
}

.hidden-br {
	display: none;
}

.policy__checkbox:checked + .policy__label::after {
	opacity: 1;
	transition: all 0.1s linear;
}

.was-validated ._form-control:invalid {
	border: 1px solid #ff314e;
}

.was-validated ._form-control:invalid::placeholder {
	color: #ff6c34;
}

.quiz__wrapper {
	border-radius: 14px;
	background-color: #ffffff;
	width: 100%;
	padding: 55px 0 60px 0px;

	@media (max-width: ($md2 + px)) {
		padding-left: 50px;
		padding-right: 50px;
	}
	@media (max-width: ($md3 + px)) {
		padding: 30px 0;
	}

	@media (max-width: ($md5 + px)) {
		padding: 20px 0px;
	}
}

.progress-bar {
	max-width: 600px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;

	@media (max-width: ($md3 + px)) {
		flex-direction: column;
		gap: 10px;
	}
}

.progress-text {
	color: #000;
	font-size: 16px;
	font-weight: 400;
	line-height: calc(24 / 16 * 100%);
}
.current-step {
}
.progress-line {
	width: 100%;

	min-height: 14px;
	border-radius: 7px;
	background-color: #f7f7f9;
	position: relative;
	z-index: 2;
	overflow: hidden;
}
.line {
	left: -20%;
	height: 14px;
	border-radius: 7px;
	position: absolute;
	left: 0;
	top: 0;
	transition: all 0.2s linear;
	background-color: #027ef2;
	width: 100%;
	top: 0;
}

.line:after {
	height: 100%;
	background-image: -webkit-linear-gradient(
		135deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
	background-image: -o-linear-gradient(
		135deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
	background-image: linear-gradient(
		-45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
	-webkit-background-size: 22px 22px;
	background-size: 22px 22px;
	text-align: center;
	line-height: 20px;
	-webkit-animation: progress-bar-stripes 7s linear infinite;
	animation: progress-bar-stripes 7s linear infinite;
	-webkit-transition: width 0.3s;
	-o-transition: width 0.3s;
	transition: width 0.3s;
	content: "";
	position: absolute;
	width: 100%;
	left: 0;
}
@keyframes progress-bar-stripes {
	0% {
		background-position: 25px 25px;
	}
	100% {
		background-position: 100px 100px;
	}
}

.next-step {
	width: 300px;
	height: 84px;
	margin: auto;
}

.step {
	// .step__block
	color: #000;
	display: none;
	margin-top: 38px;
	&.active {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
	}
	.step__block {
	}

	@media (max-width: ($md3 + px)) {
		margin-top: 30px;
	}
	@media (max-width: ($md4 + px)) {
		margin-top: 30px;
	}
	// .step__title

	.step__title {
		font-size: 32px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(42 / 32 * 100%);

		b {
			font-weight: 700;
		}
		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
		}

		@media (max-width: ($md2 + px)) {
			font-size: 30px;
		}
		@media (max-width: ($md3 + px)) {
			font-size: 26px;
		}
		@media (max-width: ($md4 + px)) {
			font-size: 22px;
		}
		@media (max-width: ($md5 + px)) {
			font-size: 20px;
		}
	}

	// .step__variants

	.step__variants {
	}

	// .step__buttons

	.step__buttons {
		justify-self: flex-end;
		margin-top: 58px;
		.main-btn {
			margin: 0 auto;
		}

		@media (max-width: ($md3 + px)) {
			margin-top: 40px;
			margin-bottom: 0;
		}

		@media (max-width: ($md4 + px)) {
			margin-top: 25px;
		}
	}
}
.quiz-variants {
	// .quiz-variants__variant
	display: grid;
	margin-top: 32px;
	justify-content: flex-start;

	@media (max-width: ($md3 + px)) {
		margin-top: 25px;
	}

	.quiz-variants__variant {
		input {
			display: none;
		}
	}

	&__variant {
		&.active22 {
			.quiz-question__picture::before,
			.quiz-question__label::before {
				box-shadow: 0 10px 30px rgba(1, 108, 208, 0.2), inset 0 0 18px 2px rgba(255, 255, 255, 0.15);
				background: url(../img/quiz-check-active.svg) center center no-repeat;
				opacity: 1;
			}
		}
	}
	// .quiz-variants__label

	&__label {
		font-size: 18px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(28 / 18 * 100%);

		height: 100%;
		cursor: pointer;
		text-align: center;
		&:hover {
			font-weight: 600;
		}
		&:hover .quiz-question__picture::before,
		&:hover .quiz-question__label::before {
			box-shadow: 0 10px 30px rgba(1, 108, 208, 0.2), inset 0 0 18px 2px rgba(255, 255, 255, 0.15);
			background: url(../img/quiz-check-active.svg) center center no-repeat;
			opacity: 1;
		}

		@media (max-width: ($md3 + px)) {
			align-items: center;
		}

		@media (max-width: ($md4 + px)) {
			margin: 0;
			font-size: 16px;
		}
	}

	// .quiz-variants__question

	&__question {
	}

	// .quiz-variants__btn

	&__btn {
	}

	// .quiz-variants__skip

	&__skip {
	}
}
.quiz-question {
	// .quiz-question__picture

	&__picture {
		position: relative;
		&::before {
			width: 60px;
			height: 60px;
			background-image: url(../img/quiz-check.svg);
			box-shadow: 0 10px 30px rgba(48, 49, 85, 0.08);
			background-size: cover;
			border-radius: 50%;
			position: absolute;
			content: "";
			bottom: -30px;
			left: 0;
			right: 0;
			margin: auto;

			@media (max-width: ($md3 + px)) {
				display: none;
			}
		}
	}

	// .quiz-question__label

	&__label {
	}
}

.step1,
.step2 {
	.quiz-variants {
		grid-template-columns: repeat(4, auto);
		justify-content: space-between;
		align-items: start;
		row-gap: 35px;

		@media (max-width: ($md1 + px)) {
			br {
				display: none;
			}
			grid-template-columns: repeat(3, 1fr);
			gap: 35px 10px;
		}

		@media (max-width: ($md2 + px)) {
			row-gap: 40px;
		}

		@media (max-width: ($md3 + px)) {
			grid-template-columns: repeat(3, 1fr);
			justify-content: center;
			gap: 20px 15px;
		}

		@media (max-width: ($md4 + px)) {
			grid-template-columns: repeat(2, 1fr);
			gap: 20px 10px;
		}

		@media (max-width: ($md5 + px)) {
		}
	}

	.quiz-question {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 45px;

		@media (max-width: ($md3 + px)) {
			gap: 10px;
		}
	}

	.quiz-question__picture {
		@media (max-width: ($md3 + px)) {
			&::before {
				align-items: center;
			}
		}
	}

	.quiz-question__label {
		text-align: left;

		@media (max-width: ($md1 + px)) {
			text-align: center;
			max-width: 190px;
		}
	}
}

.step3 {
	.quiz-variants {
		grid-template-columns: repeat(4, 1fr);
		align-items: start;
		gap: 20px;

		@media (max-width: ($md1 + px)) {
			gap: 10px;
		}

		@media (max-width: ($md2 + px)) {
			grid-template-columns: repeat(2, 1fr);
			gap: 20px;
		}

		@media (max-width: ($md3 + px)) {
			gap: 15px;
		}

		@media (max-width: ($md4 + px)) {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	.quiz-variants__variant {
		width: 100%;
	}

	.quiz-variants__label {
		width: 100%;
	}

	.quiz-question__label {
		padding-left: 25px;
		height: 120px;
		border-radius: 12px;
		border: 1px solid rgba($color: #000000, $alpha: 0.1);
		width: 100%;
		display: flex;
		align-items: center;
		gap: 16px;

		@media (max-width: ($md1 + px)) {
			gap: 10px;
			padding-left: 0;
			justify-content: center;
		}
		@media (max-width: ($md2 + px)) {
			gap: 20px;
			padding-left: 40px;
			justify-content: flex-start;
		}

		@media (max-width: ($md3 + px)) {
			padding-left: 20px;
		}

		@media (max-width: ($md4 + px)) {
			height: 100px;
		}
		span {
			text-align: left;
		}
		&::before {
			width: 60px;
			height: 60px;
			background-image: url(../img/quiz-check.svg);
			box-shadow: 0 10px 30px rgba(48, 49, 85, 0.08);
			background-size: cover;
			border-radius: 50%;
			content: "";
		}
	}
}

.last-step {
	&__block {
		z-index: 1;
	}
	margin-top: 53px;

	@media (max-width: ($md3 + px)) {
		margin-top: 25px;
	}
	&__absolute {
		position: absolute;
		right: 15px;
		top: 138px;
		z-index: -1;

		@media (max-width: ($md1 + px)) {
			right: -90px;
		}

		@media (max-width: ($md2 + px)) {
			max-width: 450px;
			top: 205px;
			right: -165px;
		}
		@media (max-width: ($md3 + px)) {
			display: none;
		}
	}
	// .last-step__text
	&__items {
		display: grid;
		grid-template-columns: repeat(2, minmax(150px, 220px));
		gap: 20px;
		margin-top: 35px;
		width: 100%;
		box-shadow: none;
		border-radius: 0;
		background-color: transparent;
		justify-content: flex-start;

		@media (max-width: ($md3 + px)) {
			justify-content: center;
			margin-top: 20px;
		}

		@media (max-width: ($md5 + px)) {
			gap: 10px;
		}
	}
	&__item {
		padding-top: 25px;
		padding-bottom: 21px;
		border-radius: 16px;
		background-color: #f7f8fa;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 13px;
	}
	&__item-text {
		font-size: 16px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: calc(24 / 16 * 100%);
		text-align: center;

		b {
			font-weight: 700;
		}

		@media (max-width: ($md6 + px)) {
			font-size: 14px;
		}
	}

	&__item-pic {
		height: 64px;
	}

	// .last-step__form
}

.last-step {
	// .last-step__form
	&__title {
		@media (max-width: ($md3 + px)) {
			text-align: center;
		}
	}
	&__form {
		position: relative;
		margin-top: 40px;
		@media (max-width: ($md3 + px)) {
			margin-top: 30px;
		}

		@media (max-width: ($md4 + px)) {
			margin-top: 20px;
		}
	}

	// .last-step__form-capture

	&__form-capture {
		box-shadow: none;
		max-width: none;
	}

	// .last-step__form-rightside

	&__form-rightside {
		position: absolute;
		right: 44px;
		bottom: 0;

		@media (max-width: ($md1 + px)) {
			right: 10px;
			bottom: 0px;
			max-width: 880px;
		}

		@media (max-width: ($md2 + px)) {
			right: 10px;
			bottom: 0px;
			max-width: 600px;
		}
		@media (max-width: ($md4 + px)) {
			display: none;
		}
	}

	// .last-step__form-catalog

	&__form-catalog {
		position: relative;
	}

	// .last-step__form-total

	&__form-total {
		position: absolute;
		right: 18px;
		top: 122px;
		display: flex;
		gap: 9px;
		align-items: flex-start;
		@media (max-width: ($md2 + px)) {
			top: -100px;
		}

		@media (max-width: ($md4 + px)) {
			display: none;
		}
	}

	.capture-form {
		background-color: transparent;
		// .capture-form__block

		&__block {
			z-index: 2;
			padding: 0;
		}

		// .capture-form__form

		&__form {
		}

		// .capture-form__inner

		&__inner {
			display: flex;

			gap: 10px;

			@media (max-width: ($md3 + px)) {
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}

		// .capture-form__phone

		&__phone {
			height: 98px;
			width: 350px;
			font-size: 18px;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: 26px;
		}

		._form-control {
		}

		// .capture-form__button

		&__button {
			height: 98px;
			width: 350px;
			margin-top: 0;
		}

		// .capture-form__policy

		&__policy {
			margin-top: 25px;
			@media (max-width: ($md3 + px)) {
				text-align: center;
			}

			@media (max-width: ($md5 + px)) {
				text-align: left;
			}

			.policy__label {
				font-size: 13px;
				padding-left: 195px;

				@media (max-width: ($md3 + px)) {
					padding-left: 30px;
				}

				&::after {
					left: 173px;

					@media (max-width: ($md3 + px)) {
						left: 3px;
					}
				}

				&::before {
					left: 170px;
					@media (max-width: ($md3 + px)) {
						left: 0;
					}
				}
			}
		}
	}
}

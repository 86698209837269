.section-mirror-2 {
	margin-top: 156px;
	@media (max-width: 768.98px) {
		margin-top: 50px;
	}
	.overflow {
		// .overflow__container

		&__container {
			@media (max-width: 992.98px) {
				max-width: 100%;
				padding: 0;
				.coursor {
					display: flex;
				}
			}
		}
	}
	// .section-mirror-2__container

	&__container {
	}

	// .section-mirror-2__title

	&__title {
	}

	// .section-mirror-2__block

	&__block {
		margin-top: 56px;
		@media (max-width: 992.98px) {
			margin-top: 0;
		}
	}
}

.four-block {
	// .four-block__wrapper

	&__wrapper {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 20px;
		@media (max-width: 1213.98px) {
			gap: 10px;
		}
		@media (max-width: 992.98px) {
			grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
			grid-auto-flow: column;
			overflow-x: scroll;
			padding: 0 15px;
		}
	}

	// .four-block__item

	&__item {
		min-width: 235px;
	}

	// .four-block__pic

	&__pic {
	}

	// .four-block__info

	&__info {
		padding: 20px 15px;
		@media (max-width: 1213.98px) {
			padding: 10px 5px;
		}
	}

	// .four-block__title

	&__title {
		font-size: 1.125rem;
		line-height: 155.555556%; /* 28/18 */
		@media (max-width: 1213.98px) {
			font-size: 16px;
		}
		@media (max-width: 992.98px) {
		}
	}
}

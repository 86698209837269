.section-mirror-9 {
	margin-top: 120px;
	@media (max-width: 768.98px) {
		margin-top: 50px;
	}
	// .section-mirror-9__wrapper

	&__wrapper {
		max-width: 1560px;
		border-radius: 40px;
		background-color: #f7f8fa;
		margin-left: auto;
		margin-right: auto;
		padding: 116px 0 120px;
		@media (max-width: 768.98px) {
			padding: 20px 0;
		}
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 10px 40px;
		margin-top: 20px;
		@media (max-width: 580.98px) {
			gap: 10px 20px;
		}
	}
	&__list-item {
		font-size: 1.125rem;
		line-height: 120%;
		@media (max-width: 580.98px) {
			font-size: 14px;
		}
		&::before {
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}
	// .section-mirror-9__container

	&__container {
	}

	// .section-mirror-9__maintitle

	&__maintitle {
	}

	// .section-mirror-9__mainsubtitle

	&__mainsubtitle {
		margin-top: 20px;
		@media (max-width: 768.98px) {
			margin-top: 5px;
		}
	}

	// .section-mirror-9__block

	&__block {
		min-width: 0;
		width: 100%;
		margin-top: 56px;
		@media (max-width: 768.98px) {
			margin-top: 20px;
		}
	}
}

.multiple-items {
	position: relative;

	// .multiple-items__column

	&__column {
		max-width: 380px;
		padding-bottom: 126.2%;
		background: no-repeat center/cover;
		border-radius: 20px;
		margin-left: auto;
		margin-right: auto;
		display: block;
		@media (max-width: 1230.98px) {
			margin-right: 5px;
			margin-left: 5px;
		}
		&_2 {
			max-width: 585px;
			padding-bottom: 74%;
		}
	}

	.slick-arrow {
		min-width: 70px;
		height: 70px;
		box-shadow: 0 25px 50px rgba(1, 108, 208, 0.25), inset 0 0 18px 2px rgba(255, 255, 255, 0.1);
		background-image: linear-gradient(to top, #016cd0 0%, #027aeb 100%);
		font-size: 0;
		border-radius: 50%;
		top: 50%;
		position: absolute;
		transform: translate(0%, -50%);
		z-index: 3;
		@media (max-width: 768.98px) {
			min-width: 45px;
			height: 45px;
		}
	}
	.slick-prev {
		left: -25px;
		&::before {
			content: "";
			position: absolute;
			inset: 0 5px 0 0;
			margin: auto;
			width: 17px;
			height: 17px;
			background: no-repeat center/contain;
			background-image: url(../img/zerkala/9/suda.svg);
		}
		@media (max-width: 768.98px) {
			left: -10px;
		}
	}
	.slick-next {
		right: -25px;
		&::before {
			content: "";
			position: absolute;
			inset: 0 0px 0 5px;
			margin: auto;
			width: 17px;
			height: 17px;
			background: no-repeat center/contain;
			background-image: url(../img/zerkala/9/tuda.svg);
		}
		@media (max-width: 768.98px) {
			right: -10px;
		}
	}
}

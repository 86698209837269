.section-mirror-8 {
	margin-top: 116px;
	@media (max-width: 768.98px) {
		margin-top: 30px;
	}
	.overflow {
		// .overflow__container

		&__container {
			@media (max-width: 768.98px) {
				padding: 0;
				.coursor {
					display: flex;
				}
				& > div {
					padding: 0 15px;
				}
			}
		}
	}

	// .section-mirror-8__container

	&__container {
	}

	// .section-mirror-8__maintitle

	&__maintitle {
	}

	// .section-mirror-8__block

	&__block {
		margin-top: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: 25px 15px;
		@media (max-width: 768.98px) {
			display: grid;
			justify-content: flex-start;
			grid-template-columns: repeat(auto-fill, minmax(228px, 1fr));
			grid-auto-flow: column;
			overflow-x: scroll;
			margin-top: 0 !important;
			padding-bottom: 20px !important;
		}
	}

	// .section-mirror-8__column

	&__column {
		position: relative;
		min-width: 228px;
	}

	// .section-mirror-8__img

	&__img {
	}

	// .section-mirror-8__info

	&__info {
		position: absolute;
		inset: 25px auto auto 15px;
		span {
			font-size: 1.125rem;
			line-height: 133.333333%; /* 24/18 */
			border-radius: 5px;
			background-color: #ffffff;
			padding: 10px;
			font-weight: 700;
			position: relative;
			&:nth-child(2) {
				padding-top: 0px;
				top: 5px;
			}
		}
	}
}

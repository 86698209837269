.section-holod-4 {
	margin-top: 120px;
	@media (max-width: 768.98px) {
		margin-top: 50px;
	}
	.overflow {
		// .overflow__container

		&__container {
			@media (max-width: 1010.98px) {
				max-width: 100% !important;
				.coursor {
					display: flex;
				}
				padding: 0;
				& > div {
					padding: 0 15px;
				}
			}
		}
	}
	// .section-holod-4__wrapper

	&__wrapper {
		padding: 0 0 90px;
		max-width: 1560px;
		margin-left: auto;
		margin-right: auto;
		border-radius: 40px;
		background-color: #f7f8fa;
		@media (max-width: 768.98px) {
			padding: 0 0 0px;
		}
	}

	// .section-holod-4__container

	&__container {
	}

	// .section-holod-4__maintitle

	&__maintitle {
		padding-top: 116px;
		@media (max-width: 991.98px) {
			br {
				display: none;
			}
		}
		@media (max-width: 768.98px) {
			padding-top: 30px;
		}
	}

	// .section-holod-4__block

	&__block {
		margin-top: 56px;
		display: grid;
		grid-template-areas:
			"a a a b b b c c c d d d"
			"e e e e f f f f g g g g";
		gap: 20px;
		@media (max-width: 1200.98px) {
			gap: 10px;
		}
		@media (max-width: 1010.98px) {
			grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
			grid-auto-flow: column;
			overflow-x: scroll;
			margin-top: 0 !important;
			padding-bottom: 20px !important;
			grid-template-areas: unset;
		}
	}

	// .section-holod-4__item

	&__item {
		position: relative;
		@media (max-width: 1010.98px) {
			min-width: 285px;
			height: 390px;
			border-radius: 15px;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
			}
		}
		@media (min-width: 1010.98px) {
			&:nth-child(1) {
				grid-area: a;
			}
			&:nth-child(2) {
				grid-area: b;
			}
			&:nth-child(3) {
				grid-area: c;
			}
			&:nth-child(4) {
				grid-area: d;
			}
			&:nth-child(5) {
				grid-area: e;
			}
			&:nth-child(6) {
				grid-area: f;
			}
			&:nth-child(7) {
				grid-area: g;
			}
		}
	}

	// .section-holod-4__img

	&__img {
		@media (max-width: 1010.98px) {
			height: 100%;
		}
	}
	.section-mirror-8 {
		// .section-mirror-8__info

		&__info {
			inset: 0;
			padding: 40px 25px;
			@media (max-width: 1200.98px) {
				padding: 10px 10px;
			}

			span {
				display: block;
				width: fit-content;
				&:nth-child(2) {
					padding-top: 10px;
					top: 0px;
				}
			}
		}
	}
}

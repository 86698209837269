.section-mirror-3 {
	margin-top: 65px;
	@media (max-width: 768.98px) {
		margin-top: 30px;
	}
	.overflow__container {
		@media (max-width: 650.98px) {
			.coursor {
				display: flex;
			}
			padding: 0;
			& > div {
				padding: 0 15px;
			}
		}
	}
	// .section-mirror-3__wrapper

	&__wrapper {
		max-width: 1440px;
		border-radius: 40px;
		background-color: #f7f8fa;
		margin-left: auto;
		margin-right: auto;
		@media (max-width: ($md1 + px)) {
			max-width: 99%;
		}
	}

	// .section-mirror-3__container

	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 40px;
		@media (max-width: ($md1 + px)) {
			flex-direction: column;
			gap: 20px;
			padding-top: 40px;
			padding-bottom: 50px;
		}
		@media (max-width: 650.98px) {
			display: block;
			padding-left: 0;
			padding-right: 0;
			.coursor {
				display: flex;
			}
		}

		@media (max-width: ($md3 + px)) {
			padding-bottom: 30px;
			padding-top: 30px;
		}
	}

	// .section-mirror-3__title

	&__title {
		text-align: left;
		@media (max-width: ($md1 + px)) {
			text-align: center;
			width: 100%;
			br {
				display: none;
			}
			margin-bottom: 10px;
		}
	}
	// .section-mirror-3__title

	&__subtitle {
		position: absolute;
		inset: 0 0 auto;
		padding: 20px;
		text-transform: uppercase;
		font-weight: 700;

		@media (max-width: ($md2 + px)) {
			line-height: 1.3;
		}
		@media (max-width: ($md3 + px)) {
			background-color: #fff;
			border-radius: 0px 0px 6px 6px;
			margin-bottom: 20px;
			padding: 10px;
			text-align: center;
			font-size: 15px;
		}
		span {
			display: block;
			padding: 10px 15px;
			border-radius: 6px;
			background-color: #ffffff;
			width: fit-content;
			&:nth-child(2) {
				margin-top: -10px;
				padding-top: 15px;
				@media (max-width: ($md2 + px)) {
					padding-top: 0;
				}
			}
			@media (max-width: ($md3 + px)) {
				display: contents;
				padding: 0;
			}
		}
		p {
			font-size: 1.25rem;
			line-height: 150%; /* 30/20 */
			text-transform: initial;
		}
		@media (max-width: 768.98px) {
			position: unset;
		}
	}

	// .section-mirror-3__block

	&__block {
		display: flex;
		padding: 60px 0;
		gap: 20px;
		@media (max-width: ($md1 + px)) {
			padding: 0;
			gap: 10px;
		}
		@media (max-width: 650.98px) {
			flex-wrap: nowrap;
			overflow-x: scroll;
			padding-left: 15px !important;
			padding-right: 15px !important;
		}
	}

	// .section-mirror-3__item

	&__item {
		position: relative;
		min-width: 300px;
	}

	// .section-mirror-3__pic

	&__pic {
		border-radius: 20px;
		overflow: hidden;
	}
}
